import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import QuickNote from '../../entities/preferences/quicknote'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface QuickNoteState extends ListState<QuickNote>{
    editModel:QuickNote;    
    conntected: Array<any>;    
}
class QuickNoteModule extends ListModule<QuickNoteState,any,QuickNote>{
    state={
        totalCount:0,
        currentPage:1, 
        pageSize: 10,
        list: new Array<QuickNote>(),
        loading:false,
        editModel: new QuickNote(),
        activeList: new Array<QuickNote>(),
        conntected: new Array<any>(),
        path: 'quick-notes'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: QuickNoteState, model: QuickNote) {
            state.editModel = Object.assign({});
            state.editModel.user_id = Util.abp.session.userId;
            state.editModel.note_date = Util.abp.clock.today();            
        },
    });
}
const quicknoteModule=new QuickNoteModule();
export default quicknoteModule;