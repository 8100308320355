import Entity from '../entity'

export default class WorkOrderActivity extends Entity<number>{
    work_order_id: number;
    work_order_task_id: number;
    activity_id: number;
    activity_name: string;
}


