import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import StockMvt from '../../entities/stocks/stockmvt'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import PageResult from '@/store/entities/page-result';

interface StockMvtState extends ListState<StockMvt>{
    editModel: StockMvt;
    product: Product;
    activeList: Array<StockMvt>;
    reportList: Array<any>;
    product_id: number;
    product_attribute_id: number;
    warehouse_id: number;
    filename: '';
}
class StockMvtModule extends ListModule<StockMvtState, any, StockMvt>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10, 
        list: new Array<StockMvt>(),
        loading:false,
        editModel:new StockMvt(),
        product: new Product(),
        activeList: new Array<StockMvt>(),
        reportList: new Array<any>(),
        product_id: null,
        product_attribute_id: null,
        warehouse_id: Util.abp.session.warehouseId,
        filename: '',
        path: 'stock-mvts'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
	    async getKardex(context: ActionContext<StockMvtState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/kardex', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = reponse.data.result.length;
            context.state.reportList = reponse.data.result;
        },
        async getMvts(context: ActionContext<StockMvtState, any>, payload: any) {
            context.state.loading = true;
            context.state.totalCount = 0;
            context.state.reportList = [];
            payload.data.perPage = -1;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = reponse.data.result.length;
            context.state.reportList = reponse.data.result;
        },
        async export(context: ActionContext<StockMvtState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/stock-mvts/export', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return reponse.data.result.filename;
        },
        async reportSunatFormat12(context: ActionContext<StockMvtState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/sunat-format12', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportSunatFormat13(context: ActionContext<StockMvtState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/sunat-format13', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: StockMvtState, model: any) {
            state.editModel = Object.assign({});
            state.product = new Product();
            state.product.id = model.product_id;
            state.product.product_attribute_id = model.product_attribute_id;            
            state.product.manufacturer_name = model.manufacturer_name;
            state.product.name = model.product_name;
            state.product.unity_symbol = model.unity_symbol;            
            state.product.cost = model.product_cost;
            state.product.stock_physical = model.physical_quantity;
            state.product.stock = model.usable_quantity;
            state.product.stock_reserved = state.product.stock_physical - state.product.stock;
            if (model.product_name != undefined) state.product['name'] = model.product_name;
            if (model.code != undefined) state.product['code'] = model.code;
            if ((state.product as any).manufacturer != undefined)
                state.product.manufacturer_name = (state.product as any).manufacturer.name;

            state.editModel.stock_id = model.id;
            state.editModel.warehouse_id = model.warehouse_id;
            state.editModel.cost = model.cost;
            state.editModel.stock_mvt_reason_id = model['stock_mvt_reason_id'];
        },
        setProduct(state: StockMvtState, id: number) {
            state.product_id = id;
        },
        setProductAttribute(state: StockMvtState, id: number) {
            state.product_attribute_id = id;
        },
        setWarehouse(state: StockMvtState, id: number) {
            state.warehouse_id = id;
        }
    });
}
const stockmvtModule=new StockMvtModule();
export default stockmvtModule;