import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import SpecialPriceRule from '../../entities/sales/specialpricerule'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface SpecialPriceRuleState extends ListState<SpecialPriceRule>{
    editModel: SpecialPriceRule;
    products: Array<any>;
    activeList: Array<SpecialPriceRule>;
}
class SpecialPriceRuleModule extends ListModule<SpecialPriceRuleState, any, SpecialPriceRule>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10, 
        list: new Array<SpecialPriceRule>(),
        loading:false,
        editModel:new SpecialPriceRule(),
        products: new Array<any>(),
        activeList: new Array<SpecialPriceRule>(),
        path: 'special-price-rules'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getInfo(context: ActionContext<SpecialPriceRuleState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/special-price-rules/get-info/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async importProducts(context: ActionContext<SpecialPriceRuleState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/special-price-rules/import-products',
                payload.data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
                .catch(e => {
                    context.state.loading = false;
                    return Promise.reject(e);
                });
            context.state.loading = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: SpecialPriceRuleState, model: SpecialPriceRule) {
            state.editModel = Object.assign({});
            state.editModel.available = 'BOTH';
            state.editModel.from_quantity = 1;
            state.editModel.discount_type = 'percent';
            state.editModel.mode = 'catalog';
            state.editModel.products = [];
            state.editModel.product_names = [];
            state.products = [];
        },
        edit(state: SpecialPriceRuleState, model: SpecialPriceRule) {
            state.editModel = model;
            state.products = (model.products != null ? model.products : []);
        },
        view(state: SpecialPriceRuleState, model: SpecialPriceRule) {
            state.editModel = model;
            state.products = (model.products != null ? model.products : []);
        },
        setProducts(state: SpecialPriceRuleState, products: Array<any>) {
            state.products = (products != null ? products : []);
        },
    });
}
const specialpriceruleModule=new SpecialPriceRuleModule();
export default specialpriceruleModule;