import Entity from '../entity'

export default class QuickNote extends Entity<number>{
    user_id: number;
    content: string;
    color: string;
    note_date: string;
}


