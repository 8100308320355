import Entity from '../entity'
import ServiceOrderDetail from './serviceorderdetail';
import Customer from '../customers/customer';
import Currency from '../preferences/currency';

export default class ServiceOrder extends Entity<number>{
    reference: string;
    serie: string;
    number: string;
    branch_id: number;
    customer_id: number;
    request_by_id: number;
    currency_id: number;
    cost_center_id: number;
    payment_type_id: number;
    current_state_id: number;
    sale_quotation_id: number;
    including_taxes: boolean;
    exchange_rate: number;
    taxes: number;
    total: number;
    total_wt: number;
    issue_date: string;
    delivery_date: string;
    notes: string;
    valid: boolean;
    invoiced: boolean;
    quotation_nro: string;
    nro: string;

    details: Array<ServiceOrderDetail>;
    customer: Customer;
    currency_sign: string;
    customer_name: string;
    currency: Currency;
}


