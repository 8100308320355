import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import WorkOrderService from '../../entities/production/workorderservice'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import WorkOrder from '@/store/entities/production/workorder';
import Product from '@/store/entities/catalogs/product';

interface WorkOrderServiceState extends ListState<WorkOrderService>{
    editModel:WorkOrderService;    
    order:number;    
}
class WorkOrderServiceModule extends ListModule<WorkOrderServiceState,any,WorkOrderService>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<WorkOrderService>(),
        loading:false,
        editModel: new WorkOrderService(),
        order: 0,
        path: 'work-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<WorkOrderServiceState, any>, payload: any) {
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: WorkOrderServiceState, model: WorkOrderService) {
            state.editModel = model;
        },
        init(state: WorkOrderServiceState, model: WorkOrder) {
            state.editModel = Object.assign({});
            state.order = 0;
            state.list = [];
            if (model != undefined) {
                state.order = model.id;
                state.list = model.services;
            }
            state.totalCount = state.list.length;
        },
        add(state: WorkOrderServiceState, service: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === service.id;
            });

            if (exist.length > 0) {
                exist[0].quantity += service.quantity;
            } else {
                let detail = new WorkOrderService();
                detail.work_order_id = state.order;
                detail.product_id = service.id;
                detail.code = service.code;
                detail.product_name = service.name;
                detail.quantity = service.quantity;
                detail.cost_wt = service.cost;
                detail.total_wt = parseFloat((detail.quantity * detail.cost_wt).toFixed(6));
                detail.quantity = service.quantity;
                
                state.list.push(detail);
            }
            state.totalCount = state.list.length;
        },
        delete(state: WorkOrderServiceState, index: number) {
            state.list.splice(index, 1);
            state.totalCount = state.list.length;
        },
    });
}
const workorderserviceModule=new WorkOrderServiceModule();
export default workorderserviceModule;