import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Product from '../../entities/catalogs/product'
import ProductField from '../../entities/catalogs/productfield'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'
import PackService from '@/store/entities/catalogs/packservice';
import PackPlan from '@/store/entities/catalogs/packplan';

interface ProductState extends ListState<Product>{
    editModel: Product;
    activeList: Array<Product>;
    planModel: PackPlan;
    images: Array<any>;
    services: Array<any>;
    vehicles: Array<any>;
    plans: Array<any>;
    simulate: Array<any>;
    uploading: boolean;
    currency: number;
    productType: number;
    totalSimCount: number;
}
class ProductModule extends ListModule<ProductState,any,Product>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Product>(),
        loading: false,
        uploading: false,
        editModel:new Product(),
        planModel: new PackPlan(),
        activeList: new Array<Product>(),
        images: new Array<any>(),
        services: new Array<any>(),
        vehicles: new Array<any>(),
        plans: new Array<any>(),
        simulate: new Array<any>(),
        currency: null,
        productType: null,
        totalSimCount: 0,
        path: 'ldms',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getInfo(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/products/get-info/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async uploadImage(context: ActionContext<ProductState, any>, payload: any) {
            context.state.uploading = true;
            let response = await Ajax.post('/api/products/upload-image', payload.data).catch(e => {
                context.state.uploading = false;
                return Promise.reject(e);
            });
            context.state.uploading = false;
            context.state.images = response.data.result;
        },
        async deleteImage(context: ActionContext<ProductState, any>, payload: any) {
            context.state.uploading = true;
            let response = await Ajax.delete('/api/products/delete-image/' + payload.data.id).catch(e => {
                context.state.uploading = false;
                return Promise.reject(e);
            });
            context.state.uploading = false;
            context.state.images = context.state.images.filter((item: any) => {
                return item.id !== payload.data.id;
            });
        },
        async getAllActive(context: ActionContext<ProductState, any>, payload: any) {
            if (context.state.activeList.length > 0 || context.state.loading) return true;
            let params = (payload.data == undefined ? {} : payload.data); 
            params.perPage = -1;
            params.product_type = Util.abp.setting.get('DEFAULT_PRODUCTION_TYPE');
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: params }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.activeList = reponse.data.result;
            context.state.loading = false;
        },
        async getSimulate(context: ActionContext<ProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/ldm-simulate', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.simulate = response.data.result;
            context.state.totalSimCount = context.state.simulate.length;
            return context.state.simulate;
        },
        async querySearch(context: ActionContext<ProductState, any>, payload: any) {
            if (payload.data.query == null || payload.data.query == 'null') return [];
            if (context.state.activeList != undefined && context.state.activeList.length > 0) {
                let result = context.state.activeList.filter((item: any) => {
                    return item != null && item[payload.data.filter] != null && (item[payload.data.filter].toUpperCase().indexOf(payload.data.query.toUpperCase()) >= 0);
                });

                if (result.length > 0)
                    return result;
            }
            if (context.state.loading) return context.state.activeList;
            context.state.loading = true;
            payload.data.product_type = Util.abp.setting.get('DEFAULT_PRODUCTION_TYPE');
            let reponse = await Ajax.get('/api/products/query-search', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.activeList = reponse.data.result;
            context.state.loading = false;
            return reponse.data.result;
        }
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ProductState, model: Product) {
            state.editModel = Object.assign({});

            let type = Util.abp.list.getProd(Util.abp.setting.get('DEFAULT_PRODUCTION_TYPE'));
            state.editModel.price = 0;
            state.editModel.price_wt = 0;
            state.editModel.weight = 0;          
            state.editModel.cost = 0;
            state.editModel.code = '';
            state.editModel.profit = Util.abp.setting.get('PRODUCT_PROFIT');
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.line_id = Util.abp.setting.get('DEFAULT_PACK_LINE');
            state.editModel.category_id = Util.abp.setting.get('DEFAULT_PACK_CATEGORY');
            state.editModel.unity_id = Util.abp.setting.get('DEFAULT_UNITY');            
            state.editModel.product_type_id = Util.abp.setting.get('DEFAULT_PRODUCTION_TYPE');
            state.editModel.available_id = type.available_id;            
            state.editModel.pack_type_id = Util.abp.setting.get('DEFAULT_LDM_TYPE');
            state.editModel.tax_id = Util.abp.setting.get('IGV_TAXES');
            state.currency = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.productType = state.editModel.product_type_id;

            state.editModel.fields = new ProductField();
            state.editModel.fields.duration_type = 'D';
            state.editModel.fields.allow_children = false;
            state.editModel.fields.frecuency_days = [];
            state.editModel.fields.frecuency_hours = [];
            state.editModel.fields.categories = [];
            state.editModel.categories = [];
        },
        edit(state: ProductState, model: Product) {
            state.editModel = model;
            state.editModel.weight = 0;
            state.currency = model.currency_id;
            state.productType = model.product_type_id;

            if (state.editModel.fields == null) {
                state.editModel.fields = new ProductField();
                state.editModel.fields.duration_type = 'D';
                state.editModel.fields.allow_children = false;
                state.editModel.fields.frecuency_days = [];
                state.editModel.fields.frecuency_hours = [];
                state.editModel.fields.categories = [];
            }
            state.editModel.categories = state.editModel.fields.categories;
        },
        setImagesList(state: ProductState, images: Array<any>) {
            state.images = images;
            state.images.forEach(img => {
                img.url = url + img.url;
            });
        },
        setProductType(state: ProductState, id: number) {
            let type = Util.abp.list.getProd(id);
            state.editModel.product_type_id = id;
            state.editModel.available_id = type.available_id;
            state.productType = id;
        },
        newPlan(state: ProductState) {
            let date = new Date();
            state.planModel = Object.assign({});
            state.planModel.duration_type = 'H';
            state.planModel.hour_rate = 0;
            state.planModel.hour_rate_employee = 0;
            state.planModel.hour_rate_equipment = 0;
            state.planModel.employee_id = null;
            state.planModel.process_id = null;
            state.planModel.activity_id = null;
            state.planModel.activity_name = '';
            state.planModel.equipment_type_id = null;
            state.planModel.equipment_id = null;
            state.planModel.equipment_name = '';
            state.planModel.time_estimated = 0;
            state.planModel.total_wt = 0;
        },        
        addService(state: ProductState, product: Product) {
            let exist = state.services.filter((detail: any) => {
                return detail.service_id == product.id;
            });

            if (exist.length == 0) {
                let detail = new PackService();
                detail.service_id = product.id;
                detail.supplier_id = null;
                detail.product_name = product.name;
                detail.code = product.code;
                detail.quantity = 1;
                detail.cost_wt = product.cost;
                detail.total_wt = product.cost;

                state.services.push(detail);
            }
        },
        addVehicle(state: ProductState, item: any) {
            let exist = state.vehicles.filter((detail: any) => {
                return detail.vehicle_id == item.id;
            });

            if (exist.length == 0) {
                let type = Util.abp.list.getItem(item.type_id);

                let detail = {
                    product_id: state.editModel.id,
                    vehicle_id: item.id,
                    vehicle_name: type.name + ' ' + item['manufacturer'].name,
                    description: item.description,
                    code: item.plate,
                    hours: 1,
                    cost_wt: parseFloat(String(item.hour_rate)),
                    total_wt: 0
                };
               
                detail.total_wt = parseFloat((detail.hours * detail.cost_wt).toFixed(6));
                state.vehicles.push(detail);
            }
        },
        addPlan(state: ProductState, item: any) {
            let exist = state.plans.filter((detail: any) => {
                return detail.activity_id == item.activity_id;
            });

            if (exist.length == 0) {
                let day_hours = Util.abp.setting.get('PRODUCTION_DAY_HOURS')

                let detail = new PackPlan();
                detail = Util.extend(true, {}, item); 
                detail.product_id = state.editModel.id;
                let time = (detail.duration_type == 'H' ? detail.time_estimated : (detail.duration_type == 'M' ? detail.time_estimated / 60 : detail.time_estimated * day_hours));
                detail.total_wt = detail.hour_rate * time;
                state.plans.push(detail);
            }
        },
        deleteService(state: ProductState, index: number) {
            state.services.splice(index, 1);
        },
        deleteVehicle(state: ProductState, index: number) {
            state.vehicles.splice(index, 1);
        },
        deletePlan(state: ProductState, index: number) {
            state.plans.splice(index, 1);
        },
        setLists(state: ProductState, item: any) {
            state.services = item.services;
            state.vehicles = item.vehicles;
            state.plans = item.plans;
        },
        setCurrency(state: ProductState, id: number) {
            state.currency = id;
        },        
        setSimulateList(state: ProductState, list: any) {
            state.simulate = list;
        },
    });
}
const productModule=new ProductModule();
export default productModule;