import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import FeatureValue from '../../entities/catalogs/featurevalue'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface FeatureValueState extends ListState<FeatureValue> {
    editModel: FeatureValue;
    activeList: Array<FeatureValue>;
}
class FeatureValueModule extends ListModule<FeatureValueState, any, FeatureValue>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<FeatureValue>(),
        loading: false,
        editModel: new FeatureValue(),
        activeList: new Array<FeatureValue>(),
        path: 'feature-values',
    }
    actions = {
        async getAll(context: ActionContext<FeatureValueState, any>, payload: any) {
        },
        async create(context: ActionContext<FeatureValueState, any>, payload: any) {
            context.state.loading = true;
            var result = await Ajax.post('/api/feature-values', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.list.push(result.data.result);
            context.state.loading = false;
        },
        async update(context: ActionContext<FeatureValueState, any>, payload: any) {
            context.state.loading = true;
            var result = await Ajax.put('/api/feature-values/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.list = result.data.result;
            context.state.loading = false;
        },
        async delete(context: ActionContext<FeatureValueState, any>, payload: any) {
            context.state.loading = true;
            let id = payload.data.id;
            await Ajax.delete('/api/feature-values/' + id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list = context.state.list.filter((item: any) => {
                return item.id !== id;
            });
        },
    };
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setActiveList(state: FeatureValueState, values: Array<FeatureValue>) {
            if (values != null) {
                state.activeList = values;
            } else
                state.activeList = new Array<FeatureValue>();
        },
        setList(state: FeatureValueState, values: Array<FeatureValue>) {
            if (values != null) {
                state.loading = true;
                state.list = values;
                state.totalCount = values.length;
                state.loading = false;
            }
        }
    });
}
const featurevalueModule = new FeatureValueModule();
export default featurevalueModule;