import Entity from '../entity'
import PurchaseImportDetail from './purchaseimportdetail';
import Currency from '../preferences/currency';
import PurchaseImportExpense from './purchaseimportexpense';

export default class PurchaseImport extends Entity<number>{
    reference: string;
    serie: string;
    number: number;
    purchase_planning_id: number;
    purchase_order_id: number;
    branch_id: number;
    currency_id: number;
    currency_sign: string;
    current_state_id: number;
    customs_id: number;
    nro_policy: string;
    date_policy: string;
    exchange_rate: number;
    expense_rate: number;
    factor_total: number;
    factor_item: number;
    subtotal: number;
    total: number;
    total_paid: number;
    total_pen: number;
    issue_date: string;
    valid: boolean;
    received: boolean;
    order_number: string;

    current_state: any;
    order: string;
    details: Array<PurchaseImportDetail>;
    expenses: Array<PurchaseImportExpense>;    
    suppliers: Array<any>;    
    currency: Currency;
}


