import Entity from '../entity'

export default class Budget extends Entity<number>{
    company_id: number;
    branch_id: number;
    currency_id: number;
    year: number;
    requested: number;
    approved: number;
    used: number;
    balance: number;
    closed: boolean;
}


