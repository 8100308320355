import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PurchasePlanningProduct from '../../entities/purchases/purchaseplanningproduct'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import PurchasePlanning from '@/store/entities/purchases/purchaseplanning';
import PurchaseRequest from '@/store/entities/purchases/purchaserequest';

interface PurchasePlanningProductState extends ListState<PurchasePlanningProduct>{
    editModel:PurchasePlanningProduct;    
    planning:number;
    edited: boolean;    
}
class PurchasePlanningProductModule extends ListModule<PurchasePlanningProductState,any,PurchasePlanningProduct>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PurchasePlanningProduct>(),
        loading:false,
        editModel: new PurchasePlanningProduct(),
        planning: 0,
        edited: false,
        path: 'purchase-planning'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<PurchasePlanningProductState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: PurchasePlanningProductState, model: PurchasePlanningProduct) {
            state.editModel = model;
        },
        init(state: PurchasePlanningProductState, model: PurchasePlanning) {
            state.editModel = Object.assign({});
            state.list = [];
            state.edited = false;
            if (model != undefined) {
                state.planning = model.id;
                if (model.products != undefined && model.products != null)
                    state.list = model.products;
            }
        },
        addProducts(state: PurchasePlanningProductState, list: Array<any>) {
            list.forEach(prod => {
                let exist = state.list.filter((detail: any) => {
                    return detail.product_id === prod.id && detail.product_attribute_id == prod['product_attribute_id'] && detail.supplier_id == 0;
                });

                if (prod.cost_wt == undefined) prod.cost_wt = prod.cost;
                if (prod.product_cost == undefined) prod.product_cost = prod.cost;

                if (exist.length == 0) {
                    if (prod['product_attribute_id'] == null) prod['product_attribute_id'] = 0;

                    let detail = new PurchasePlanningProduct();
                    detail.purchase_planning_id = state.planning;
                    detail.category_id = prod.category_id;
                    detail.product_id = prod.id;
                    detail.product_attribute_id = prod['product_attribute_id'];
                    detail.unity_id = prod.unity_id;
                    detail.unity_sym = prod.unity_sym;
                    detail.product_name = prod.name + (prod.manufacturer != null && prod.manufacturer != 'SIN MARCA' ? ' - ' + prod.manufacturer : '');
                    detail.code = prod.code;
                    detail.quantity_suggested = prod.quantity_to_purchase;
                    detail.quantity_out = prod.outs;
                    detail.quantity_request = 0;            
                    detail.quantity_ordered = 0;
                    detail.quantity_aditional = 0;
                    detail.minimal_quantity = prod.minimal_quantity;
                    detail.quantity = prod.quantity_to_purchase;
                    detail.stock = prod.physical_quantity;
                    detail.cost_wt = parseFloat(parseFloat(String(prod.cost_wt)).toFixed(6));
                    detail.cost = Util.removeTaxes(detail.cost_wt);
                    detail.total = parseFloat((detail.cost * detail.quantity).toFixed(6));
                    detail.total_wt = parseFloat((detail.cost_wt * detail.quantity).toFixed(6));
                    detail.taxes = detail.total_wt - detail.total;
                    detail.product_cost = prod.product_cost;
                    detail.budget = prod.budget;
                    detail.quantity_received = 0;
                    detail.quantity_pending = detail.quantity - detail.quantity_received;
                    detail.supplier_id = 0;
                    detail.supplier_name = 'Sin Proveedor asginado';
                    detail.category_name = prod.category;
                    detail.number = state.list.length + 1;
                    detail.state_id = Util.abp.list.get('E_PPLANING_PROD', 'PE').id;
                    detail.service = false;

                    state.list.push(detail);
                    state.edited = true;
                }
            });            
        },
        addProductsFromRequests(state: PurchasePlanningProductState, list: Array<PurchaseRequest>) {
            list.forEach(req => {
                req.details.forEach(prod => {
                    let exist = state.list.filter((detail: any) => {
                        return detail.product_id === prod.product_id && detail.product_attribute_id == prod.product_attribute_id;
                    });

                    if (exist.length == 0 && !prod.service) {
                        let detail = new PurchasePlanningProduct();
                        detail.purchase_planning_id = state.planning;
                        detail['purchase_request_id'] = req.id;
                        detail.category_id = prod.product.category_id;
                        detail.product_id = prod.product_id;
                        detail.product_attribute_id = prod.product_attribute_id;
                        detail.unity_id = prod.unity_id;
                        detail.unity_sym = prod.unity_sym;
                        detail.product_name = prod.product_name;
                        detail.code = prod.code;
                        detail.quantity_suggested = 0;
                        detail.quantity_out = 0;
                        detail.quantity_request = prod.quantity;
                        detail.quantity_ordered = 0;
                        detail.quantity_aditional = 0;
                        detail.minimal_quantity = prod.product.minimal_quantity;
                        detail.quantity = prod.quantity;
                        detail.stock = prod.product.stock;
                        detail.cost_wt = parseFloat(parseFloat(String(prod.cost_wt)).toFixed(6));
                        detail.cost = Util.removeTaxes(detail.cost_wt);
                        detail.total = parseFloat((detail.cost * detail.quantity).toFixed(6));
                        detail.total_wt = parseFloat((detail.cost_wt * detail.quantity).toFixed(6));
                        detail.taxes = detail.total_wt - detail.total;
                        detail.product_cost = prod.product_cost;
                        detail.budget = 0;
                        detail.quantity_received = 0;
                        detail.quantity_pending = detail.quantity - detail.quantity_received;
                        detail.supplier_id = 0;
                        detail.supplier_name = 'Sin Proveedor asginado';
                        detail.category_name = String(prod.product.category_id);
                        detail.number = state.list.length + 1;
                        detail.state_id = Util.abp.list.get('E_PPLANING_PROD', 'RE').id;
                        detail.service = false;

                        state.list.push(detail);
                        state.edited = true;
                    }
                });
            });
        },
        add(state: PurchasePlanningProductState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id && detail.product_attribute_id == product['product_attribute_id'] && detail.supplier_id == 0;
            });

            if (exist.length == 0) {
                let detail = new PurchasePlanningProduct();
                detail.purchase_planning_id = state.planning;
                detail.category_id = product.category_id;
                detail.product_id = product.id;
                detail.product_attribute_id = product['product_attribute_id'];
                detail.unity_id = product.unity_id;
                detail.unity_sym = product.unity_symbol;
                detail.product_name = product.name + (product.manufacturer_name != '' && product.manufacturer_name != null && product.manufacturer_name != 'SIN MARCA' ? ' - ' + product.manufacturer_name : '');
                detail.code = product.code;
                detail.quantity_suggested = 0;
                detail.quantity_out = 0;
                detail.quantity_request = 0;                
                detail.quantity_ordered = 0;
                detail.quantity_aditional = 0;
                detail.minimal_quantity = product.minimal_quantity;
                detail.quantity = 1;
                detail.stock = product.stock;
                detail.cost_wt = parseFloat(parseFloat(String(product.cost_wt)).toFixed(6));
                detail.cost = Util.removeTaxes(detail.cost_wt);
                detail.total = detail.cost * detail.quantity;
                detail.total_wt = detail.cost_wt * detail.quantity;
                detail.taxes = detail.total_wt - detail.total;
                detail.product_cost = product.product_cost;
                detail.budget = product['category_budget'];
                detail.quantity_received = 0;
                detail.quantity_pending = detail.quantity - detail.quantity_received;
                detail.supplier_id = 0;
                detail.supplier_name = 'Sin Proveedor asginado';
                detail.category_name = product.category_name;
                detail.number = state.list.length + 1;
                detail.state_id = Util.abp.list.get('E_PPLANING_PROD', 'PE').id;
                detail.service = product['service'];
                                
                state.list.push(detail);
                state.edited = true;
            }
        },
        setSupplier(state: PurchasePlanningProductState, data: any) {
            if (data.list != null) {
                data.list.forEach((prod, i) => {
                    let exist = state.list.filter((detail: any) => {
                        return detail.product_id === prod.product_id && detail.product_attribute_id == prod.product_attribute_id && detail.supplier_id == data.supplier.id;
                    });
                    if (exist.length == 0) {
                        state.list[prod.number - 1].supplier = data.supplier;
                        state.list[prod.number - 1].supplier_name = data.supplier.name;
                        state.list[prod.number - 1].supplier_id = data.supplier.id;
                    } else {
                        exist[0].quantity += prod.quantity;
                        exist[0].quantity_request += prod.quantity_request;
                        exist[0].quantity_ordered += prod.quantity_ordered;
                        exist[0].quantity_received += prod.quantity_received;
                        exist[0].quantity_pending += prod.quantity_pending;
                        exist[0].cost = prod.cost;
                        exist[0].cost_wt = prod.cost_wt;
                        exist[0].total = exist[0].cost * exist[0].quantity;
                        exist[0].total_wt = exist[0].cost_wt * exist[0].quantity;
                        exist[0].taxes = exist[0].total_wt - exist[0].total;

                        state.list.splice(prod.number-1, 1);
                    }
                });
                state.edited = true;
            }
        },
        setEdited(state: PurchasePlanningProductState, value: any) {
            state.edited = value;
        },
        delete(state: PurchasePlanningProductState, index: number) {
            state.list.splice(index, 1);

            let number = 1;
            state.list.forEach((prod, i) => {
                state.list[i].number = number;
                number++;
            });
            state.edited = true;
        },
    });
}
const orderdetailModule=new PurchasePlanningProductModule();
export default orderdetailModule;