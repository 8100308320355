import Entity from '../entity'

export default class CategoryBudget extends Entity<number>{
    category_id: number;
    year: number;
    month: number;
    budget: number;    
}


