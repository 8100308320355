import Entity from '../entity'

export default class ImportExpenseType extends Entity<number>{
    code: number;
    name: string;
    cost: number;
    active: boolean;
}


