import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Quotation from '../../entities/sales/quotation'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Customer from '@/store/entities/customers/customer';
import QuotationDetail from '@/store/entities/sales/quotationdetail';
import QuotationHistory from '@/store/entities/sales/history';
import Currency from '@/store/entities/preferences/currency';

interface QuotationState extends ListState<Quotation>{
    editModel:Quotation;    
    hisLoading: boolean;
    hasChanges: boolean;
    history: Array<QuotationHistory>;      
}
class QuotationModule extends ListModule<QuotationState,any,Quotation>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Quotation>(),
        loading:false,
        hisLoading: false,
        hasChanges: false,
        editModel: new Quotation(),
        activeList: new Array<Quotation>(),
        history: new Array<QuotationHistory>(),
        path: 'quotations'         
    } 
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<QuotationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async update(context: ActionContext<QuotationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            context.state.editModel = response.data.result;
            context.state.activeList = [];
        },
        async getLastNumber(context: ActionContext<QuotationState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/quotations/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<QuotationState, any>, payload: any) {
            context.state.hisLoading = true; 
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/quotations/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async print(context: ActionContext<QuotationState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.get('/api/quotation-invoice', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: QuotationState, model: Quotation) {
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);
            let serie = Util.abp.setting.get('QUOTATION_SERIE');
            
            let currency = new Currency();
            currency.id = Util.abp.setting.get('DEFAULT_CURRENCY');
            currency.sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel = Object.assign({});

            state.editModel.customer = Object.assign({});
            state.editModel.customer_id = Util.abp.setting.get('DEFAULT_CUSTOMER');
            state.editModel.customer_code = '';
            state.editModel.customer_name = Util.abp.setting.get('DEFAULT_CUSTOMER_NAME');
            state.editModel.customer_document = Util.abp.setting.get('DEFAULT_CUSTOMER_DOCUMENT');
            state.editModel.group_name = '';
            state.editModel.credit_line = 0;

            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.due_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.customer_id = Util.abp.setting.get('DEFAULT_CUSTOMER');
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.payment_type_id = Util.abp.list.get('C_SALEPAID', 'CO').id;
            state.editModel.currency = currency;
            state.editModel.currency_sign = 'S/';
            state.editModel.exchange_rate = 1;
            state.editModel.discounts = 0.00;
            state.editModel.subtotal = 0.00;
            state.editModel.subtotal_wt = 0.00;
            state.editModel.taxes = 0.00;
            state.editModel.total = 0.00;
            state.editModel.total_wt = 0.00;
            state.editModel.serie = (serie == 'YYYY' ? Util.abp.clock.today().substr(0, 4) : serie);
            state.editModel.number = '00000';
            state.editModel.details = new Array<QuotationDetail>();
            state.hasChanges = false;

            state.editModel.seller_id = Util.abp.session.userId;
        },
        edit(state: QuotationState, model: Quotation) {
            state.editModel = model;
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.customer_code = model.customer.code;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_document = model.customer.document_full;
            state.editModel.credit_line = model.customer.credit_line;
            if (model.customer.group != null) state.editModel.group_name = model.customer.group.name;
            state.hasChanges = false;
        },
        setCurrency(state: QuotationState, currency: any) {
            state.editModel.currency = currency;
            state.editModel.currency_id = currency.id;
            state.editModel.currency_sign = currency.sign;
            state.editModel.exchange_rate = currency.conversion_rate;
            state.hasChanges = true;
        },
        setCustomer(state: QuotationState, customer: any) {
            state.editModel.customer = customer;
            state.editModel.customer_id = customer.id;
            state.hasChanges = true;
        },
        setChanged(state: QuotationState, value: boolean) {
            state.hasChanges = value;
        },
    });
}
const quotationModule=new QuotationModule();
export default quotationModule;