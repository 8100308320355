import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Room from '@/store/entities/foods/room';
import PageResult from '@/store/entities/page-result';

interface RoomState extends ListState<Room>{
    editModel: Room;
    viewModel: Room;
    rooms: Array<Room>;
    tables: Array<any>;
}
class RoomModule extends ListModule<RoomState, any, Room>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Room>(),
        loading: false,
        editModel:new Room(),
        viewModel: new Room(),
        activeList: new Array<Room>(),
        rooms: new Array<Room>(),
        tables: new Array<any>(),
        path: 'rooms'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getRooms(context: ActionContext<RoomState, any>, payload: any) {
            if (context.state.loading) return context.state.rooms;
            if (context.state.rooms.length > 0) return context.state.rooms;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/rooms', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Room>;
                context.state.totalCount = page.total;
                context.state.rooms = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.rooms = reponse.data.result;
            }
            return context.state.rooms;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: RoomState, model: Room) {
            state.editModel = Object.assign({});
            state.editModel.width = 8;
            state.editModel.height = 6;
            state.editModel.number = 1;
            state.editModel.active = true;
        },
        edit(state: RoomState, model: Room) {
            state.editModel = model;
        },
        view(state: RoomState, model: Room) {
            state.viewModel = model;

            state.viewModel.tables.forEach(a => {
                state.tables[a.position_x + '_' + a.position_y] = a;
            });
        },
    });
}
const roomModule=new RoomModule();
export default roomModule;