import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PurchaseQuotationDetail from '../../entities/purchases/purchasequotationdetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import PurchaseQuotation from '@/store/entities/purchases/purchasequotation';
import PurchaseQuotationSupplier from '@/store/entities/purchases/purchasequotationsupplier';

interface PurchaseQuotationDetailState extends ListState<PurchaseQuotationDetail>{
    editModel:PurchaseQuotationDetail;    
    quotation:number;    
    supplier:number;    
}
class PurchaseQuotationDetailModule extends ListModule<PurchaseQuotationDetailState,any,PurchaseQuotationDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PurchaseQuotationDetail>(),
        loading:false,
        editModel: new PurchaseQuotationDetail(),
        activeList: new Array<PurchaseQuotationDetail>(),
        quotation: 0,
        supplier: 0,
        path: 'purchase-quotations'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<PurchaseQuotationDetailState, any>, payload: any) {
            context.state.list = [];
        },
        async getProductsList(context: ActionContext<PurchaseQuotationDetailState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/purchase-quotations/products', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = reponse.data.result.length;
            context.state.list = reponse.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: PurchaseQuotationDetailState, model: PurchaseQuotationDetail) {
            state.editModel = model;
        },
        init(state: PurchaseQuotationDetailState, model: PurchaseQuotation) {
            state.editModel = Object.assign({});
            state.list = [];
            if (model != undefined) {
                state.quotation = model.id;
            }
            if (state.list == undefined) state.list = [];
        },
        setSupplier(state: PurchaseQuotationDetailState, model: PurchaseQuotationSupplier) {
            if (model != undefined) {
                state.supplier = model.id;
            }
        },
        add(state: PurchaseQuotationDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id && detail.product_attribute_id == product['product_attribute_id'] && detail.purchase_quotation_supplier_id === state.supplier;
            });            

            if (exist.length > 0) {
                exist[0].quantity += product.quantity;
                exist[0].total = exist[0].quantity * exist[0].cost;
                exist[0].total_wt = exist[0].quantity * exist[0].cost_wt
                exist[0].taxes = parseFloat((exist[0].total_wt - exist[0].total).toFixed(6));
            } else {
                let detail = new PurchaseQuotationDetail();
                detail.purchase_quotation_id = state.quotation;
                detail.purchase_quotation_supplier_id = state.supplier;
                detail.product = product;
                detail.product_id = product.id;
                detail.product_attribute_id = product['product_attribute_id'];
                detail.unity_id = product.unity_id;
                detail.unity_sym = product.unity_symbol;
                detail.code = product.code;
                detail.upc = product.upc;
                detail.reference = product.reference;
                detail.product_name = Util.formatProdName(product);
                detail.version = product.version;
                detail.quantity = product.quantity;
                detail.cost_wt = parseFloat(parseFloat(String(product.cost_wt)).toFixed(6));
                detail.cost = Util.removeTaxes(detail.cost_wt);
                detail.total = parseFloat((detail.quantity * detail.cost).toFixed(6));
                detail.total_wt = parseFloat((detail.quantity * detail.cost_wt).toFixed(6));
                detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(6));
                detail.discounts = 0;
                detail.product_cost = product.product_cost;
                detail.stock = product.stock;
                detail.service = product['service'];
                
                state.list.push(detail);
            }
        },
        update(state: PurchaseQuotationDetailState, detail: PurchaseQuotationDetail) {
            detail.quantity = parseInt(String(detail.quantity));
            detail.cost_wt = parseFloat(String(detail.cost_wt));
            detail.cost = Util.removeTaxes(detail.cost_wt);
            detail.total = detail.quantity * detail.cost;
            detail.total_wt = detail.quantity * detail.cost_wt;
            detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(6));
            detail.total_wt = parseFloat(detail.total_wt.toFixed(6));
        },
        updateList(state: PurchaseQuotationDetailState, data: any) {
            let idx = -1;
            state.list.forEach((det, index) => {
                if (det.product_id == data.detail.product_id && det.purchase_quotation_supplier_id == data.supplier)
                    idx = index;
            });
            if (idx >= 0) {

                state.list[idx].quantity = data.detail.quantity;
                state.list[idx].cost = data.detail.cost;
                state.list[idx].cost_wt = data.detail.cost_wt;
                state.list[idx].total = data.detail.total;
                state.list[idx].total_wt = data.detail.total_wt;
                state.list[idx].taxes = data.detail.taxes;
                state.list[idx].reference = data.detail.reference;
            }
        },
        delete(state: PurchaseQuotationDetailState, index: number) {
            state.list.splice(index, 1);
        },
    });
}
const orderdetailModule=new PurchaseQuotationDetailModule();
export default orderdetailModule;