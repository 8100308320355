import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Assignment from '../../entities/logistics/assignment'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import AssignmentDetail from '@/store/entities/logistics/assignmentdetail';
import AssignmentHistory from '@/store/entities/sales/history';
import FixedAsset from '@/store/entities/logistics/fixedasset';

interface AssignmentState extends ListState<Assignment>{
    editModel: Assignment;
    hisLoading: boolean;
    history: Array<AssignmentHistory>;
    details: Array<AssignmentDetail>;
}
class AssignmentModule extends ListModule<AssignmentState, any, Assignment>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Assignment>(),
        loading: false,
        hisLoading: false,
        editModel:new Assignment(),
        history: new Array<AssignmentHistory>(),
        details: new Array<AssignmentDetail>(),
        path: 'assignments'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastNumber(context: ActionContext<AssignmentState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/assignments/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<AssignmentState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/assignments/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async print(context: ActionContext<AssignmentState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.get('/api/assignment-pdf', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            return response.data.result;
        },
        async confirm(context: ActionContext<AssignmentState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/assignments/confirm', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return true;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: AssignmentState, model: Assignment) {
            state.editModel = Object.assign({});
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.number = '00000';
            state.editModel.delivery_by_id = Util.abp.session.employeeId;
            state.editModel.details = new Array<AssignmentDetail>();
            state.details = new Array<AssignmentDetail>();
        },
        edit(state: AssignmentState, model: Assignment) {
            state.editModel = model;
            state.details = model.details;
        },
        addAsset(state: AssignmentState, asset: FixedAsset) {
            let exist = state.list.filter((detail: any) => {
                return detail.fixed_asset_id === asset.id;
            });

            if (exist.length == 0) {
                let detail = new AssignmentDetail();
                detail.assignment_id = state.editModel.id;
                detail.product_id = asset.product_id;
                detail.fixed_asset_id = asset.id;
                detail.product_name = asset.name;
                detail.serial = asset.serial;
                detail.code = asset.code;

                state.details.push(detail);
            }
        },
        deleteAsset(state: AssignmentState, index: number) {
            state.details.splice(index, 1);
        },
    });
}
const assignmentModule=new AssignmentModule();
export default assignmentModule;