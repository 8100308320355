import Entity from '../entity'

export default class ProductCodebar extends Entity<number>{
    key: string;
    warehouse_id: number;
    user_id: number;
    product_id: number;
    product_attribute_id: number;
    name: string;
    manufacturer: string;
    quantity: number;
    copies: number;
    code: string;
    upc: string;
    reference: string;
    category_name: string;
    unity_name: string;
    sticker: any;
    batch_id: number;
}


