import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Refund from '../../entities/logistics/refund'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import RefundDetail from '@/store/entities/logistics/refunddetail';
import RefundHistory from '@/store/entities/sales/history';
import FixedAsset from '@/store/entities/logistics/fixedasset';

interface RefundState extends ListState<Refund>{
    editModel: Refund;
    hisLoading: boolean;
    history: Array<RefundHistory>;
    details: Array<RefundDetail>;
    employee: number;
    refund_type: string;
}
class RefundModule extends ListModule<RefundState, any, Refund>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Refund>(),
        loading: false,
        hisLoading: false,
        editModel: new Refund(),
        history: new Array<RefundHistory>(),
        details: new Array<RefundDetail>(),
        employee: null,
        refund_type: 'D',
        path: 'refunds'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastNumber(context: ActionContext<RefundState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/refunds/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<RefundState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/refunds/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async print(context: ActionContext<RefundState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.get('/api/refund-pdf', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            return response.data.result;
        },
        async confirm(context: ActionContext<RefundState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/refunds/confirm', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return true;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: RefundState, model: Refund) {
            state.editModel = Object.assign({});
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.refund_type = 'D';
            state.editModel.number = '00000';
            state.editModel.received_by_id = Util.abp.session.employeeId;
            state.editModel.details = new Array<RefundDetail>();
            state.details = new Array<RefundDetail>();
        },
        edit(state: RefundState, model: Refund) {
            state.editModel = model;
            state.details = model.details;
        },
        addAsset(state: RefundState, asset: FixedAsset) {
            let exist = state.list.filter((detail: any) => {
                return detail.fixed_asset_id === asset.id;
            });

            if (exist.length == 0) {
                let detail = new RefundDetail();
                detail.refund_id = state.editModel.id;
                detail.product_id = asset.product_id;
                detail.fixed_asset_id = asset.id;
                detail.product_name = asset.name;
                detail.serial = asset.serial;
                detail.code = asset.code;
                detail.conservation_id = Util.abp.list.get('E_CONSERV','SU').id;

                state.details.push(detail);
            }
        },
        deleteAsset(state: RefundState, index: number) {
            state.details.splice(index, 1);
        },
        setEmployee(state: RefundState, employee: number) {
            state.employee = employee;
        },
        setRefundType(state: RefundState, type: string) {
            state.refund_type = type;
        },
    });
}
const refundModule=new RefundModule();
export default refundModule;