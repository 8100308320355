import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ReferralGuideDetail from '../../entities/sales/referralguidedetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import ReferralGuide from '@/store/entities/sales/referralguide';

interface ReferralGuideDetailState extends ListState<ReferralGuideDetail>{
    editModel:ReferralGuideDetail;    
    referralguide:number;    
}
class ReferralGuideDetailModule extends ListModule<ReferralGuideDetailState,any,ReferralGuideDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ReferralGuideDetail>(),
        loading:false,
        editModel: new ReferralGuideDetail(),
        activeList: new Array<ReferralGuideDetail>(),
        referralguide: 0,
        path: 'referral-guides'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<ReferralGuideDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: ReferralGuideDetailState, model: ReferralGuideDetail) {
            state.editModel = model;
        },
        init(state: ReferralGuideDetailState, model: ReferralGuide) {
            state.editModel = Object.assign({});
            if (model != undefined) {
                state.referralguide = model.id;
                state.list = model.details;
            }
        },
        setProduct(state: ReferralGuideDetailState, product: any) {
            state.editModel.product = product;
            state.editModel.product.manufacturer_name = product.manufacturer.name;
            state.editModel.product.category_name = product.category_default != undefined ? product.category_default.name : '';

        },
        add(state: ReferralGuideDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id && detail.product_attribute_id == product['product_attribute_id'];
            });

            if (exist.length > 0) {
                exist[0].quantity += product.quantity;
            } else {
                let detail = new ReferralGuideDetail();
                detail.referral_guide_id = state.editModel.id;
                detail.product_id = product.id;
                detail.product_attribute_id = product['product_attribute_id'];
                detail.warehouse_id = (product['warehouse_id'] != undefined ? product['warehouse_id'] : Util.abp.session.warehouseId);
                detail.unity_id = product.unity_id;
                detail.unity_sym = product.unity_symbol;
                detail.code = product.code;
                detail.upc = product.upc;
                detail.product_name = Util.formatProdName(product);
                detail.version = product.version;
                detail.stock = product.stock;
                detail.quantity = product.quantity;
                detail.number = state.list.length + 1;

                state.list.push(detail);
            }
        },
        addElement(state: ReferralGuideDetailState) {
            let detail = new ReferralGuideDetail();
            detail.referral_guide_id = state.editModel.id;
            detail.product_id = null;
            detail.product_attribute_id = null;
            detail.warehouse_id = Util.abp.session.warehouseId;
            detail.unity_id = null;
            detail.unity_sym = null;
            detail.code = null;
            detail.upc = null;
            detail.product_name = '';
            detail.stock = 0;
            detail.quantity = 1;
            detail.number = state.list.length + 1;

            state.list.push(detail);
        },
        update(state: ReferralGuideDetailState, detail: ReferralGuideDetail) {
            detail.quantity = parseInt(String(detail.quantity));            
        },
        updateList(state: ReferralGuideDetailState, data: any) {
            state.list[data.index].warehouse_id = data.detail.warehouse_id;
            state.list[data.index].quantity = data.detail.quantity;
        },
        delete(state: ReferralGuideDetailState, index: number) {
            state.list.splice(index, 1);
        },
    });
}
const referralguidedetailModule=new ReferralGuideDetailModule();
export default referralguidedetailModule;