import Entity from '../entity'

export default class SpecialPrice extends Entity<number> {
    special_price_rule_id: number;
    product_id: number;
    product_attribute_id: number;
    name: string;
    groups: string;
    customers: string;
    payment_methods: string;
    price_rules: string;
    discount_type: string;
    discount: number;
    from: string;
    to: string;
    apply_cost: boolean;
    active: boolean;
}

