import Entity from '../entity'

export default class Attribute extends Entity<number>{
    attribute_group_id: number;
    name: string;
    color: string;
    position: number;
    reference: string;
}


