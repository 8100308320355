import Entity from '../entity'
import ReferralGuideDetail from './referralguidedetail';
import Customer from '../customers/customer';
import Carrier from '../stocks/carrier';
import Address from '../customers/address';
import Vehicle from '../logistics/vehicle';

export default class ReferralGuide extends Entity<number>{
    reference: string;
    customer_id: number;
    branch_id: number;
    guide_type_id: number;
    transfer_mode_id: number;
    warehouse_output_id: number;
    warehouse_income_id: number;
    address_origin_id: number;
    address_destination_id: number;
    carrier_id: number;
    driver_id: number;
    invoice_type_id: number;
    current_state_id: number;
    sale_id: number;
    purchase_id: number;
    transfer_order_id: number;
    issue_date: string;
    transfer_date: string;
    invoice_serie: string;
    invoice_number: string;
    invoice_date: string;
    gross_weight: number;
    nb_packages: number;
    contact: string;
    reason: string;
    purchase_order_nro: string;
    sale_nro: string;
    notes: string;
    valid: boolean;
    invoiced: boolean;
    move_stock: boolean;
    details: Array<ReferralGuideDetail>;

    address_origin: string;
    address_destination: Address;
    guide_type: any;
    carrier_address: string;
    invoice_type: any;
    customer: Customer;
    carrier: Carrier;
    driver: Vehicle;
    customer_document: string;
    customer_name: string;
    address_destination_text: string;
}


