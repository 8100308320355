import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ProductSerie from '../../entities/stocks/productserie'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ProductSerieState extends ListState<ProductSerie>{
    editModel: ProductSerie;
    product: number;
}
class ProductSerieModule extends ListModule<ProductSerieState, any, ProductSerie>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ProductSerie>(),
        loading:false,
        editModel:new ProductSerie(),
        product:0,
        activeList: new Array<ProductSerie>(),
        path: 'product-series'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<ProductSerieState, any>, payload: any) {
            context.state.loading = true;
            payload.data.product_id = context.state.product;
            let response = await Ajax.post('/api/product-series', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.list = response.data.result;
            context.state.loading = false;
            return response.data.result;
        },
        async createBatch(context: ActionContext<ProductSerieState, any>, payload: any) {
            context.state.loading = true;
            payload.data.product_id = context.state.product;
            let response = await Ajax.post('/api/product-series-batch', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.list = response.data.result;
            context.state.loading = false;
            return response.data.result;
        },
        async update(context: ActionContext<ProductSerieState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.product_id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async delete(context: ActionContext<ProductSerieState, any>, payload: any) {
            context.state.loading = true;
            let id = context.state.product + '_' + payload.data.number;
            let response = await Ajax.delete('/api/product-series/' + id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.list = response.data.result;
            context.state.loading = false;
        },
        async countSeries(context: ActionContext<ProductSerieState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/product-series/count', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return reponse.data.result;
        }
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setProduct(state: ProductSerieState, product: number) {
            state.product = product;
        },
    });
}
const productserieModule=new ProductSerieModule();
export default productserieModule;