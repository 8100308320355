import Entity from '../entity'
import ProductField from './productfield';

export default class Product extends Entity<number>{
    supplier_id: number;
    manufacturer_id: number;
    line_id: number;
    category_id: number;
    subcategory_id: number;
    tax_id: number;
    product_type_id: number;
    origin_id: number;
    available_id: number;
    currency_id: number;
    unity_id: number;
    unity_conver_id: number;
    pack_type_id: number;
    conversion: number;
    service_type: string;
    name: string;
    description: string;
    original_name: string;
    code: string;
    upc: string;
    reference: string;
    profit: number;
    price: number;
    price_wt: number;
    cost: number;
    active: boolean;
    has_combinations: boolean;
    is_pack: boolean;
    has_stock: boolean;
    has_repair: boolean;
    last_purchase: string;
    tax: any;
    quantity: number;
    minimal_quantity: number;
    nro_comment: number;
    default_attribute: number;
    public_name: string;
    public_visibility: boolean;
    fields: ProductField;

    images: Array<any>;
    product_attribute_id: number;
    price_rule_id: number;
    stock_physical: number;
    stock_reserved: number;
    stock: number;
    category_name: string;
    manufacturer_name: string;
    origin_name: string;
    version: string;
    unity_name: string;
    prices: Array<any>;
    vehicles: Array<number>;
    locations: Array<number>;
    categories: Array<number>;
    unity: any;
    cost_wt: number;
    product_cost: number;
    tax_rate: number;
    unity_symbol: string;
    unity_conver_symbol: string;
    weight: number;

    url: string;
    url_icon: string;
    url_catalog: string;
    url_box: string;
    url_preview: string;

}


