import Entity from '../entity'

export default class Sticker extends Entity<number>{
    page_type_id: number;
    number: number;
    name: string;
    template: string;
    epl: string;
    format: string;
    module: string;
    active: boolean;
    printers: Array<any>;
    subtitle: string;
}


