import Entity from '../entity'
import Customer from '../customers/customer';
import WorkOrderProduct from './workorderproduct';
import WorkOrderDocument from './workorderdocument';
import WorkOrderService from './workorderservice';
import WorkOrderTask from './workordertask';
import WorkOrderVehicle from './workordervehicle';
import WorkOrderPlan from './workorderplan';
import Product from '../catalogs/product';
import Project from './project';

export default class WorkOrder extends Entity<number>{
    reference: string;
    serie: string;
    number: string;
    branch_id: number;
    project_id: number;
    customer_id: number;
    responsable_id: number;
    cost_center_id: number;
    work_product_id: number;
    request_by_id: number;
    work_type_id: number;
    priority_id: number;
    sale_quotation_id: number;
    current_state_id: number;
    customer_responsable: string;
    description: string;
    quantity_ordered: number;
    quantity_production: number;
    quantity_max: number;
    progress: number;
    total_work_hours: number;
    total_work_cost: number;
    total_products: number;
    total_purchases: number;
    total_third_party: number;
    total_vehicles: number;
    total_cost: number;
    issue_date: string;
    start_date: string;
    finish_date: string;
    delivery_date: string;
    notes: string;
    valid: boolean;
    rejected: boolean;
    assigned: boolean;
    requested_assign: boolean;
    require_purchase: boolean;
    waiting_stocks: boolean;
    invoiced: boolean;
    in_process: boolean;
    finished: boolean;
    delivered: boolean;
    quotation_nro: string;
    customer_purchase_order: string;

    project: Project;
    work_product: Product;
    customer: Customer;
    customer_name: string;
    work_product_name: string;
    nro: string;
    delivery_orders: Array<any>;

    
    products: Array<WorkOrderProduct>;
    documents: Array<WorkOrderDocument>;
    services: Array<WorkOrderService>;
    tasks: Array<WorkOrderTask>;
    vehicles: Array<WorkOrderVehicle>;
    planning: Array<WorkOrderPlan>;
}


