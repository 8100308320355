import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PurchaseDebitNoteDetail from '../../entities/purchases/purchasedebitnotedetail'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import PurchaseDebitNote from '@/store/entities/purchases/purchasedebitnote';

interface PurchaseDebitNoteDetailState extends ListState<PurchaseDebitNoteDetail>{
    editModel:PurchaseDebitNoteDetail;    
    purchasedebitnote:number;    
}
class PurchaseDebitNoteDetailModule extends ListModule<PurchaseDebitNoteDetailState,any,PurchaseDebitNoteDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PurchaseDebitNoteDetail>(),
        loading:false,
        editModel: new PurchaseDebitNoteDetail(),
        activeList: new Array<PurchaseDebitNoteDetail>(),
        purchasedebitnote: 0,
        path: 'purchase-debit-notes'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<PurchaseDebitNoteDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: PurchaseDebitNoteDetailState, model: PurchaseDebitNoteDetail) {
            state.editModel = model;
        },
        init(state: PurchaseDebitNoteDetailState, model: PurchaseDebitNote) {
            state.editModel = Object.assign({});
            if (model != undefined) {
                state.purchasedebitnote = model.id;
                state.list = model.details;
            }
        },
        setProduct(state: PurchaseDebitNoteDetailState, product: any) {
            state.editModel.product = product;
            state.editModel.product.manufacturer_name = product.manufacturer.name;
            state.editModel.product.category_name = product.category_default != undefined ? product.category_default.name : '';

        },
        add(state: PurchaseDebitNoteDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id && detail.product_attribute_id == product['product_attribute_id'];
            });

            if (exist.length > 0) {
                exist[0].quantity++;
                exist[0].total = exist[0].quantity * exist[0].cost;
                exist[0].total_wt = exist[0].quantity * exist[0].cost_wt
                exist[0].taxes = parseFloat((exist[0].total_wt - exist[0].total).toFixed(3));
            } else {
                let detail = new PurchaseDebitNoteDetail();
                detail.purchase_debit_note_id = state.editModel.id;
                detail.product_id = product.id;
                detail.product_attribute_id = product['product_attribute_id'];
                detail.warehouse_id = (product['warehouse_id'] != undefined ? product['warehouse_id'] : Util.abp.session.warehouseId);
                detail.unity_id = product.unity_id;
                detail.code = product.code;
                detail.upc = product.upc;
                detail.product_name = Util.formatProdName(product);
                detail.version = product.version;
                detail.quantity = 1;
                detail.cost = product.cost;
                detail.cost_wt = product.cost_wt;
                detail.total = detail.quantity * detail.cost;
                detail.total_wt = detail.quantity * detail.cost_wt
                detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(3));
                detail.service = product['service'];
                
                state.list.push(detail);
            }
        },
        update(state: PurchaseDebitNoteDetailState, detail: PurchaseDebitNoteDetail) {
            detail.quantity = parseInt(String(detail.quantity));

            detail.total = detail.quantity * detail.cost;
            detail.total_wt = (detail.quantity * detail.cost_wt);

            detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(3));            
        },
        delete(state: PurchaseDebitNoteDetailState, index: number) {
            state.list.splice(index, 1);
        },
        save(state: PurchaseDebitNoteDetailState, detail: PurchaseDebitNoteDetail) {
            for (var i = 0; i < state.list.length; i++) {
                if (state.list[i].id == detail.id) {
                    state.list[i] = detail;
                }
            }
        },
    });
}
const purchasedebitnotedetailModule=new PurchaseDebitNoteDetailModule();
export default purchasedebitnotedetailModule;