import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PriceRule from '../../entities/catalogs/pricerule'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface PriceRuleState extends ListState<PriceRule>{
    editModel: PriceRule;
}
class PriceRuleModule extends ListModule<PriceRuleState, any, PriceRule>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PriceRule>(),
        loading:false,
        editModel:new PriceRule(),
        activeList: new Array<PriceRule>(),
        path: 'price-rules'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: PriceRuleState, model: PriceRule) {
            state.editModel = Object.assign({});
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.active = true;
            state.editModel.main = false;
        },
    });
}
const priceruleModule=new PriceRuleModule();
export default priceruleModule;