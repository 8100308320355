import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import DeliveryOrder from '../../entities/stocks/deliveryorder'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'
import Customer from '@/store/entities/customers/customer';
import Currency from '@/store/entities/preferences/currency';
import DeliveryOrderDetail from '@/store/entities/stocks/deliveryorderdetail';
import DeliveryOrderHistory from '@/store/entities/sales/history';
import Sale from '@/store/entities/sales/sale';
import WorkOrder from '@/store/entities/production/workorder';
import RepairOrder from '@/store/entities/workshops/repairorder';
import axios from 'axios'

interface DeliveryOrderState extends ListState<DeliveryOrder>{
    editModel:DeliveryOrder;    
    hisLoading: boolean;
    hasChanges: boolean;
    history: Array<DeliveryOrderHistory>;
    reportList: Array<any>; 
    filename: string;     
}
class DeliveryOrderModule extends ListModule<DeliveryOrderState,any,DeliveryOrder>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<DeliveryOrder>(),
        loading:false,
        hisLoading: false,
        hasChanges: false,
        editModel: new DeliveryOrder(),
        activeList: new Array<DeliveryOrder>(),
        history: new Array<DeliveryOrderHistory>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'delivery-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastNumber(context: ActionContext<DeliveryOrderState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/delivery-orders/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<DeliveryOrderState, any>, payload: any) {
            context.state.hisLoading = true; 
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/delivery-orders/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async print(context: ActionContext<DeliveryOrderState, any>, payload: any) {
            context.state.loading = true;

            let format = Util.abp.setting.get('DELIVERY_PRINT_FORMAT');
            let localUrl = Util.abp.setting.get('LOCAL_PRINTER_URL');
            let mode = Util.abp.setting.get('REMOTE_PRINTER_MODE');
            let method = Util.abp.setting.get('REMOTE_PRINTER_METHOD');
            let useRemote = Util.abp.setting.get('USE_REMOTE_PRINTER');

            let api = (format == 'A4' ? 'delivery-orders-pdf' : 'delivery-orders-receipt');

            let response = await Ajax.get('/api/' + api, { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            if (mode == 'LOCAL' && useRemote == 1 && format == 'TK') {
                if (response.data.result.localUrl != undefined) localUrl = response.data.result.localUrl;
                if (method == 'POST') {
                    await axios.post(localUrl, response.data.result);
                } else {
                    let href: any = localUrl + "?endpoint=" + (url + 'api/' + api) + "&token=" + window.abp.auth.getToken() + "&id=" + payload.id;
                    const link = document.createElement('a');
                    link.href = href;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    return '';
                    //await axios.get(localUrl, { params: response.data.result });
                }
                return '';
            } else
                return response.data.result;  
        },
        async deliveryProducts(context: ActionContext<DeliveryOrderState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/delivery-orders/delivery', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return true;
        },

        async reportDeliveryNotes(context: ActionContext<DeliveryOrderState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/delivery-notes', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: DeliveryOrderState, model: DeliveryOrder) {
            state.editModel = Object.assign({});

            let currency = new Currency();
            currency.id = Util.abp.setting.get('DEFAULT_CURRENCY');
            currency.sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            currency.conversion_rate = 1;
            state.editModel = Object.assign({});

            state.editModel.delivery_date = Util.abp.clock.today();
            state.editModel.warehouse_id = Util.abp.session.warehouseId;
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.currency = currency;
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.exchange_rate = 1;
            state.editModel.total_wt = 0.00;
            state.editModel.total_paid = 0.00;
            state.editModel.balance = 0.00;
            state.editModel.customer_id = Util.abp.setting.get('DEFAULT_CUSTOMER');
            state.editModel.serie = Util.abp.setting.get('DELIVERY_SERIE');
            state.editModel.received_by_id = Util.abp.session.employeeId;
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PURCHASE_COSTCENTER');   
            state.editModel.reason_id = Util.abp.setting.get('STOCK_MVT_DEC_REASON_DEFAULT');
            state.editModel.number = '000000';
            state.editModel.details = new Array<DeliveryOrderDetail>();
        },
        edit(state: DeliveryOrderState, model: DeliveryOrder) {
            state.editModel = model;
            if (model.customer != null)
                state.editModel.customer_name = model.customer.fullname;
        },
        setCurrency(state: DeliveryOrderState, currency: any) {
            state.editModel.currency = currency;
            state.editModel.currency_id = currency.id;
            state.editModel.currency_sign = currency.sign;
            state.editModel.exchange_rate = currency.conversion_rate;
            state.hasChanges = true;
        },
        setTotals(state: DeliveryOrderState, data: any) {
            if (state.editModel.total_wt != data.total_wt) state.hasChanges = true;
            state.editModel.total_wt = data.total_wt;
            state.editModel.total_paid = data.total_paid;
            state.editModel.balance = state.editModel.total_wt - state.editModel.total_paid;
            if (Math.abs(state.editModel.balance) < 0.01) state.editModel.balance = 0;
        },
        loadFromSale(state: DeliveryOrderState, model: Sale) {

            var today = new Date();
            
            state.editModel = Object.assign({});
            state.editModel.entity_id = model.id;
            state.editModel.entity = 'sale';
            state.editModel.reference = model.reference;
            state.editModel.delivery_date = Util.abp.clock.today();
            state.editModel.customer_id = model.customer_id;
            state.editModel.serie = Util.abp.setting.get('DELIVERY_SERIE');
            state.editModel.number = '000000';
            state.editModel.origin_document = model.invoice_serie + '-' + model.invoice_number;
            state.editModel.details = new Array<DeliveryOrderDetail>();

            model.details.forEach((item, index) => {
                let det = new DeliveryOrderDetail();
                det = Util.extend(true, {}, item);
                det.entity = 'sale';
                det.entity_id = model.id;
                delete det.id;
                state.editModel.details.push(det);
            });
        },
        loadFromWorkOrder(state: DeliveryOrderState, model: WorkOrder) {

            var today = new Date();

            state.editModel = Object.assign({});
            state.editModel.entity_id = model.id;
            state.editModel.entity = 'work_order';
            state.editModel.reference = model.reference;
            state.editModel.delivery_date = Util.abp.clock.today();
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PRODUCTION_COSTCENTER');
            state.editModel.branch_id = model.branch_id;
            state.editModel.warehouse_id = Util.abp.session.warehouseId;
            state.editModel.customer_id = model.customer_id;
            state.editModel.customer_name = model.customer_name;
            state.editModel.received_by_id = Util.abp.session.employeeId;
            state.editModel.serie = Util.abp.setting.get('DELIVERY_SERIE');
            state.editModel.number = '000000';
            state.editModel.origin_document = model.nro;
            state.editModel.reason_id = Util.abp.setting.get('STOCK_MVT_WO_OUT');
            state.editModel.details = new Array<DeliveryOrderDetail>();
            
            model.products.forEach((item, index) => {
                if (item.quantity_received < item.quantity_total && item.stock >= item.quantity_total) {
                    let det = new DeliveryOrderDetail();
                    det = Util.extend(true, {}, item);
                    delete det.id;
                    delete det.unity;
                    det.warehouse_id = Util.abp.session.warehouseId;
                    det.quantity = item.quantity_total - item.quantity_received;
                    det.entity = 'work_order';
                    det.entity_id = model.id;

                    state.editModel.details.push(det);
                }
            });
        },
        loadFromWorkOrders(state: DeliveryOrderState, list: Array<WorkOrder>) {

            var today = new Date();

            let item = list[0];
            state.editModel = Object.assign({});
            state.editModel.entity_id = item.id;
            state.editModel.entity = 'work_order';
            state.editModel.reference = item.reference;
            state.editModel.delivery_date = Util.abp.clock.today();
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PRODUCTION_COSTCENTER');
            state.editModel.branch_id = item.branch_id;
            state.editModel.warehouse_id = Util.abp.session.warehouseId;
            state.editModel.customer_id = item.customer_id;
            state.editModel.customer_name = item.customer.fullname;
            state.editModel.received_by_id = Util.abp.session.employeeId;
            state.editModel.serie = Util.abp.setting.get('DELIVERY_SERIE');
            state.editModel.number = '000000';
            state.editModel.origin_document = null;
            state.editModel.reason_id = Util.abp.setting.get('STOCK_MVT_WO_OUT');
            state.editModel.details = new Array<DeliveryOrderDetail>();

            let order_number = [];
            list.forEach(model => {
                order_number.push(model.nro);

                model.products.forEach((item, index) => {
                    if (item.quantity_received < item.quantity_total) {
                        let det = new DeliveryOrderDetail();
                        det = Util.extend(true, {}, item);
                        det.warehouse_id = Util.abp.session.warehouseId;
                        det.quantity = item.quantity_total - item.quantity_received;
                        det.entity = 'work_order';
                        det.entity_id = model.id;
                        delete det.id;
                        state.editModel.details.push(det);
                    }
                });
            });

            state.editModel.origin_document = order_number.join('/');
        },
        loadFromRepairOrder(state: DeliveryOrderState, model: RepairOrder) {

            var today = new Date();

            state.editModel = Object.assign({});
            state.editModel.entity_id = model.id;
            state.editModel.entity = 'repair_order';
            state.editModel.reference = model.reference;
            state.editModel.delivery_date = Util.abp.clock.today();
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PRODUCTION_COSTCENTER');
            state.editModel.warehouse_id = model.warehouse_id;
            state.editModel.customer_id = model.customer_id;
            state.editModel.customer_name = model.customer_name;
            state.editModel.received_by_id = Util.abp.session.employeeId;
            state.editModel.serie = Util.abp.setting.get('DELIVERY_SERIE');
            state.editModel.number = '000000';
            state.editModel.origin_document = model.nro;
            state.editModel.reason_id = Util.abp.setting.get('STOCK_MVT_RO_OUT');
            state.editModel.details = new Array<DeliveryOrderDetail>();

            model.products.forEach((item, index) => {
                if (item.quantity_received < item.quantity && item.stock >= item.quantity) {
                    let det = new DeliveryOrderDetail();
                    det = Util.extend(true, {}, item);
                    delete det.id;
                    delete det.unity;
                    det.warehouse_id = item.warehouse_id;
                    det.quantity = item.quantity - item.quantity_received;
                    det.entity = 'repair_order';
                    det.entity_id = model.id;

                    state.editModel.details.push(det);
                }
            });
        },
        setChanged(state: DeliveryOrderState, value: boolean) {
            state.hasChanges = value;
        },
    });
}
const deliveryorderModule=new DeliveryOrderModule();
export default deliveryorderModule;