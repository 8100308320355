import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import WorkOrderDocument from '../../entities/production/workorderdocument'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import WorkOrder from '@/store/entities/production/workorder';

interface WorkOrderDocumentState extends ListState<WorkOrderDocument>{
    editModel:WorkOrderDocument;    
    order:number;    
}
class WorkOrderDocumentModule extends ListModule<WorkOrderDocumentState,any,WorkOrderDocument>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<WorkOrderDocument>(),
        loading:false,
        editModel: new WorkOrderDocument(),
        order: 0,
        path: 'work-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<WorkOrderDocumentState, any>, payload: any) {
        },
        async create(context: ActionContext<WorkOrderDocumentState, any>, payload: any) {
            context.state.loading = true;
            payload.data.work_order_id = context.state.order;
            let response = await Ajax.post('/api/work-order-documents', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list = response.data.result;
            context.state.totalCount = context.state.list.length;
        },
        async delete(context: ActionContext<WorkOrderDocumentState, any>, payload: any) {
            context.state.loading = true;
            let id = payload.data.id;
            await Ajax.delete('/api/work-order-documents/' + id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let item = context.state.list.filter(item => {
                return item.id == id;
            })[0];

            let index = context.state.list.indexOf(item);
            context.state.list.splice(index, 1);
            context.state.totalCount = context.state.list.length;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        init(state: WorkOrderDocumentState, model: WorkOrder) {
            state.editModel = Object.assign({});
            state.order = 0;
            state.list = [];
            if (model != undefined) {
                state.order = model.id;
                state.list = model.documents;
            }
            state.totalCount = state.list.length;
        },
    });
}
const workorderdocumentModule=new WorkOrderDocumentModule();
export default workorderdocumentModule;