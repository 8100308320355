import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Workshop from '../../entities/workshops/workshop'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'
import Employee from '@/store/entities/admin/employee';

interface WorkshopState extends ListState<Workshop>{
    editModel:Workshop;
    activeList: Array<Workshop>;
    employees: Array<Employee>;
}
class WorkshopModule extends ListModule<WorkshopState,any,Workshop>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Workshop>(),
        loading:false,
        editModel:new Workshop(),
        activeList: new Array<Workshop>(),
        employees: new Array<Employee>(),
        path: 'workshops',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<WorkshopState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let item = response.data.result;
            context.state.activeList.push(response.data.result);
            return item;
        },
        async get(context: ActionContext<WorkshopState, any>, payload: any) {
            if (payload.id == undefined) return {};
            let existing = context.state.list.filter((item: any) => {
                return item.id == payload.id;
            });

            if (existing.length > 0) return existing[0];
            
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.list.push(reponse.data.result);
            context.state.loading = false;
            return reponse.data.result;
        },
        async getLastCode(context: ActionContext<WorkshopState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/workshops/last-code', { params: payload.data}).then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: WorkshopState, model: Workshop) {
            state.editModel = Object.assign({});
            if (model != null && model != undefined)
                state.editModel = model;

            state.editModel.code = '';
            //state.editModel.address = Object.assign({});
            //state.editModel.address.country_id = 171;
            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.warehouse_id = Util.abp.session.warehouseId;
            state.editModel.employees = [];
            state.editModel.employee = null;
            state.employees = [];
            
        },
        edit(state: WorkshopState, model: Workshop) {
            state.editModel = model;
            state.editModel.employee = null;
            state.employees = model.employees;

            //if (model.address == null) {
            //    state.editModel.address = Object.assign({});
            //    state.editModel.address.country_id = 171;
            //}
        },
        addEmployee(state: WorkshopState, item: any) {
            let exists = state.employees.filter(data => {
                return data.id == item.id;
            });
            if (exists.length > 0) return;

            state.employees.push(item);
        },
        deleteEmployee(state: WorkshopState, idx: number) {
            state.employees.splice(idx, 1);
        },
    });
}
const workshopModule=new WorkshopModule();
export default workshopModule;