import Entity from '../entity'

export default class FixedAssetType extends Entity<number>{
    name: string;
    code: string;
    account_id: number;
    equipment_type_id: number;
    depreciation_account_id: number;
    expense_account_id: number;
    depreciation_method_id: number;
    useful_life_unit: string;
    useful_life: number;
}


