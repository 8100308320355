import Entity from '../entity'
import EntryOrderDetail from './entryorderdetail';
import Supplier from '../purchases/supplier';

export default class DeliveryOrder extends Entity<number>{
    reference: string;
    serie: string;
    number: string;
    supplier_id: number;
    warehouse_id: number;
    location_id: number;
    current_state_id: number;
    received_by_id: number;
    reason_id: number;
    cost_center_id: number;
    purchase_planning_id: number;
    entity_id: number;
    entity: string;
    referral_guide: string;
    origin_document: string;
    invoice_number: string;
    entry_date: string;
    notes: string;
    received: boolean;
    invoiced: boolean;
    details: Array<EntryOrderDetail>;
    
    nro_document: string;

    supplier: Supplier;
    supplier_name: string;
    nro: string;
}


