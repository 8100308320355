import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Booking from '@/store/entities/bookings/booking';
import BookingHistory from '@/store/entities/sales/history';
import BookingProduct from '@/store/entities/bookings/bookingproduct';
import Currency from '@/store/entities/preferences/currency';
import PageResult from '@/store/entities/page-result';

interface BookingState extends ListState<Booking>{
    editModel: Booking;
    hisLoading: boolean;
    openLoading: boolean;
    changed: boolean; 
    history: Array<BookingHistory>;
    openList: Array<Booking>;
}
class BookingModule extends ListModule<BookingState, any, Booking>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Booking>(),
        loading: false,
        hisLoading: false,
        openLoading: false,
        changed: false, 
        editModel:new Booking(),
        activeList: new Array<Booking>(),
        history: new Array<BookingHistory>(),
        openList: new Array<Booking>(),
        path: 'bookings'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<BookingState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Booking>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }
            context.state.list.forEach(a => {
                a.product = a.products[0];
            })

            return context.state.list;
        },
        async get(context: ActionContext<BookingState, any>, payload: any) {
            if (payload.id == undefined) return {};
            
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            
            let item = reponse.data.result as Booking;

            context.state.loading = false;
            return item;
        },
        async getOpenBooking(context: ActionContext<BookingState, any>, payload: any) {
            context.state.openLoading = true;
            let reponse = await Ajax.get('/api/booking-open', { params: payload.data }).catch(e => {
                context.state.openLoading = false;
                return Promise.reject(e);
            });
            context.state.openList = reponse.data.result;
            context.state.openLoading = false;
            return context.state.openList;
        },
        async getLastNumber(context: ActionContext<BookingState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/bookings/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<BookingState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/bookings/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async reserve(context: ActionContext<BookingState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/bookings/reserve', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async print(context: ActionContext<BookingState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.get('/api/booking-invoice', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: BookingState, model: Booking) {
            let date = new Date();
            let serie = Util.abp.setting.get('BOOKING_SERIE');

            let currency = new Currency();
            currency.id = Util.abp.setting.get('DEFAULT_CURRENCY');
            currency.sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            currency.conversion_rate = 1;

            state.editModel = Object.assign({});
            state.editModel.customer = null;
            state.editModel.customer_id = null;
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.price_rule_id = Util.abp.setting.get('DEFAULT_PRICE_RULE_SALE');
            state.editModel.branch_id = Util.abp.session.branchId;       
            state.editModel.including_taxes = true;
            state.editModel.currency = currency;
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.exchange_rate = 1;            
            state.editModel.discount_type = 'amount';
            state.editModel.subtotal = 0.00;
            state.editModel.subtotal_wt = 0.00;
            state.editModel.discounts = 0.00;
            state.editModel.taxes = 0.00;
            state.editModel.total = 0.00;
            state.editModel.total_wt = 0.00;
            state.editModel.total_paid = 0.00;
            state.editModel.balance = 0.00;
            state.editModel.serie = (serie == 'YYYY' ? Util.abp.clock.today().substr(0, 4) : serie);
            state.editModel.number = 0;
            state.changed = false; 
            state.editModel.products = new Array<BookingProduct>();
        },
        edit(state: BookingState, model: Booking) {
            state.editModel = model;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.currency_sign = model.currency.sign;
            state.changed = false;             
        },
        setCurrency(state: BookingState, currency: any) {
            state.editModel.currency = currency;
            state.editModel.currency_id = currency.id;
            state.editModel.currency_sign = currency.sign;
            state.editModel.exchange_rate = currency.conversion_rate;
            state.changed = true;
        },
        setPriceRule(state: BookingState, id: any) {
            state.editModel.price_rule_id = id;
        },
        setChanged(state: BookingState, value: boolean) {
            state.changed = value; 
        },
        updatePayments(state: BookingState, total_paid: any) {
            state.editModel.total_paid = parseFloat(total_paid);
            state.editModel.balance = parseFloat((state.editModel.total_wt - state.editModel.total_paid).toFixed(2));
            if (state.editModel.total_paid > 0)
                state.editModel.current_state_id = Util.abp.list.get('E_BOOKING', 'PP').id;

            if (state.editModel.total_paid == state.editModel.total_wt)
                state.editModel.current_state_id = Util.abp.list.get('E_BOOKING', 'PA').id;
            state.changed = true;
        },
        setTotals(state: BookingState, data: any) {
            state.editModel.subtotal = data.subtotal;
            state.editModel.subtotal_wt = data.subtotal_wt;
            state.editModel.discounts = data.discounts;
            state.editModel.taxes = data.taxes;
            state.editModel.total = data.total;
            state.editModel.total_wt = data.total_wt;
            state.editModel.total_paid = data.total_paid;
            state.editModel.balance = state.editModel.total_wt - state.editModel.total_paid;
            if (Math.abs(state.editModel.balance) < 0.01) state.editModel.balance = 0;
        },
    });
}
const bookingModule=new BookingModule();
export default bookingModule;