import Entity from '../entity'

export default class FeatureValue extends Entity<number>{
    feature_id: number;
    custom: boolean;
    value: string;
    description: string;
}


