import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Voucher from '../../entities/customers/voucher'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';


interface VoucherState extends ListState<Voucher>{
    editModel:Voucher;
    customer: number;
    reportList: Array<any>;    
}
class VoucherModule extends ListModule<VoucherState,any,Voucher>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Voucher>(),
        loading:false,
        customer: 0,
        editModel: new Voucher(),
        activeList: new Array<Voucher>(),
        reportList: new Array<any>(),
        path: 'vouchers'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<VoucherState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            payload.data.customer = context.state.customer;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Voucher>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }
            return context.state.list;
        },
        async searchCode(context: ActionContext<VoucherState, any>, payload: any) {
            let list = context.state.activeList.filter(a => {
                return a.reference == payload.data.code;
            });
            if (list.length == 0) {
                context.state.loading = true;
                let reponse = await Ajax.get('/api/vouchers/search-code', { params: payload.data }).catch(e => {
                    context.state.loading = false;
                    return Promise.reject(e);
                });
                context.state.loading = false;
                context.state.activeList.push(reponse.data.result);
                return reponse.data.result;
            }
            return null;
        },
        async querySearch(context: ActionContext<VoucherState, any>, payload: any) {
            if (payload.data.query == null || payload.data.query == 'null') return [];
            let list = context.state.activeList.filter(a => {
                return a.reference.toUpperCase() == payload.data.query.toUpperCase();
            });
            if (list.length > 0) {
                return list;
            }

            if (payload.data.query != null && String(payload.data.query) != '' && String(payload.data.query).length <= 5) {
                context.state.loading = true;
                let reponse = await Ajax.get('/api/vouchers/query-search', { params: payload.data }).catch(e => {
                    context.state.loading = false;
                    return Promise.reject(e);
                });
                context.state.activeList = reponse.data.result;
                context.state.loading = false;
                return reponse.data.result;
            } else {
                return [];
            }
        },
        async create(context: ActionContext<VoucherState, any>, payload: any) {
            context.state.loading = true;
            payload.data.customer_id = context.state.customer;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (response.data.result && context.state.activeList != undefined)
                context.state.activeList.push(response.data.result);
            return response.data.result;
        },
        async update(context: ActionContext<VoucherState, any>, payload: any) {
            context.state.loading = true;
            payload.data.customer_id = context.state.customer;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (context.state.activeList != undefined) {
                context.state.activeList.forEach((item, k) => {
                    if (item.id == payload.data.id)
                        context.state.activeList[k] = Util.extend(context.state.activeList[k], payload.data);
                });
            }
            return response.data.result;

        },
        async reportVouchers(context: ActionContext<VoucherState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/report-vouchers', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setCustomer(state: VoucherState, id: number) {
            state.customer = id;
        },
    });
}
const voucherModule=new VoucherModule();
export default voucherModule;