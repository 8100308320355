import Entity from '../entity'
import Address from '../customers/address';

export default class Supplier extends Entity<number>{
    address_id: number;
    document_type_id: number;
    type_id: number;
    origin_id: number;
    code: string;
    document: string;
    name: string;
    email: string;
    contact: string;
    comments: string;
    active: boolean;

    address: Address;
    full_name: string;
}


