import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ProductAttribute from '../../entities/catalogs/productattribute'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface ProductAttributeState extends ListState<ProductAttribute>{
    editModel:ProductAttribute;
    product: number;
}
class ProductAttributeModule extends ListModule<ProductAttributeState,any,ProductAttribute>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10, 
        list: new Array<ProductAttribute>(),
        loading:false,
        editModel:new ProductAttribute(),
        activeList: new Array<ProductAttribute>(),
        product: 0,
        path: 'products-attributes',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<ProductAttributeState, any>, payload: any) {
            context.state.loading = true;
            if (context.state.product > 0) payload.data.product_id = context.state.product;
            let response = await Ajax.post('/api/products-attributes', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async updateImage(context: ActionContext<ProductAttributeState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.put('/api/products-attributes-image', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async rebuild(context: ActionContext<ProductAttributeState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/products-attributes-rebuild', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setProduct(state: ProductAttributeState, product: number) {
            state.product = product;
        },
        setProductMainName(state: ProductAttributeState, data: any) {
            state.list.forEach(pro => {
                pro.name = pro.name.replace(data.oldName, data.name);
            });
        },
    });
}
const productattributeModule=new ProductAttributeModule();
export default productattributeModule;