import Entity from '../entity'

export default class CreditLine extends Entity<number>{
    customer_id: number;
    credit: number;
    used: number;
    balance: number;
    state_id: number;
    issue_date: string;
    valid: boolean;
    rejected: boolean;

    moves: Array<any>;
}


