import Entity from '../entity'

export default class CurrentAccount extends Entity<number>{
    person_type: string;
    document_type: string;
    group: string;
    code: string;
    document: string;
    fullname: string;
    comments: string;
    credit_line: number;
    total_sales: number;
    total_debt: number;
    total_paid: number;
    total_purchases: number;
    balance: number;
}


