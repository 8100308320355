import Entity from '../entity'
import PurchaseRequestDetail from './purchaserequestdetail';
import Currency from '../preferences/currency';

export default class PurchaseRequest extends Entity<number>{
    reference: string;
    branch_id : number;
    cost_center_id : number;
    user_id : number;
    currency_id : number;
    current_state_id: number;
    purchase_planning_id: number;
    work_order_id : number;
    priority_id : number;
    request_type_id: number;
    area_id: number;
    exchange_rate: number;
    total_wt: number;
    order_date: string;
    request_date: string;
    delivery_date: string;
    serie: string;
    number: string;
    notes: string;
    response: string;
    valid: boolean;
    finished: boolean;
    order_number: string;
    nro: string;

    current_state: any;
    details: Array<PurchaseRequestDetail>;
    currency: Currency;
    currency_sign: string;
}


