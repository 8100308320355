import Entity from '../entity'
import InventoryDetail from './inventorydetail';

export default class Inventory extends Entity<number>{
    number: number;
    branch_id: number;
    warehouse_id: number;
    description: string;
    inventory_date: string;
    valid: boolean;

    details: Array<InventoryDetail>;
}


