import Entity from '../entity'

export default class WorkOrderProduct extends Entity<number>{
    work_order_id: number;
    product_type_id: number;
    product_id: number;
    product_attribute_id: number;
    unity_id: number;
    product_name: string;
    code: string;
    weight: number;
    quantity: number;
    quantity_total: number;
    quantity_request: number;
    quantity_received: number;
    stock: number;
    cost_wt: number;
    total_wt: number;

    unity_sym: string;
}


