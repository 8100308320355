import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import CurrentAccount from '../../entities/cashiers/currentaccount'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface CurrentAccountState extends ListState<CurrentAccount> {
    editModel: CurrentAccount;
    notes: Array<any>;
    payments: Array<any>;
    accounts: Array<any>;
    sales: Array<any>;
    purchases: Array<any>;
    reportList: Array<any>;
    filename: string;
}
class CurrentAccountModule extends ListModule<CurrentAccountState, any, CurrentAccount>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<CurrentAccount>(),
        loading: false,
        editModel: new CurrentAccount(),
        reportList: new Array<any>(),
        filename: '',
        notes: new Array<any>(),
        payments: new Array<any>(),
        purchases: new Array<any>(),
        accounts: new Array<any>(),
        sales: new Array<any>()
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getCustomer(context: ActionContext<CurrentAccountState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/customers/current-account', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = reponse.data.result;
            context.state.sales = reponse.data.result.sales;
            context.state.payments = reponse.data.result.payments;
            context.state.accounts = reponse.data.result.accounts;
            context.state.notes = reponse.data.result.notes;
        },
        async getSupplier(context: ActionContext<CurrentAccountState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/suppliers/current-account', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = reponse.data.result;
            context.state.purchases = reponse.data.result.purchases;
            context.state.payments = reponse.data.result.payments;
            context.state.accounts = reponse.data.result.accounts;

            context.state.purchases.forEach(item => {
                let state = Util.abp.list.getItem(item.current_state_id);
                item['state_name'] = state.name;
                item['state_color'] = state.extra;
                if (item.received) {
                    item['state_name'] += ' / Ingresado';
                } else if (item.partial_received) {
                    item['state_name'] += ' / Ingreso Parcial';
                    item['state_color'] = 'green';
                }
            });
        },
        async reportAccountCustomer(context: ActionContext<CurrentAccountState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/account-customer', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList = reponse.data.result;
        },
        async reportAccountSupplier(context: ActionContext<CurrentAccountState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/account-supplier', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList = reponse.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        init(state: CurrentAccountState) {
            state.editModel = Object.assign({});
            state.notes = [];
            state.payments = [];
            state.accounts = [];
            state.sales = [];
            state.purchases = [];
        },
    });
}
const currentaccountModule = new CurrentAccountModule();
export default currentaccountModule;