import ListModule from '../list-module'
import ListState from '../list-state'
import CostCenter from '../../entities/admin/costcenter'
import Util from '../../../lib/util'

interface CostCenterState extends ListState<CostCenter>{
    editModel: CostCenter;
    
}
class CostCenterModule extends ListModule<CostCenterState, any, CostCenter>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<CostCenter>(),
        loading:false,
        editModel: new CostCenter(),
        activeList: new Array<CostCenter>(),
        path: 'cost-centers'          
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const costcenterModule = new CostCenterModule();
export default costcenterModule;