import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Purchase from '../../entities/purchases/purchase'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';
import PurchaseDetail from '@/store/entities/purchases/purchasedetail';
import PurchaseHistory from '@/store/entities/purchases/history';
import PurchaseOrder from '@/store/entities/purchases/purchaseorder';
import EntryOrder from '@/store/entities/stocks/entryorder';

interface PurchaseState extends ListState<Purchase> {
    editModel: Purchase;
    supplier: number;
    hisLoading: boolean;
    history: Array<PurchaseHistory>;
    reportList: Array<any>;
    filename: '';
}
class PurchaseModule extends ListModule<PurchaseState, any, Purchase>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Purchase>(),
        loading: false,
        hisLoading: false,
        editModel: new Purchase(),
        supplier: null,
        activeList: new Array<Purchase>(),
        history: new Array<PurchaseHistory>(),
        reportList: new Array<any>(),
        filename: '',
        path: 'purchases'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<PurchaseState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Purchase>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }

            context.state.list.forEach(item => {
                let state = Util.abp.list.getItem(item.current_state_id);
                item['state_name'] = state.name;
                item['state_color'] = state.extra;
                if (item.received) {
                    item['state_name'] += ' / Ingresado';
                } else if (item.partial_received) {
                    item['state_name'] += ' / Ingreso Parcial';
                    item['state_color'] = 'green';
                }
            });

            return context.state.list;
        },
        async get(context: ActionContext<PurchaseState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id);
            context.state.editModel = reponse.data.result as Purchase;
            context.state.loading = false;
            return context.state.editModel;
        },
        async getLastNumber(context: ActionContext<PurchaseState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/purchases/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async update(context: ActionContext<PurchaseState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async allowEdit(context: ActionContext<PurchaseState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/purchases/allow-edit', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },        
        async getHistory(context: ActionContext<PurchaseState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/purchases/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async getPurchasesForCreditNotes(context: ActionContext<PurchaseState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/purchases/for-credit-notes', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<Purchase>;
            context.state.totalCount = page.total;
            context.state.list = page.data;
        },
        async reportSunatFormat8(context: ActionContext<PurchaseState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/sunat-format8', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportPurchasesQuery(context: ActionContext<PurchaseState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/purchases-query', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
        async reportOutputPayments(context: ActionContext<PurchaseState, any>, payload: any) {
            context.state.loading = true;
            context.state.reportList = [];
            let reponse = await Ajax.get('/api/reports/purchase-output-payments', { params: payload.data }).catch(e => {
                context.state.loading = false;
                context.state.filename = '';
                return Promise.reject(e);
            });
            context.state.reportList = reponse.data.result.list;
            context.state.filename = reponse.data.result.url;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: PurchaseState, model: Purchase) {
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);
            state.supplier = null;

            state.editModel = Object.assign({});
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.due_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_PURCHASE_DOCUMENT');
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PURCHASE_COSTCENTER');            
            state.editModel.invoice_type_name = 'Tipo de Comprobante';
            state.editModel.including_taxes = true;
            state.editModel.exchange_rate = 1;
            state.editModel.subtotal = 0.00;
            state.editModel.subtotal_wt = 0.00;
            state.editModel.taxes = 0.00;
            state.editModel.total = 0.00;
            state.editModel.total_wt = 0.00;
            state.editModel.total_paid = 0.00;
            state.editModel.balance = 0.00;
            state.editModel.invoice_number = '';
            state.editModel.supplier_id = null;
            state.editModel.supplier_name = '';
            state.editModel.supplier_document = '';
            state.editModel.supplier_address = '';
            state.editModel.credit_days = 0;
            state.editModel.number = 0;
            state.editModel.details = new Array<PurchaseDetail>();
        },
        edit(state: PurchaseState, model: Purchase) {
            state.editModel = model;
            state.editModel.currency_sign = model.currency.sign;
            if (model.invoice_type != undefined)
                state.editModel.invoice_type_name = model.invoice_type.name;

        },
        setCurrency(state: PurchaseState, currency: any) {
            state.editModel.currency = currency;
            state.editModel.currency_id = currency.id;
            state.editModel.currency_sign = currency.sign;
            state.editModel.exchange_rate = currency.conversion_rate;
        },
        setSupplier(state: PurchaseState, data: any) {
            state.editModel['supplier'] = data;
            state.editModel.supplier_id = data.id;
            state.editModel.supplier_document = data.document;
            state.editModel.supplier_name = data.name;
            state.supplier = data.id;
        },
        updateAmount(state: PurchaseState, details: Array<PurchaseDetail>) {
            state.editModel.subtotal = 0;
            state.editModel.subtotal_wt = 0;
            state.editModel.taxes = 0;

            details.forEach((item, index) => {
                state.editModel.subtotal += item.total;
                state.editModel.subtotal_wt += item.total_wt;
            });

            state.editModel.total_wt = state.editModel.subtotal_wt;
            state.editModel.total = state.editModel.subtotal;
            state.editModel.taxes = state.editModel.total_wt - state.editModel.total;
            state.editModel.balance = state.editModel.total_wt - state.editModel.total_paid;
        },
        updatePayments(state: PurchaseState, total_paid: any) {
            state.editModel.total_paid = parseFloat(total_paid);
            state.editModel.balance = parseFloat((state.editModel.total_wt - state.editModel.total_paid).toFixed(2));
        },
        setInvoiceType(state: PurchaseState, invoice_type: any) {
            state.editModel.invoice_type_id = invoice_type.id;
            state.editModel.invoice_type_name = invoice_type.name;
        },
        loadFromOrder(state: PurchaseState, data: PurchaseOrder) {
            if (data == undefined) return;
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);

            state.editModel = Object.assign({});
            state.editModel.reference = data.reference;
            state.editModel['supplier'] = data['supplier'];
            state.editModel.supplier_id = data.supplier_id;
            state.editModel.supplier_document = data.supplier_document;
            state.editModel.supplier_name = data.supplier_name;
            state.editModel.supplier_address = data.supplier_address;         
            state.editModel.branch_id = data.branch_id;
            state.editModel.currency_id = data.currency_id;
            state.editModel.cost_center_id = data.cost_center_id;
            state.editModel.purchase_planning_id = data.purchase_planning_id;
            state.editModel.purchase_order_id = data.id;
            state.editModel.payment_type_id = data.payment_type_id;
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_PURCHASE_DOCUMENT');
            state.editModel.invoice_type_name = 'Tipo de Comprobante';
            state.editModel.tax_id = Util.abp.setting.get('IGV_TAXES');
            state.editModel.including_taxes = true;
            state.editModel.exchange_rate = data.exchange_rate;
            state.editModel.subtotal = data.total;
            state.editModel.subtotal_wt = data.total_wt;
            state.editModel.taxes = data.taxes;
            state.editModel.total = data.total;
            state.editModel.total_wt = data.total_wt;
            state.editModel.total_paid = 0;
            state.editModel.balance = 0;
            state.editModel.received = data.received;
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.due_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.invoice_serie = '';
            state.editModel.invoice_number = '';
            state.editModel.order_number = data.nro;
            state.editModel.details = new Array<PurchaseDetail>();

            data.details.forEach((item, index) => {
                let det = new PurchaseDetail();
                det = Util.extend(true, {}, item);
                det.including_taxes = true;
                det.purchase_order_id = data.id;
                det.purchase_planning_id = data.purchase_planning_id;
                det.order_number = data.nro;

                delete det.id;
                state.editModel.details.push(det);
            });

            state.editModel.details.forEach((item, index) => {
                state.editModel.total += item.total;
                state.editModel.total_wt += item.total_wt;
            });

            state.editModel.taxes = state.editModel.total_wt - state.editModel.total;
        },
        loadFromPurchaseOrders(state: PurchaseState, list: Array<PurchaseOrder>) {
            if (list == undefined) return;
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);
            
            let data = list[0];
            state.editModel = Object.assign({});
            state.editModel['supplier'] = data['supplier'];
            state.editModel.supplier_id = data.supplier_id;
            state.editModel.supplier_document = data.supplier_document;
            state.editModel.supplier_name = data.supplier_name;
            state.editModel.supplier_address = data.supplier_address;            
            state.editModel.branch_id = data.branch_id;
            state.editModel.currency_id = data.currency_id;
            state.editModel.cost_center_id = data.cost_center_id;
            state.editModel.purchase_planning_id = data.purchase_planning_id;
            state.editModel.purchase_order_id = data.id;
            state.editModel.payment_type_id = data.payment_type_id;
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_PURCHASE_DOCUMENT');
            state.editModel.invoice_type_name = 'Tipo de Comprobante';
            state.editModel.tax_id = Util.abp.setting.get('IGV_TAXES');
            state.editModel.including_taxes = true;
            state.editModel.exchange_rate = data.exchange_rate;
            state.editModel.subtotal = 0;
            state.editModel.subtotal_wt = 0;
            state.editModel.taxes = 0;
            state.editModel.total = 0;
            state.editModel.total_wt = 0;
            state.editModel.total_paid = 0;
            state.editModel.balance = 0;
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.due_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.invoice_serie = '';
            state.editModel.invoice_number = '';
            state.editModel.details = new Array<PurchaseDetail>();

            let order_number = [];
            list.forEach(model => {
                state.editModel.subtotal += model.total;
                state.editModel.subtotal_wt += model.total_wt;
                state.editModel.taxes += model.taxes;
                state.editModel.total += model.total;
                state.editModel.total_wt += model.total_wt;
                order_number.push(model.nro);

                model.details.forEach((item, index) => {
                    let det = new PurchaseDetail();
                    det = Util.extend(true, {}, item);
                    det.purchase_order_id = model.id;
                    det.purchase_planning_id = model.purchase_planning_id;
                    det.order_number = model.nro;

                    if (model.purchase_planning_id != null && state.editModel.purchase_planning_id == null)
                        state.editModel.purchase_planning_id = data.purchase_planning_id;

                    delete det.unity;
                    delete det.id;
                    state.editModel.details.push(det);
                });
            });

            state.editModel.order_number = order_number.join('/');
        },
        loadFromPurchaseEntries(state: PurchaseState, list: Array<EntryOrder>) {
            if (list == undefined) return;
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);

            let data = list[0];
            //state.editModel = Object.assign({});
            state.editModel['supplier'] = data['supplier'];
            state.editModel.supplier_id = data.supplier_id;
            state.editModel.supplier_document = data['supplier'].document;
            state.editModel.supplier_name = data['supplier'].name;
            state.editModel.supplier_address = '';
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PURCHASE_COSTCENTER');     
            if (data.cost_center_id != null) state.editModel.cost_center_id = data.cost_center_id;
            state.editModel.purchase_planning_id = data.purchase_planning_id;
            //state.editModel.purchase_order_id = data.id;
            //state.editModel.payment_type_id = data.payment_type_id;
            //state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_PURCHASE_DOCUMENT');
            //state.editModel.invoice_type_name = 'Tipo de Comprobante';
            state.editModel.tax_id = Util.abp.setting.get('IGV_TAXES');
            state.editModel.including_taxes = true;
            state.editModel.exchange_rate = 1;
            state.editModel.subtotal = 0;
            state.editModel.subtotal_wt = 0;
            state.editModel.taxes = 0;
            state.editModel.total = 0;
            state.editModel.total_wt = 0;
            state.editModel.total_paid = 0;
            state.editModel.balance = 0;
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.due_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.invoice_serie = '';
            state.editModel.invoice_number = '';
            state.editModel.origin_document = data.origin_document;
            state.editModel.valid = false;
            state.editModel.details = new Array<PurchaseDetail>();

            if (data.invoice_number != null && data.invoice_number != '') {
                let parts = data.invoice_number.split('-');
                state.editModel.invoice_serie = parts[0];
                state.editModel.invoice_number = parts[1];
                state.editModel.invoice = data.invoice_number;
            }

            state.editModel.entries = [];
            list.forEach(model => {
                state.editModel.entries.push(model.id);

                model.details.forEach((item, index) => {
                    let exist = state.editModel.details.filter((detail: any) => {
                        return detail.product_id === item.product_id && detail.product_attribute_id == item.product_attribute_id;
                    });

                    if (exist.length > 0) {
                        exist[0].quantity += item.quantity;
                        exist[0].total = exist[0].quantity * exist[0].cost;
                        exist[0].total_wt = exist[0].quantity * exist[0].cost_wt
                        exist[0].taxes = parseFloat((exist[0].total_wt - exist[0].total).toFixed(6));
                    } else {
                        let det = new PurchaseDetail();
                        det = Util.extend(true, {}, item);
                        det.purchase_planning_id = model.purchase_planning_id;
                        if (model.entity == 'purchase_order') det.purchase_order_id = model.entity_id;
                        det.tax_id = item.product.tax_id;
                        det.tax_rate = Util.abp.setting.get('TAX_RATE');
                        det.including_taxes = true;
                        det.exchange_rate = 1;
                        det.cost = Util.removeTaxes(item.cost_wt, det.tax_rate, 3);
                        det.cost_wt = item.cost_wt;
                        det.total = det.quantity * det.cost;
                        det.total_wt = det.quantity * det.cost_wt
                        det.taxes = parseFloat((det.total_wt - det.total).toFixed(6));
                        det.product_cost = item.product_cost;
                        det.service = false;
                        det.updated = false;
                        
                        state.editModel.subtotal += det.total;
                        state.editModel.subtotal_wt += det.total_wt;
                        state.editModel.taxes += det.taxes;
                        state.editModel.total += det.total;
                        state.editModel.total_wt += det.total_wt;

                        if (model.purchase_planning_id != null && state.editModel.purchase_planning_id == null)
                            state.editModel.purchase_planning_id = data.purchase_planning_id;

                        delete det.unity;
                        delete det.id;
                        state.editModel.details.push(det);
                    }
                    
                });
            });

            state.editModel.balance = state.editModel.total_wt - state.editModel.total_paid;

        },
        loadFromPlanning(state: PurchaseState, data) {
            if (data.model == undefined) return;
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);

            state.editModel = Object.assign({});
            state.editModel.reference = data.model.reference;
            state.editModel['supplier'] = data.products[0].supplier;
            state.editModel.supplier_id = data.products[0].supplier_id;
            state.editModel.supplier_document = data.products[0].supplier.document;
            state.editModel.supplier_name = data.products[0].supplier_name;
            state.editModel.branch_id = data.model.branch_id;
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_PURCHASE_CURRENCY');
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PURCHASE_COSTCENTER');     
            state.editModel.purchase_planning_id = data.model.id;
            //state.editModel.purchase_order_id = data.id;
            state.editModel.invoice_type_id = Util.abp.setting.get('DEFAULT_PURCHASE_DOCUMENT');
            state.editModel.invoice_type_name = 'Tipo de Comprobante';
            state.editModel.tax_id = Util.abp.setting.get('IGV_TAXES');
            state.editModel.including_taxes = true;
            state.editModel.exchange_rate = (state.editModel.currency_id == 1 ? 1 : Util.abp.setting.get('EXCHANGE_RATE'));
            state.editModel.subtotal = 0;
            state.editModel.subtotal_wt = 0;
            state.editModel.taxes = 0;
            state.editModel.total = 0;
            state.editModel.total_wt = 0;
            state.editModel.total_paid = 0;
            state.editModel.balance = 0;
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.due_date = tomorrow.toISOString().substr(0, 10);
            state.editModel.invoice_serie = '';
            state.editModel.invoice_number = '';
            //state.editModel.order_number = data.nro;
            state.editModel.details = new Array<PurchaseDetail>();

            data.products.forEach((item, index) => {
                let quantityVal = item.quantity_ordered < item.quantity && item.quantity_request < item.quantity;
                if (Util.abp.setting.get('ENABLE_LOAD_PURCHASE_REQUEST') == 1)
                    quantityVal = item.quantity_ordered < item.quantity;

                if (quantityVal) {
                    let det = new PurchaseDetail();
                    det = Util.extend(true, {}, item);
                    if (item.quantity_request > 0)
                        det.quantity = item.quantity - item.quantity_request;
                    else
                        det.quantity = item.quantity - item.quantity_ordered;

                    if (Util.abp.setting.get('ENABLE_LOAD_PURCHASE_REQUEST') == 1)
                        det.quantity = item.quantity - item.quantity_ordered;

                    det.total_wt = det.quantity * det.cost_wt;
                    det.including_taxes = true;
                    det.purchase_planning_id = data.model.id;
                    det.tax_id = Util.abp.setting.get('IGV_TAXES');
                    det.tax_rate = Util.abp.setting.get('TAX_RATE');
                    det.updated = false;

                    delete det.id;
                    state.editModel.details.push(det);
                }
            });

            state.editModel.details.forEach((item, index) => {
                state.editModel.subtotal += item.total;
                state.editModel.subtotal_wt += item.total_wt;
                state.editModel.total += item.total;
                state.editModel.total_wt += item.total_wt;
            });

            state.editModel.taxes = state.editModel.total_wt - state.editModel.total;
            state.editModel.balance = state.editModel.total_wt;
        },
        setReportList(state: PurchaseState, list: Array<any>) {
            state.reportList = list;
            state.totalCount = list.length;
        },
    });
}
const purchaseModule = new PurchaseModule();
export default purchaseModule;