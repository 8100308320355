import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ark from '../../entities/cashiers/ark'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface ArkState extends ListState<Ark>{
    editModel:Ark;
}
class ArkModule extends ListModule<ArkState,any,Ark>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Ark>(),
        loading:false,
        isOpen:false,
        editModel: new Ark(),
        path: 'arks'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async get(context: ActionContext<ArkState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/arks/get/1').catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.editModel = reponse.data.result;
            context.state.loading = false;

            if (context.state.editModel.id == undefined) {
                context.state.editModel.cash = 0;
                context.state.editModel.card = 0;
                context.state.editModel.bank = 0;
                context.state.editModel.total = 0;
            }

            return context.state.editModel;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations  
    });
}
const arkModule=new ArkModule();
export default arkModule;