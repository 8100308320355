import Entity from '../entity'

export default class PackHotel extends Entity<number>{
    product_id: number;
    hotel_id: number;
    price: number;
    price_wt: number;

    hotel: any;
    rooms: Array<any>;
}