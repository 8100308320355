import Entity from '../entity'

export default class WorkOrderPlan extends Entity<number>{
    work_order_id: number;
    work_order_activity_id: number;
    state_id: number;
    employee_id: number;
    process_id: number;
    activity_id: number;
    process_name: string;
    activity_name: string;
    equipment_type_id: number;
    equipment_id: number;
    equipment_name: string;
    duration_type: string;
    time_estimated: number;
    duration_expected: number;
    duration: number;
    hour_rate: number;
    hour_rate_employee: number;
    hour_rate_equipment: number;
    estimated_start_date: string;
    position: number;
    in_process: boolean;
    start_date: string;
    last_start_date: string;
    finish_date: string;
    employees: string;
    equipments: Array<any>;

    employee_name: string;
}


