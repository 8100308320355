import Entity from '../entity'
import Tax from '../preferences/tax';
import MeasureUnit from '../catalogs/measureunit';
import Product from '../catalogs/product';
import PurchasePrice from './purchaseorderprice';

export default class PurchaseDetail extends Entity<number>{
    purchase_id: number;
    purchase_order_id: number;
    purchase_planning_id: number;
    product_id : number;
    product_attribute_id : number;
    unity_id : number;
    tax_id : number;
    tax_rate: number;
    including_taxes: boolean;
    product_name: string;
    version: string;
    code: string;
    upc: string;
    reference: string;
    quantity: number;
    exchange_rate: number;
    discount_type: string;
    cost: number;
    cost_wt: number;
    taxes: number;
    total: number;
    total_wt: number;
    product_cost: number;    
    product_price: number;
    stock: number;
    service: boolean;
    updated: boolean;
    order_number: string;
    quantity_received: number;
    discounts: number;
    
    prices: Array<any>;
    tax: Tax;
    unity: MeasureUnit;
    product: Product;
    unity_sym: string;
}


