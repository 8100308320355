import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ReferralGuide from '../../entities/sales/referralguide'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'
import Customer from '@/store/entities/customers/customer';
import ReferralGuideDetail from '@/store/entities/sales/referralguidedetail';
import ReferralGuideHistory from '@/store/entities/sales/history';
import Sale from '@/store/entities/sales/sale';
import Address from '@/store/entities/customers/address';
import TransferOrder from '@/store/entities/stocks/transferorder';
import axios from 'axios'

interface ReferralGuideState extends ListState<ReferralGuide>{
    editModel:ReferralGuide;   
    hisLoading: boolean;      
    history: Array<ReferralGuideHistory>;      
    addresses: Array<Address>;
    guide_type: string;
}
class ReferralGuideModule extends ListModule<ReferralGuideState,any,ReferralGuide>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ReferralGuide>(),
        loading:false,
        hisLoading:false,
        editModel: new ReferralGuide(),
        activeList: new Array<ReferralGuide>(),
        addresses: new Array<Address>(),
        history: new Array<ReferralGuideHistory>(),
        guide_type: '',
        path: 'referral-guides'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async get(context: ActionContext<ReferralGuideState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/referral-guides/get/' + payload.id);
            context.state.editModel = reponse.data.result as ReferralGuide;
            context.state.loading = false;
            return context.state.editModel;
        },
        async update(context: ActionContext<ReferralGuideState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async invoice(context: ActionContext<ReferralGuideState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/referral-guide-invoice', payload.data).catch(e => {
                context.state.loading = false;
                return false;
            });
            context.state.loading = false;
            if (!response)
                return response;
            else if ((response as any).data != undefined)
                return (response as any).data.result;
            return false;
        },
        async validate(context: ActionContext<ReferralGuideState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/referral-guide/validate', payload.data).catch(e => {
                context.state.loading = false;
                return false;
            });
            context.state.loading = false;
            if (!response)
                return response;
            else if ((response as any).data != undefined)
                return (response as any).data.result;
            return false;
        },
        async getHistory(context: ActionContext<ReferralGuideState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/referral-guides/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async print(context: ActionContext<ReferralGuideState, any>, payload: any) {
            context.state.loading = true;

            let format = Util.abp.setting.get('EGUIDE_PRINT_FORMAT');
            let localUrl = Util.abp.setting.get('LOCAL_PRINTER_URL');
            let mode = Util.abp.setting.get('REMOTE_PRINTER_MODE');
            let method = Util.abp.setting.get('REMOTE_PRINTER_METHOD');
            let useRemote = Util.abp.setting.get('USE_REMOTE_PRINTER');

            let api = (format == 'TK' ? 'referral-guide-invoice-receipt' : 'referral-guide-invoice-document');

            let response = await Ajax.get('/api/' + api, { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            if (mode == 'LOCAL' && useRemote == 1 && format == 'TK') {
                if (response.data.result.localUrl != undefined) localUrl = response.data.result.localUrl;
                if (method == 'POST') {
                    await axios.post(localUrl, response.data.result);
                } else {
                    let href: any = localUrl + "?endpoint=" + (url + 'api/' + api) + "&token=" + window.abp.auth.getToken() + "&id=" + payload.id;
                    const link = document.createElement('a');
                    link.href = href;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    return '';
                    //await axios.get(localUrl, { params: response.data.result });
                }
                return '';
            } else
                return response.data.result;            
        },
        async printSticker(context: ActionContext<ReferralGuideState, any>, payload: any) {
            context.state.loading = true;

            let localUrl = Util.abp.setting.get('LOCAL_STICKER_PRINTER_URL');
            let mode = Util.abp.setting.get('REMOTE_PRINTER_MODE');
            let useRemote = Util.abp.setting.get('USE_REMOTE_PRINTER');
            let method = Util.abp.setting.get('REMOTE_PRINTER_METHOD');

            let response = await Ajax.get('/api/referral-guide-sticker', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            if (mode == 'LOCAL' && useRemote == 1) {
                if (response.data.result.localUrl != undefined) localUrl = response.data.result.localUrl;
                if (method == 'POST') {
                    await axios.post(localUrl, response.data.result);
                } else {
                    let href: any = localUrl + "?endpoint=" + (url + 'api/referral-guide-sticker') + "&token=" + window.abp.auth.getToken() + "&id=" + payload.id;
                    const link = document.createElement('a');
                    link.href = href;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    return '';
                    //await axios.get(localUrl, { params: response.data.result });
                }
                return '';
            } else
                return response.data.result;
        },
        async downloadInvoiceFile(context: ActionContext<ReferralGuideState, any>, payload: any) {
            context.state.loading = true;
            payload.data.sale = payload.data.id;
            let reponse = await Ajax.get('/api/referral-guide-invoice-download', {
                responseType: 'blob',
                params: payload.data
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', payload.data.filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ReferralGuideState, model: ReferralGuide) {
            var today = new Date();
            state.addresses = [];

            state.editModel = Object.assign({});

            state.editModel.customer = Object.assign({});
            state.editModel.customer_id = Util.abp.setting.get('DEFAULT_CUSTOMER');
            state.editModel.customer_document = '';
            state.editModel.customer_name = Util.abp.setting.get('DEFAULT_CUSTOMER_NAME');

            state.editModel.customer_id = Util.abp.setting.get('DEFAULT_CUSTOMER');
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.transfer_date = Util.abp.clock.today();              
            state.editModel.guide_type = {};
            state.editModel['sale'] = null;
            state.editModel.invoice_serie = null;
            state.editModel.invoice_number = '0';
            state.editModel.invoiced = false;
            state.editModel.address_origin = '';
            state.editModel.move_stock = true;
            
            state.editModel.details = new Array<ReferralGuideDetail>();
            state.guide_type = '';
        },
        edit(state: ReferralGuideState, model: ReferralGuide) {
            state.editModel = model;
            state.editModel.customer = model.customer;
            state.editModel.customer_document = model.customer.document_full;
            state.editModel.customer_name = model.customer.fullname;
            state.addresses = [];
            state.addresses.push(...model.customer.addresses);
            if (model.address_destination != null)
                state.editModel.address_destination_text = model.address_destination.address1 + ' ' + model.address_destination.address2;

            if (model.carrier != null && model.carrier.address != null) 
                state.editModel.carrier_address = model.carrier.address.address1 + (model.carrier.address.address2 != null ? ' ' + model.carrier.address.address2 : '');

            state.guide_type = state.editModel.guide_type.code;
        },
        setAddresses(state: ReferralGuideState, addresses: any) {
            state.addresses = addresses;
        },
        setOrigin(state: ReferralGuideState, address: Address) {
            if (address != undefined) {
                state.editModel.address_origin_id = address.id;
                state.editModel.address_origin = address.address1 + (address.address2 != null ? ' ' + address.address2 : '');
            }
        },
        setInvoice(state: ReferralGuideState, data: any) {
            state.editModel.invoice_type_id = data.invoice_type.id;
            if (data.invoice_type.series[0] != undefined)
                state.editModel.invoice_serie = data.invoice_type.series[0].serie;
            state.editModel.invoice_number = data.number;
        },
        setGuideType(state: ReferralGuideState, data: any) {
            state.editModel.guide_type = data;
            state.guide_type = data.code;
        },
        loadFromSale(state: ReferralGuideState, model: Sale) {
            var today = new Date();
            
            state.addresses = [];   
            if (model.delivery_address != null)
                state.addresses.push(model.delivery_address);

            state.editModel = Object.assign({});
            state.editModel.customer = model.customer;
            state.editModel.customer_document = model.customer.document_full;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.id = 0;
            state.editModel.reference = model.reference;
            state.editModel.sale_id = model.id;
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.transfer_date = Util.abp.clock.today();
            state.editModel.customer_id = model.customer_id;
            state.editModel.invoiced = false;
            state.editModel.address_destination_id = model.delivery_address_id;
            state.editModel.invoice_serie = null;
            state.editModel.invoice_number = '0';
            state.editModel['sale'] = model['invoice'];
            state.editModel.sale_nro = model['invoice'];
            state.editModel.guide_type_id = Util.abp.list.get('T_GUIDE', '01').id;
            state.editModel.guide_type = Util.abp.list.get('T_GUIDE','01');
            state.editModel.details = new Array<ReferralGuideDetail>();
            state.guide_type = Util.abp.list.get('T_GUIDE', '01').code;

            if (state.editModel.invoice_number == undefined) state.editModel.invoice_number = '0';

            model.details.forEach((item, index) => {
                let det = new ReferralGuideDetail();
                det = Util.extend(true, {}, item);
                det.quantity = item.quantity - item.quantity_returned;
                det.stock = det.quantity;
                if (det.quantity > 0) {
                    state.editModel.details.push(det);
                }
            });
        },
        loadFromTransfer(state: ReferralGuideState, model: TransferOrder) {
            var today = new Date();

            state.editModel = Object.assign({});
            state.editModel.id = 0;
            state.editModel.reference = model.reference;
            state.editModel.transfer_order_id = model.id;
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.transfer_date = Util.abp.clock.today();
            state.editModel.invoiced = false;
            state.editModel.move_stock = false;
            state.editModel.warehouse_output_id = model.warehouse_out_id;
            state.editModel.warehouse_income_id = model.warehouse_in_id;
            state.editModel.sale_nro = model['nro'];
            state.editModel.guide_type_id = Util.abp.list.get('T_GUIDE', '13').id;
            state.editModel.guide_type = Util.abp.list.get('T_GUIDE', '13');
            state.editModel.details = new Array<ReferralGuideDetail>();
            state.guide_type = Util.abp.list.get('T_GUIDE', '13').code;

            if (state.editModel.invoice_number == undefined) state.editModel.invoice_number = '0';

            model.details.forEach((item, index) => {
                let det = new ReferralGuideDetail();
                det = Util.extend(true, {}, item);
                det.stock = item.quantity;
                state.editModel.details.push(det);
            });
        },
    });
}
const referralguideModule=new ReferralGuideModule();
export default referralguideModule;