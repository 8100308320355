import Entity from '../entity'

export default class PackGroup extends Entity<number>{
    nro: number;
    product_id: number;
    name: string;
    items: Array<any>;
}


