import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import FoodPlanCategory from '@/store/entities/foods/foodplancategory';

interface FoodPlanCategoryState extends ListState<FoodPlanCategory>{
    editModel: FoodPlanCategory;
}
class FoodPlanCategoryModule extends ListModule<FoodPlanCategoryState, any, FoodPlanCategory>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<FoodPlanCategory>(),
        loading: false,
        editModel:new FoodPlanCategory(),
        activeList: new Array<FoodPlanCategory>(),
        path: 'food-plan-categories'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: FoodPlanCategoryState, model: FoodPlanCategory) {
            state.editModel = Object.assign({});
            state.editModel.line_id = Util.abp.setting.get('DEFAULT_LINE');
            state.editModel.category_id = Util.abp.setting.get('DEFAULT_CATEGORY');
        },
    });
}
const foodplancategoryModule=new FoodPlanCategoryModule();
export default foodplancategoryModule;