import Entity from '../entity'

export default class PackPlan extends Entity<number>{
    product_id: number;
    process_id: number;
    activity_id: number;
    employee_id: number;
    equipment_type_id: number;
    equipment_id: number;
    process_name: string;
    activity_name: string;
    equipment_name: string;
    duration_type: string;
    time_estimated: number;
    hour_rate: number;
    hour_rate_employee: number;
    hour_rate_equipment: number;
    total_wt: number;
}


