import Entity from '../entity'
import SaleDetail from './saledetail';
import SaleItem from './saleitem';
import Customer from '../customers/customer';
import Currency from '../preferences/currency';
import Address from '../customers/address';
import Transaction from '../cashiers/transaction';
import SaleState from './salestate';
import SaleCharge from './salecharge';
import SaleDiscount from './salediscount';

export default class Sale extends Entity<number>{
    reference: string;
    customer_id: number;
    branch_id: number;
    delivery_address_id: number;
    currency_id: number;
    currency_sign: string;
    seller_id: number;
    invoice_type_id: number;
    payment_type_id: number;
    current_state_id: number;
    sale_quotation_id: number;
    food_order_id: number;
    sale_order_id: number;
    order_id: number;
    delivery_order_id: number;
    service_order_id: number;
    work_order_id: number;
    repair_order_id: number;
    booking_id: number;
    tax_id: number;
    tax_rate: number;
    credit_days: number;
    exchange_rate: number;
    including_taxes: boolean;
    detraction: boolean;
    retention: boolean;
    perception: boolean;
    export: boolean;
    discount_type: string;
    discount_mode: string;
    subtotal: number;
    subtotal_wt: number;
    tax_igv: number;
    tax_isc: number;
    tax_others: number;
    taxes: number;
    discounts: number;
    total: number;
    total_wt: number;
    total_paid: number;
    total_return: number;
    balance: number;
    sale_date: string;
    expiration_date: string;
    invoice_serie: string;
    invoice_number: string;
    invoice_date: string;
    notes: string;
    valid: boolean;
    invoiced: boolean;
    delivered: boolean;
    returned: boolean;
    creditnote: number;
    debitnote: number;
    referral_guide: string;
    plate: string;
    quotation_nro: string;
    order_nro: string;
    service_nro: string;
    work_nro: string;
    ticket_nro: string;
    booking_nro: string;
    cancel_reason: string;
    dispatch_type_id: number;
    dispatch_date: string;
    details: Array<SaleDetail>;
    items: Array<SaleItem>;
    payments: Array<Transaction>;
    charges: Array<SaleCharge>;
    sale_discounts: Array<SaleDiscount>;

    invoice_type_name: string;
    invoice_type: any;
    customer: Customer;
    currency: Currency;
    delivery_address: Address;
    address_full: string;
    customer_code: string;
    customer_document: string;
    customer_name: string;
    customer_email: string;
    group_name: string;
    credit_line: number;
    current_state: SaleState;
    charge_detraction_id: number;
    charge_retention_id: number;
    charge_perception_id: number;
    
}
