import Entity from '../entity'
import Attribute from './attribute';

export default class AttributeGroup extends Entity<number>{
    group_type: string;
    name: string;
    is_color_group: boolean;
    position: number;
    attributes: Array<Attribute>;
}


