import Entity from '../entity'
import QuotationDetail from './quotationdetail';
import Customer from '../customers/customer';
import Currency from '../preferences/currency';

export default class Quotation extends Entity<number>{
    reference: string;
    serie: string;
    number: string;
    customer_id: number;
    branch_id: number;
    currency_id: number;
    currency_sign: string;
    seller_id: number;
    payment_type_id: number;
    current_state_id: number;
    credit_days: number;
    including_taxes: boolean;
    discount_type: string;
    exchange_rate: number;
    subtotal: number;
    subtotal_wt: number;
    taxes: number;
    discounts: number;
    total: number;
    total_wt: number;
    issue_date: string;
    due_date: string;
    nro_sends: number;
    emailed: boolean;
    email_date: string;
    notes: string;
    title: string;
    observations: string;
    delivery_place: string;
    delivery_time: string;
    customer_quote_nro: string;
    valid: boolean;

    customer: Customer;
    currency: Currency;
    details: Array<QuotationDetail>;

    nro: string;
    customer_code: string;
    customer_document: string;
    customer_name: string;
    group_name: string;
    credit_line: number;
}


