import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ProductLocation from '../../entities/catalogs/productlocation'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface ProductLocationState extends ListState<ProductLocation>{
    editModel:ProductLocation;
    product: number;
    product_attribute: number;
    activeList: Array<ProductLocation>;
    batches: Array<any>;
}
class ProductLocationModule extends ListModule<ProductLocationState,any,ProductLocation>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ProductLocation>(),
        loading:false,
        editModel:new ProductLocation(),
        activeList: new Array<ProductLocation>(),
        batches: new Array<any>(),
        product: 0,
        product_attribute: 0,        
        path: 'products-locations',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<ProductLocationState, any>, payload: any) {
            if (context.state.loading) return; 
            context.state.loading = true;
            let reponse = await Ajax.get('/api/products-locations', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<ProductLocation>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }
        },
        async getAllActive(context: ActionContext<ProductLocationState, any>, payload: any) {
            if (context.state.loading) return; 

            payload.data.perPage = -1;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.activeList = reponse.data.result;
            context.state.loading = false;
            return context.state.activeList;
        },
        async create(context: ActionContext<ProductLocationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list.push(response.data.result);
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations    
        new(state: ProductLocationState, model: ProductLocation) {
            state.editModel = Object.assign({});
            state.editModel.warehouse_id = Util.abp.session.warehouseId;
            state.editModel.location_id = null;
            state.editModel.shelf_id = null;
            state.editModel.position = '';
            if (model != undefined && model.product != undefined) {
                state.editModel.product = model.product;
                state.editModel.product_id = model.product.id;
            }
        },
        editLocations(state: ProductLocationState, list: Array<any>) {
            state.batches = list;
        },
        setList(state: ProductLocationState, list: Array<ProductLocation>) {

            if (list != null) {
                state.list = list;
                state.list.forEach((item, index) => {
                    if (item.warehouse_id == Util.abp.session.warehouseId)
                        state.list[index].warehouse = Util.abp.setting.get('DEFAULT_WAREHOUSE_NAME');

                    //state.list[index].stock = 0;
                });

                state.totalCount = list.length;
            }
        },
        setActiveList(state: ProductLocationState, list: Array<ProductLocation>) {
            if (list != null) {
                state.activeList = list;
            }
        },
        setProduct(state: ProductLocationState, product: number) {
            state.product = product;
        },
        setProductAttribute(state: ProductLocationState, attribute: number) {
            state.product_attribute = attribute;
        },
    });
}
const productlocationModule=new ProductLocationModule();
export default productlocationModule;