import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PurchaseDebitNote from '../../entities/purchases/purchasedebitnote'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PurchaseDebitNoteDetail from '@/store/entities/purchases/purchasedebitnotedetail';
import PurchaseDebitNoteHistory from '@/store/entities/purchases/history';
import Purchase from '@/store/entities/purchases/purchase';

interface PurchaseDebitNoteState extends ListState<PurchaseDebitNote>{
    editModel:PurchaseDebitNote; 
    hisLoading: boolean;      
    history: Array<PurchaseDebitNoteHistory>;      
}
class PurchaseDebitNoteModule extends ListModule<PurchaseDebitNoteState,any,PurchaseDebitNote>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PurchaseDebitNote>(),
        loading:false,
        hisLoading:false,
        editModel: new PurchaseDebitNote(),
        activeList: new Array<PurchaseDebitNote>(),
        history: new Array<PurchaseDebitNoteHistory>(),
        path: 'purchase-debit-notes'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getHistory(context: ActionContext<PurchaseDebitNoteState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/purchase-debit-notes/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: PurchaseDebitNoteState, model: PurchaseDebitNote) {
            state.editModel = Object.assign({});
            state.editModel.process_date = Util.abp.clock.today();
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.currency_sign = 'S/';
            state.editModel.exchange_rate = 1;
            state.editModel.invoice_type_name = 'Nota de Debito';
            state.editModel.subtotal = 0.00;
            state.editModel.subtotal_wt = 0.00;
            state.editModel.taxes = 0.00;
            state.editModel.aditional = 0.00;         
            state.editModel.total = 0.00;
            state.editModel.total_wt = 0.00;
            state.editModel.total_paid = 0.00;
            state.editModel.balance = 0.00;
            state.editModel.invoice_serie = null;
            state.editModel.invoice_number = null;
            state.editModel.details = new Array<PurchaseDebitNoteDetail>();
        },
        edit(state: PurchaseDebitNoteState, model: PurchaseDebitNote) {
            state.editModel = model;
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.invoice_type_name = 'Nota de Debito';
            state.editModel.purchase_invoice_type = model.purchase_invoice_type;

            state.editModel.supplier_document = model.supplier.document;
            state.editModel.supplier_name = model.supplier.name;
        },
        updateAmount(state: PurchaseDebitNoteState, details: Array<PurchaseDebitNoteDetail>) {
            state.editModel.subtotal = 0;
            state.editModel.subtotal_wt = 0;
            state.editModel.taxes = 0;

            details.forEach((item, index) => {
                state.editModel.subtotal += item.total;
                state.editModel.subtotal_wt += item.total_wt;
            });

            state.editModel.total = state.editModel.subtotal;
            state.editModel.total_wt = state.editModel.subtotal_wt;
            state.editModel.taxes = state.editModel.total_wt - state.editModel.total;
            state.editModel.balance = state.editModel.total_wt + state.editModel.total_paid;
        },
        setTotals(state: PurchaseDebitNoteState, data: any) {
            state.editModel.subtotal = data.subtotal;
            state.editModel.subtotal_wt = data.subtotal_wt;
            state.editModel.taxes = data.taxes;
            state.editModel.total = data.total;
            state.editModel.total_wt = data.total_wt;
            state.editModel.balance = data.balance;
            state.editModel.total_paid = data.total_paid;
        },
        loadFromPurchase(state: PurchaseDebitNoteState, model: Purchase) {
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);

            state.editModel = Object.assign({});
            state.editModel.supplier_document = model.supplier_document;
            state.editModel.supplier_name = model.supplier_name;

            state.editModel.reference = model.reference;
            state.editModel.purchase_id = model.id;
            state.editModel.process_date = Util.abp.clock.today();
            state.editModel.supplier_id = model.supplier_id;
            state.editModel.currency = model.currency;
            state.editModel.currency_id = model.currency_id;
            state.editModel.invoice_type_name = 'Nota de Debito'; 
            state.editModel.purchase_invoice_type = model.invoice_type_id;
            state.editModel.purchase_invoice = (model as any).invoice;
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.exchange_rate = model.exchange_rate;
            state.editModel.subtotal = model.subtotal;
            state.editModel.subtotal_wt = model.subtotal_wt;
            state.editModel.taxes = model.taxes;
            state.editModel.aditional = 0.00;        
            state.editModel.total = model.total;
            state.editModel.total_wt = model.total_wt;
            state.editModel.balance = model.total_wt;
            state.editModel.invoice_serie = null;
            state.editModel.invoice_number = null;
            state.editModel.details = new Array<PurchaseDebitNoteDetail>();

            model.details.forEach((item, index) => {
                let det = new PurchaseDebitNoteDetail();
                det.purchase_debit_note_id = state.editModel.id;
                det.id = item.id;
                det.product_id = item.product_id;
                det.product_attribute_id = item.product_attribute_id;
                det.warehouse_id = Util.abp.session.warehouseId;
                det.unity_id = item.unity_id;
                det.code = item.code;
                det.product_name = item.product_name;
                det.quantity = item.quantity;
                det.cost = item.cost;
                det.cost_wt = item.cost_wt;
                det.total = item.total;
                det.total_wt = item.total_wt;
                det.taxes = item.taxes;
                det.stock = item.quantity;
                state.editModel.details.push(det);
            });
        },
    });
}
const purchasedebitnoteModule=new PurchaseDebitNoteModule();
export default purchasedebitnoteModule;