import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PurchaseQuotation from '@/store/entities/purchases/purchasequotation';
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Supplier from '@/store/entities/purchases/supplier';
import PurchaseQuotationDetail from '@/store/entities/purchases/purchasequotationdetail';
import PurchaseQuotationHistory from '@/store/entities/purchases/history';
import PurchaseQuotationSupplier from '@/store/entities/purchases/purchasequotationsupplier';
import PurchaseRequest from '@/store/entities/purchases/purchaserequest';
import PurchaseRequestDetail from '@/store/entities/purchases/purchaserequestdetail';

interface PurchaseQuotationState extends ListState<PurchaseQuotation>{
    editModel: PurchaseQuotation;    
    editSupplier: PurchaseQuotationSupplier;
    supplier: number;   
    hisLoading: boolean;
    hasChanges: boolean; 
    history: Array<PurchaseQuotationHistory>;
    requestList: Array<PurchaseRequestDetail>;
}
class PurchaseQuotationModule extends ListModule<PurchaseQuotationState, any,PurchaseQuotation>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PurchaseQuotation>(),
        loading:false,
        hisLoading: false,
        hasChanges: false,
        editModel: new PurchaseQuotation(),
        editSupplier: new PurchaseQuotationSupplier(),
        supplier: null,
        activeList: new Array<PurchaseQuotation>(),
        history: new Array<PurchaseQuotationHistory>(),
        requestList: new Array<PurchaseRequestDetail>(),
        path: 'purchase-quotations'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<PurchaseQuotationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async update(context: ActionContext<PurchaseQuotationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async getLastNumber(context: ActionContext<PurchaseQuotationState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/purchase-quotations/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<PurchaseQuotationState, any>, payload: any) {
            context.state.hisLoading = true; 
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/purchase-quotations/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async createSupplier(context: ActionContext<PurchaseQuotationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path + '/supplier', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editSupplier = Util.extend(true, {}, response.data.result);
            context.state.editModel.suppliers.push(response.data.result);
        },
        async send(context: ActionContext<PurchaseQuotationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/purchase-quotations/send', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async confirm(context: ActionContext<PurchaseQuotationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/purchase-quotations/confirm', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.hasChanges = false;
            return response.data.result;
        },
        async download(context: ActionContext<PurchaseQuotationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/purchase-quotations/download', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async quotationImport(context: ActionContext<PurchaseQuotationState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/purchase-quotation-import',
                payload.data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
                .catch(e => {
                    context.state.loading = false;
                    return Promise.reject(e);
                });
            context.state.loading = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: PurchaseQuotationState, model: PurchaseQuotation) {
            var today = Util.abp.clock.today();
            let serie = Util.abp.setting.get('P_QUOTATION_SERIE');
            state.editSupplier = Object.assign({});
            state.supplier = null;

            state.editModel = Object.assign({});
            state.editModel.issue_date = today; 
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_PURCHASE_CURRENCY');
            state.editModel.currency_sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            state.editModel.currency = null;
            state.editModel.serie = (serie == 'YYYY' ? today.substr(0, 4) : serie);
            state.editModel.number = '0000';
            state.editModel.suppliers = new Array<PurchaseQuotationSupplier>();
        },
        edit(state: PurchaseQuotationState, model: PurchaseQuotation) {
            state.editModel = model;
            state.editModel.currency_sign = model.currency.sign;
            state.editSupplier = Object.assign({});
            if (model.suppliers.length > 0)
                state.editSupplier = model.suppliers[0];
            state.hasChanges = false;
        },
        setSupplier(state: PurchaseQuotationState, supplier: PurchaseQuotationSupplier) {
            state.editSupplier = supplier;
            state.supplier = supplier.supplier_id;
        },
        updateSupplierNote(state: PurchaseQuotationState, supplier: PurchaseQuotationSupplier) {
            state.editSupplier.notes = supplier.notes;
            state.editModel.suppliers.forEach(item => {
                if (supplier.supplier_id == item.supplier_id)
                    item.notes = supplier.notes;
            });            
        },
        setCurrency(state: PurchaseQuotationState, currency: any) {
            state.editModel.currency = currency;
            state.editModel.currency_id = currency.id;
            state.editModel.currency_sign = currency.sign;
            state.editModel.exchange_rate = currency.conversion_rate;
            state.hasChanges = true;
        },
        setRequestList(state: PurchaseQuotationState, list: any) {
            state.requestList = list;
        },
        updateAmount(state: PurchaseQuotationState, details: Array<PurchaseQuotationDetail>) {
            if (state.editSupplier.id > 0) {
                state.editSupplier.subtotal = 0;
                state.editSupplier.subtotal_wt = 0;
                state.editSupplier.taxes = 0;

                details.forEach((item, index) => {
                    state.editSupplier.subtotal += item.total;
                    state.editSupplier.subtotal_wt += item.total_wt;
                });

                state.editSupplier.total = state.editSupplier.subtotal - state.editSupplier.discounts;
                state.editSupplier.total_wt = state.editSupplier.subtotal_wt - state.editSupplier.discounts;
                state.editSupplier.taxes = parseFloat((state.editSupplier.total_wt - state.editSupplier.total).toFixed(6));
                state.editSupplier.details = details;

                state.editModel.suppliers.forEach((det, index) => {
                    if (det.id == state.editSupplier.id) {
                        state.editModel.suppliers[index].discounts = state.editSupplier.discounts;
                        state.editModel.suppliers[index].subtotal = state.editSupplier.subtotal;
                        state.editModel.suppliers[index].subtotal_wt = state.editSupplier.subtotal_wt;
                        state.editModel.suppliers[index].total = state.editSupplier.total;
                        state.editModel.suppliers[index].total_wt = state.editSupplier.total_wt;
                        state.editModel.suppliers[index].taxes = state.editSupplier.taxes;
                    }
                });
            }
            state.hasChanges = true;
        },
        loadFromRequest(state: PurchaseQuotationState, model: PurchaseRequest) {
            if (model == undefined) return;

            state.editModel.reference = model.reference;
            state.editModel.purchase_planning_id = model.purchase_planning_id;
            state.editModel.purchase_request_id = model.id;
            state.editModel.request_number = model.nro;
            state.editModel.currency_id = model.currency_id;
            state.editModel.currency_sign = model.currency_sign;
            state.editModel.currency = model.currency;
        },
        setChanged(state: PurchaseQuotationState, value: boolean) {
            state.hasChanges = value;
        },
    });
}
const orderModule = new PurchaseQuotationModule();
export default orderModule;