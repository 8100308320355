import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import InvoiceCharge from '../../entities/sales/invoicecharge'
import Util from '../../../lib/util'

interface InvoiceChargeState extends ListState<InvoiceCharge>{
    editModel:InvoiceCharge;    
}
class InvoiceChargeModule extends ListModule<InvoiceChargeState,any,InvoiceCharge>{
    state={ 
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<InvoiceCharge>(),
        loading:false,
        editModel: new InvoiceCharge(),
        activeList: new Array<InvoiceCharge>(),
        path: 'invoice-charges'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const invoicechargeModule=new InvoiceChargeModule();
export default invoicechargeModule;