import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ServiceOrderDetail from '../../entities/production/serviceorderdetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import ServiceOrder from '@/store/entities/production/serviceorder';

interface ServiceOrderDetailState extends ListState<ServiceOrderDetail>{
    editModel:ServiceOrderDetail;    
    order:number;    
}
class ServiceOrderDetailModule extends ListModule<ServiceOrderDetailState,any,ServiceOrderDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ServiceOrderDetail>(),
        loading:false,
        editModel: new ServiceOrderDetail(),
        activeList: new Array<ServiceOrderDetail>(),
        order: 0,
        path: 'service-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<ServiceOrderDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: ServiceOrderDetailState, model: ServiceOrderDetail) {
            state.editModel = model;
        },
        init(state: ServiceOrderDetailState, model: ServiceOrder) {
            state.editModel = Object.assign({});
            state.order = 0;
            state.list = [];
            if (model != undefined) {
                state.order = model.id;
                state.list = model.details;
            }
        },
        add(state: ServiceOrderDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id;
            });

            if (exist.length > 0) {
                exist[0].quantity += product.quantity;
            } else {
                let detail = new ServiceOrderDetail();
                detail.service_order_id = state.editModel.id;
                detail.service_id = product.id;
                detail.tax_id = product.tax_id;
                detail.tax_rate = product.tax_rate;
                detail.code = product.code;
                detail.service_name = product.name;
                detail.quantity = product.quantity;
                detail.cost = Util.removeTaxes(product.price_wt, detail.tax_rate);
                detail.cost_wt = product.price_wt;
                detail.total = parseFloat((detail.quantity * detail.cost).toFixed(6));
                detail.total_wt = parseFloat((detail.quantity * detail.cost_wt).toFixed(6));
                detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(6));
                detail.product_cost = product.product_cost;
                detail.quantity = product.quantity;
                
                state.list.push(detail);
            }
        },
        update(state: ServiceOrderDetailState, detail: ServiceOrderDetail) {
            if (detail.tax != null) {
                detail.tax_id = detail.tax.id;
                detail.tax_rate = detail.tax.rate;
            }
            detail.quantity = parseInt(String(detail.quantity));
            detail.cost = Util.removeTaxes(detail.cost_wt, detail.tax_rate);
            detail.total = detail.quantity * detail.cost;
            detail.total_wt = (detail.quantity * detail.cost_wt);

            detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(6));
            detail.total_wt = parseFloat(detail.total_wt.toFixed(6));
        },
        updateList(state: ServiceOrderDetailState, data: any) {
            state.list[data.index].tax_id = data.detail.tax_id;
            state.list[data.index].tax_rate = data.detail.tax_rate;
            state.list[data.index].quantity = data.detail.quantity;
            state.list[data.index].cost = data.detail.cost;
            state.list[data.index].cost_wt = data.detail.cost_wt;
            state.list[data.index].taxes = data.detail.taxes;
            state.list[data.index].total = data.detail.total;
            state.list[data.index].total_wt = data.detail.total_wt;
        },
        delete(state: ServiceOrderDetailState, index: number) {
            state.list.splice(index, 1);
        },
    });
}
const serviceorderdetailModule=new ServiceOrderDetailModule();
export default serviceorderdetailModule;