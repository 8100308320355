declare global {
    interface RouterMeta {
        title: string;
    }
    interface Router { 
        path: string;
        name: string;
        icon?: string;
        permission?: string;
        meta?: RouterMeta;
        component: any; 
        children?: Array<Router>;
    } 
    interface System {
        import(request: string): Promise<any>
    }
    var System: System
}
import login from '../views/login.vue'
import home from '../views/home/home.vue'
import main from '../views/main.vue'
import Ajax from '../lib/ajax';

export const locking = {
    path: '/locking',
    name: 'locking',
    component: () => import('../components/lockscreen/components/locking-page.vue')
};
export const apps = {
    path: '/apps',
    name: 'apps',
    component: () => import('../components/apps/components/apps-page.vue')
};
export const loginRouter: Router = {
    path: '/',
    name: 'login',
    meta: {
        title: 'LogIn'
    },
    component: () => import('../views/login.vue')
};
export const otherRouters: Router = {
    path: '/main',
    name: 'main',
    permission: '',
    meta: { title: '' },
    component: main,
    children: [
        { path: 'home', meta: { title: 'Home' }, name: 'home', component: () => import('../views/home/home.vue') },
        { path: '/cashiers/arca', meta: { title: 'Arca' }, name: 'ark', component: () => import('../views/cashiers/ark/ark-view.vue') },
        { path: '/cashiers/view', meta: { title: 'Caja' }, name: 'cashierdefault', component: () => import('../views/cashiers/cashier/cashier-view.vue') },
        { path: '/cashiers/view/:id', meta: { title: 'Caja' }, name: 'cashierview', component: () => import('../views/cashiers/cashier/cashier-view.vue') },
        { path: '/pedido/:id', meta: { title: 'Punto de Venta POS' }, name: 'orderview', component: () => import('../views/sales/pos/order/edit.vue') },
        { path: '/stocks/stock-mvt-reason', meta: { title: 'Tipos de Movimiento de Stocks' }, name: 'stockmvtreason', component: () => import('../views/stocks/stockmvtreason/stockmvtreason.vue') },
        { path: '/admin/users/profile', meta: { title: 'Perfil de usuario' }, name: 'profile', component: () => import("../views/admin/user/profile.vue") },
        { path: '/cashiers/letras', meta: { title: 'Letras Combinadas' }, name: 'accountcombined', component: () => import("../views/cashiers/accountcombined/accountcombined.vue") },        
        { path: '/quicknote/calendario', meta: { title: 'Notas' }, name: 'quicknote', component: () => import("../views/preferences/quicknote/calendar.vue") },
        { path: '/sticker', meta: { title: 'Etiquetas' }, name: 'sticker', component: () => import("../views/preferences/sticker/sticker.vue") },
        { path: '/reports', meta: { title: 'Reportes' }, name: 'reports', component: () => import("../views/reports/reports.vue") },
        { path: '/reports/sales-sellers', meta: { title: 'Ventas por Vendedor' }, name: 'rsalessellers', component: () => import("../views/reports/sales/sales-sellers.vue") },
        { path: '/reports/sales-query', meta: { title: 'Consulta de Ventas' }, name: 'rsalesquery', component: () => import("../views/reports/sales/sales-query.vue") },
        { path: '/reports/sales-products', meta: { title: 'Ventas por Producto' }, name: 'rsalesproducts', component: () => import("../views/reports/sales/sales-products.vue") },
        { path: '/reports/sales-discounts', meta: { title: 'Ventas por Promocion' }, name: 'rsalesdiscounts', component: () => import("../views/reports/sales/sales-discounts.vue") },
        { path: '/reports/sales-customers', meta: { title: 'Ventas por Cliente' }, name: 'rsalescustomers', component: () => import("../views/reports/sales/sales-customers.vue") },
        { path: '/reports/sales-canceled', meta: { title: 'Ventas Anuladas' }, name: 'rsalescanceled', component: () => import("../views/reports/sales/sales-canceled.vue") },
        { path: '/reports/credit-notes', meta: { title: 'Notas de Credito' }, name: 'rcreditnotes', component: () => import("../views/reports/sales/credit-notes.vue") },
        { path: '/reports/delivery-notes', meta: { title: 'Notas de Salida' }, name: 'rdeliverynotes', component: () => import("../views/reports/sales/delivery-notes.vue") },
        { path: '/reports/modalidad-pagos', meta: { title: 'Ingresos Modalidad de Pago' }, name: 'rentrancepayments', component: () => import("../views/reports/sales/entrance-payments.vue") },
        { path: '/reports/productos-vendidos', meta: { title: 'Productos vendidos' }, name: 'rsalesproductsbase', component: () => import("../views/reports/sales/sales-products-base.vue") },
        { path: '/reports/top-productos', meta: { title: 'Top Productos' }, name: 'rtopproducts', component: () => import("../views/reports/sales/top-products.vue") },

        { path: '/reports/sunat-format12', meta: { title: 'Registro de Inventario Permanente' }, name: 'rsunatformat12', component: () => import("../views/reports/sunat/format12.vue") },
        { path: '/reports/sunat-format13', meta: { title: 'Inventario Valorizado' }, name: 'rsunatformat13', component: () => import("../views/reports/sunat/format13.vue") },
        { path: '/reports/sunat-format14', meta: { title: 'Registro de Ventas e Ingresos' }, name: 'rsunatformat14', component: () => import("../views/reports/sunat/format14.vue") },
        { path: '/reports/sunat-format8', meta: { title: 'Registro de Compras' }, name: 'rsunatformat8', component: () => import("../views/reports/sunat/format8.vue") },
	
	    { path: '/reports/pagos-movimientos', meta: { title: 'Pagos y Movimiento de Efectivo' }, name: 'rtransactionmoves', component: () => import("../views/reports/cashiers/transaction-moves.vue") },
        { path: '/reports/flujo-caja', meta: { title: 'Flujo de Caja' }, name: 'rcashierhistory', component: () => import("../views/reports/cashiers/cashier-history.vue") },
        { path: '/reports/ingresos-egresos', meta: { title: 'Reporte Ingresos y Egresos' }, name: 'rinout', component: () => import("../views/reports/cashiers/inout-moves.vue") },
        { path: '/reports/vouchers', meta: { title: 'Reporte Vouchers' }, name: 'rvouchers', component: () => import("../views/reports/cashiers/vouchers.vue") },
        { path: '/reports/cuentas-por-cobrar', meta: { title: 'Reporte Cuenta por Cobrar' }, name: 'rreceivable', component: () => import("../views/reports/cashiers/report-receivable.vue") },
        { path: '/reports/cuentas-por-pagar', meta: { title: 'Reporte Cuenta por Pagar' }, name: 'rpayable', component: () => import("../views/reports/cashiers/report-payable.vue") },
        { path: '/reports/estado-cuenta-clientes', meta: { title: 'Estado de Cuenta de Clientes' }, name: 'raccountcustomer', component: () => import("../views/reports/cashiers/account-customer.vue") },
        { path: '/reports/estado-cuenta-proveedores', meta: { title: 'Estado de Cuenta de Proveedores' }, name: 'raccountsupplier', component: () => import("../views/reports/cashiers/account-supplier.vue") },
        { path: '/reports/ingresos-gastos', meta: { title: 'Ingresos y Gastos por Centro' }, name: 'rcentercostmoves', component: () => import("../views/reports/cashiers/center-cost-moves.vue") },
        { path: '/reports/flujo-financiero', meta: { title: 'Flujo Financiero' }, name: 'rfinancialflow', component: () => import("../views/reports/cashiers/financial-flow.vue") },
        

        { path: '/reports/product-alerts', meta: { title: 'Alerta de Productos' }, name: 'rproductalerts', component: () => import("../views/reports/stocks/product-alerts.vue") },
        { path: '/reports/stock-almacen', meta: { title: 'Stock por Almacen' }, name: 'rstockwarehouse', component: () => import("../views/reports/stocks/stock-warehouse.vue") },
	    { path: '/reports/stock-disponible', meta: { title: 'Stock Disponible' }, name: 'rstockavailable', component: () => import("../views/reports/stocks/stock-available.vue") },
        { path: '/reports/arqueo-inventario', meta: { title: 'Arqueo de Inventario' }, name: 'rinventorycount', component: () => import("../views/reports/stocks/inventory-count.vue") },

        { path: '/reports/work-orders', meta: { title: 'Ordenes de Trabajo' }, name: 'rworkorders', component: () => import("../views/reports/production/work-orders.vue") },

        { path: '/reports/clientes', meta: { title: 'Consulta de Clientes' }, name: 'rcustomerquery', component: () => import("../views/reports/customers/customer-query.vue") },
        { path: '/reports/top-clientes', meta: { title: 'Top Clientes' }, name: 'rtopcustomers', component: () => import("../views/reports/customers/top-customers.vue") },
        { path: '/reports/dashboard-clientes', meta: { title: 'Dashboard de Clientes' }, name: 'rcustomerdashboard', component: () => import("../views/reports/customers/dashboard.vue") },


        { path: '/reports/proveedores', meta: { title: 'Consulta de Proveedores' }, name: 'rsupplierquery', component: () => import("../views/reports/purchases/supplier-query.vue") },
        { path: '/reports/top-productos-comprados', meta: { title: 'Top Productos Comprados' }, name: 'rtopproductspurchase', component: () => import("../views/reports/purchases/top-products.vue") },
        { path: '/reports/top-proveedores', meta: { title: 'Top Proveedores' }, name: 'rtopsuppliers', component: () => import("../views/reports/purchases/top-suppliers.vue") },
        { path: '/reports/facturas-compra', meta: { title: 'Reporte Factuas de Compras' }, name: 'rpurchasequery', component: () => import("../views/reports/purchases/purchases-query.vue") },
        { path: '/reports/salida-modalidad-pagos', meta: { title: 'Salidas Modalidad de Pago' }, name: 'routputspayments', component: () => import("../views/reports/purchases/output-payments.vue") },
	
        { path: '/reports/product-prices', meta: { title: 'Listas de Precios' }, name: 'rproductprices', component: () => import("../views/reports/products/product-prices.vue") },
        { path: '/reports/reporte-abc', meta: { title: 'Reporte ABC' }, name: 'rreportabc', component: () => import("../views/reports/products/product-abc.vue") },
        { path: '/reports/productos', meta: { title: 'Consulta de Productos' }, name: 'rproductquery', component: () => import("../views/reports/products/product-query.vue") },
        { path: '/reports/producto-descuentos', meta: { title: 'Productos Descuento Aplicado' }, name: 'rproductdiscounts', component: () => import("../views/reports/products/product-discounts.vue") },
        { path: '/reports/catalogo-productos', meta: { title: 'Catalogo de Productos' }, name: 'rproductcatalog', component: () => import("../views/reports/products/product-catalog.vue") },
        { path: '/reports/catalogo-promociones', meta: { title: 'Catalogo de Descuentos' }, name: 'rdiscountcatalog', component: () => import("../views/reports/products/discount-catalog.vue") }, 
        { path: '/reports/monitorizacion', meta: { title: 'Monitorizacion de Productos' }, name: 'rmonitoring', component: () => import("../views/reports/products/product-monitoring.vue") }, 
        { path: '/reports/packs', meta: { title: 'Reporte de Formulaciones' }, name: 'rproductpacks', component: () => import("../views/reports/products/product-packs.vue") },
        { path: '/reports/consumos', meta: { title: 'Reporte de Consumo Teorico' }, name: 'rconsumtion', component: () => import("../views/reports/products/consumtion.vue") },
    ]
}
export const appRouters: Array<Router> = [];

export const dbRouters = {
    user: () => import("../views/admin/user/user.vue"),
    role: () => import("../views/admin/role/role.vue"),
    //auditLog: () => import("../views/admin/auditLog/auditLog.vue"),
    tab: () => import("../views/admin/tab/tab.vue"),
    application: () => import("../views/admin/application/application.vue"),
    icon: () => import("../views/admin/icon/icon.vue"),
    company: () => import("../views/admin/company/company.vue"),
    branch: () => import("../views/admin/branch/branch.vue"),
    costcenter: () => import("../views/admin/costcenter/costcenter.vue"),
    employee: () => import("../views/admin/employee/employee.vue"),
    area: () => import("../views/admin/area/area.vue"),
    jobtitle: () => import("../views/admin/jobtitle/jobtitle.vue"),

    customer: () => import("../views/customers/customer/customer.vue"),
    group: () => import("../views/customers/group/group.vue"),
    documenttype: () => import("../views/customers/documenttype/documenttype.vue"),
    gender: () => import("../views/customers/gender/gender.vue"),

    booking: () => import("../views/bookings/booking/booking.vue"),
    foodplan: () => import("../views/foods/foodplan/foodplan.vue"), 
    foodorder: () => import("../views/foods/foodorder/foodorder.vue"), 
    room: () => import("../views/foods/room/room.vue"), 
     
    attributegroup: () => import("../views/catalogs/attributegroup/attributegroup.vue"),
    category: () => import("../views/catalogs/category/category.vue"),
    feature: () => import("../views/catalogs/feature/feature.vue"),
    manufacturer: () => import("../views/catalogs/manufacturer/manufacturer.vue"),
    measureunit: () => import("../views/catalogs/measureunit/measureunit.vue"),
    pricerule: () => import("../views/catalogs/pricerule/pricerule.vue"),
    product: () => import("../views/catalogs/product/product.vue"),
    pack: () => import("../views/catalogs/pack/pack.vue"),
    line: () => import("../views/catalogs/line/line.vue"),
    service: () => import("../views/catalogs/service/service.vue"), 
    preparationremark: () => import("../views/catalogs/preparationremark/preparationremark.vue"), 


    vehicle: () => import("../views/logistics/vehicle/vehicle.vue"),
    equipment: () => import("../views/logistics/equipment/equipment.vue"),
    fixedasset: () => import("../views/logistics/fixedasset/fixedasset.vue"), 
    assignment: () => import("../views/logistics/assignment/assignment.vue"), 
    refund: () => import("../views/logistics/refund/refund.vue"), 

    supplier: () => import("../views/purchases/supplier/supplier.vue"),
    purchase: () => import("../views/purchases/purchase/purchase.vue"),
    purchaseplanning: () => import("../views/purchases/purchaseplanning/purchaseplanning.vue"),    
    purchaseorder: () => import("../views/purchases/purchaseorder/purchaseorder.vue"),
    purchaserequest: () => import("../views/purchases/purchaserequest/purchaserequest.vue"),
    purchasequotation: () => import("../views/purchases/purchasequotation/purchasequotation.vue"),
    purchaseentry: () => import("../views/purchases/purchaseentry/purchaseentry.vue"),
    purchasecreditnote: () => import("../views/purchases/creditnote/creditnote.vue"),
    import: () => import("../views/purchases/purchaseimport/purchaseimport.vue"),
    categorybudget: () => import("../views/purchases/categorybudget/categorybudget.vue"),
    

    carrier: () => import("../views/stocks/carrier/carrier.vue"),
    warehouse: () => import("../views/stocks/warehouse/warehouse.vue"),
    productcodebar: () => import("../views/stocks/productcodebar/productcodebar.vue"),
    correspdocument: () => import("../views/stocks/correspdocument/correspdocument.vue"),
    batch: () => import("../views/stocks/batch/batch.vue"),    
    productlocation: () => import("../views/stocks/productlocation/list.vue"),
    stock: () => import("../views/stocks/stock/stock.vue"),
    stockavailable: () => import("../views/stocks/stockavailable/stockavailable.vue"),
    stockmvt: () => import("../views/stocks/stockmvt/stockmvt.vue"), 
    stockconversion: () => import("../views/stocks/stockconversion/stockconversion.vue"),
    kardex: () => import("../views/stocks/kardex/kardex.vue"),
    deliveryorder: () => import("../views/stocks/deliveryorder/deliveryorder.vue"),
    entryorder: () => import("../views/stocks/entryorder/entryorder.vue"),
    transferorder: () => import("../views/stocks/transferorder/transferorder.vue"), 
    stockmvtreason: () => import("../views/stocks/stockmvtreason/stockmvtreason.vue"), 
    inventory: () => import("../views/stocks/inventory/inventory.vue"),

    ldm: () => import("../views/production/ldm/ldm.vue"), 
    activity: () => import("../views/production/activity/activity.vue"), 
    process: () => import("../views/production/process/process.vue"), 
    project: () => import("../views/production/project/project.vue"), 
    serviceorder: () => import("../views/production/serviceorder/serviceorder.vue"), 
    workorder: () => import("../views/production/workorder/workorder.vue"), 
    maintenance: () => import("../views/production/maintenance/maintenance.vue"), 
    planning: () => import("../views/production/planning/planning.vue"), 
    workshop: () => import("../views/workshops/workshop/workshop.vue"), 
    repairorder: () => import("../views/workshops/repairorder/repairorder.vue"), 


    cashier: () => import("../views/cashiers/cashier/cashier.vue"),    
    cashierview: () => import("../views/cashiers/cashier/cashier-view.vue"),    
    paymentmethod: () => import("../views/cashiers/paymentmethod/paymentmethod.vue"),
    dailyclosure: () => import("../views/cashiers/dailyclosure/dailyclosure.vue"),
    payableaccount: () => import("../views/cashiers/payableaccount/payableaccount.vue"),
    receivableaccount: () => import("../views/cashiers/receivableaccount/receivableaccount.vue"),
    currentaccount: () => import("../views/cashiers/currentaccount/currentaccount.vue"),
    transaction: () => import("../views/cashiers/transaction/transaction.vue"),
    bankaccount: () => import("../views/cashiers/bankaccount/bankaccount.vue"),
    incomeoutput: () => import("../views/cashiers/incomeoutput/incomeoutput.vue"),
    budget: () => import("../views/cashiers/budget/budget.vue"),
    accountkey: () => import("../views/accounting/accounting.vue"),
    accountmoves: () => import("../views/reports/accounting/account-moves.vue"), 
     
    quotation: () => import("../views/sales/quotation/quotation.vue"),
    pos: () => import("../views/sales/pos/orders.vue"),
    orderpending: () => import("../views/sales/pos/pending/orders.vue"),
    order: () => import("../views/sales/order/order.vue"),
    saleorder: () => import("../views/sales/saleorder/saleorder.vue"),
    sale: () => import("../views/sales/sale/sale.vue"),
    saledeliveryorder: () => import("../views/sales/deliveryorder/deliveryorder.vue"),
    creditnote: () => import("../views/sales/creditnote/creditnote.vue"),
    debitnote: () => import("../views/sales/debitnote/debitnote.vue"),
    referralguide: () => import("../views/sales/referralguide/referralguide.vue"),
    sunatsummaries: () => import("../views/sales/sunatsummary/sunatsummary.vue"),
    sunatvoided: () => import("../views/sales/sunatvoided/sunatvoided.vue"), 
    specialpricerule: () => import("../views/sales/specialpricerule/specialpricerule.vue"), 
    invoicetype: () => import("../views/sales/invoicetype/invoicetype.vue"),
    saleinvoice: () => import("../views/sales/saleinvoice/saleinvoice.vue"),
    goal: () => import("../views/sales/goal/goal.vue"),
        
    type: () => import("../views/preferences/generaltype/generaltype.vue"),
    printer: () => import("../views/preferences/printer/printer.vue"),
    country: () => import("../views/preferences/country/country.vue"),
    department: () => import("../views/preferences/department/department.vue"),
    province: () => import("../views/preferences/province/province.vue"),
    district: () => import("../views/preferences/district/district.vue"),
    currency: () => import("../views/preferences/currency/currency.vue"),
    tax: () => import("../views/preferences/tax/tax.vue"),
    settings: () => import("../views/preferences/configuration/configuration.vue"),
    //language: () => import("../views/preferences/language/language.vue")
} 

export const routers = [
    loginRouter,
    locking,
    apps,
    ...appRouters,
    otherRouters
];
