import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ReceivableAccount from '../../entities/cashiers/receivableaccount'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';
import ReceivableAccountDue from '@/store/entities/cashiers/receivableaccountdue';

interface ReceivableAccountState extends ListState<ReceivableAccount>{
    editModel: ReceivableAccount;
    reportList: Array<any>;
    dues: Array<ReceivableAccountDue>;
}
class ReceivableAccountModule extends ListModule<ReceivableAccountState,any,ReceivableAccount>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ReceivableAccount>(),
        loading:false,
        editModel: new ReceivableAccount(),
        activeList: new Array<ReceivableAccount>(),
        reportList: new Array<any>(),
        dues: new Array<ReceivableAccountDue>(),
        path: 'receivable-accounts'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getPendingAccounts(context: ActionContext<ReceivableAccountState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/receivable-accounts/pending', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<ReceivableAccount>;
            context.state.totalCount = page.total;
            context.state.list = page.data;
        },
        async getDues(context: ActionContext<ReceivableAccountState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/receivable-accounts/dues', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.dues = reponse.data.result;
            context.state.dues.forEach(item => {
                item.currency = context.state.editModel.currency;
            });
        },
        async buildDues(context: ActionContext<ReceivableAccountState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.post('/api/receivable-accounts/dues', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.dues = reponse.data.result;
        },
        async updateDue(context: ActionContext<ReceivableAccountState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/receivable-account-due/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async reportReceivable(context: ActionContext<ReceivableAccountState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/reports/receivable', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList = reponse.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        view(state: ReceivableAccountState, model: ReceivableAccount) {
            state.editModel = model;
            state.dues = (model.dues != undefined ? model.dues : []);
            state.dues.forEach(item => {
                item.currency = state.editModel.currency;
            });
        },
        setTotals(state: ReceivableAccountState, model: ReceivableAccount) {
            state.editModel.paid = model.paid;
            state.editModel.balance = model.balance;
            state.editModel.state_id = model.state_id;
        },
    });
}
const receivableaccountModule=new ReceivableAccountModule();
export default receivableaccountModule;