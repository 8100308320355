import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import BookingProduct from '../../entities/bookings/bookingproduct'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import Booking from '@/store/entities/bookings/booking';

interface BookingProductState extends ListState<BookingProduct>{
    editModel:BookingProduct;    
    booking: number;      
    features: any;      
    lastSeq: number;      
}
class BookingProductModule extends ListModule<BookingProductState,any,BookingProduct>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<BookingProduct>(),
        loading:false,
        editModel: new BookingProduct(),
        activeList: new Array<BookingProduct>(),
        features: {},
        booking: null,
        lastSeq: 0,
        path: 'booking-products'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<BookingProductState, any>, payload: any) {
            context.state.list = [];
        },
        async finish(context: ActionContext<BookingProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/booking-products/finish/' + payload.data.id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async createFeature(context: ActionContext<BookingProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/booking-product-features', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async updateFeature(context: ActionContext<BookingProductState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/booking-product-features/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async deleteFeature(context: ActionContext<BookingProductState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.delete('/api/booking-product-features/' + payload.data.id, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async getFeatures(context: ActionContext<BookingProductState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/booking-product-features', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.features = reponse.data.result;
            context.state.loading = false;
            return context.state.features;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        init(state: BookingProductState, model: Booking) {
            state.editModel = Object.assign({});
            state.booking = 0;
            state.list = [];
            state.features = [];
            if (model != undefined) {
                state.booking = model.id;
                if (model.products != null)
                    state.list = model.products;
            }
        },
        edit(state: BookingProductState, model: BookingProduct) {
            state.editModel = model;
            state.editModel.including_taxes = (model.product.tax_id == Util.abp.setting.get('IGV_TAXES'));
            state.features = state.editModel.features;
        },
        add(state: BookingProductState, product: Product) {
            let type = Util.abp.list.getProd(product.product_type_id);

            let room_type_id = null;
            let price_wt = product.price_wt;
            if (type.code == 'HO' && product.fields.room_prices != null && product.fields.room_prices.length > 0) {
                let roomT = Util.abp.list.getByValue('T_ROOM', product.fields.room_prices[0].id);
                room_type_id = roomT.id;
                if (product.fields.room_prices[0] != undefined && product.fields.room_prices[0].price > 0) {
                    price_wt = parseFloat(product.fields.room_prices[0].price);
                }
            }

            let detail = new BookingProduct();
            detail.product = product;
            detail.booking_id = state.booking;
            detail.price_rule_id = product.price_rule_id;
            detail.currency_id = product.currency_id;            
            detail.product_type_id = product.product_type_id;
            detail.product_id = product.id;
            detail.product_attribute_id = product['product_attribute_id'];
            detail.hotel = null;
            detail.hotel_id = null;
            detail.room_type_id = room_type_id;
            detail.currency_sign = product['currency_sign'];
            detail.exchange_rate = product['exchange_rate'];
            detail.current_state_id = Util.abp.list.get('E_BOOKING', 'PE').id;
            detail.including_taxes = (product.tax_id == Util.abp.setting.get('IGV_TAXES'));
            detail.seq = state.list.length + 1;
            detail.code = product.code;
            detail.reference = product.reference;
            detail.product_name = product.name;
            detail.nro_pax = product.quantity;
            detail.nro_inf = 0;
            detail.nro_child = 0;
            detail.days = 1;
            detail.price = price_wt;
            detail.price_inf = product.fields.allow_children ? product.fields.price_infants : 0;
            detail.price_child = product.fields.allow_children ? product.fields.price_children : 0;
            detail.total_wt = detail.price * detail.nro_pax;
            detail.total = Util.removeTaxes(detail.total_wt);
            detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(6));
            detail.product_price = price_wt;
            detail.booking_date = Util.abp.clock.today();
            detail.changed = false;
            detail.schedule = null;
            detail.pack_items = null;
            detail.features = null;

            if (detail.price_inf == null) detail.price_inf = 0;
            if (detail.price_child == null) detail.price_child = 0;

            state.list.push(detail);
            state.lastSeq = detail.seq;
        },
        updateList(state: BookingProductState, detail: BookingProduct) {
            let index = detail.seq - 1;
            state.list[index].currency_id = detail.currency_id;
            state.list[index].currency_sign = detail.currency_sign;
            state.list[index].exchange_rate = detail.exchange_rate;            
            state.list[index].price_rule_id = detail.price_rule_id;
            state.list[index].hotel_id = detail.hotel_id;
            state.list[index].room_type_id = detail.room_type_id;
            state.list[index].days = detail.days;
            state.list[index].nro_pax = detail.nro_pax;
            state.list[index].nro_inf = detail.nro_inf;
            state.list[index].nro_child = detail.nro_child;
            state.list[index].price = detail.price;
            state.list[index].price_inf = detail.price_inf;
            state.list[index].price_child = detail.price_child;
            state.list[index].taxes = detail.taxes;
            state.list[index].total = detail.total;
            state.list[index].total_wt = detail.total_wt;
            state.list[index].booking_date = detail.booking_date;
            state.list[index].schedule = detail.schedule;            
            state.list[index].product_price = detail.product_price;
            state.list[index].changed = detail.changed;
            state.list[index].pack_items = detail.pack_items;
            state.list[index].features = detail.features;
            
            if (state.list[index].product_price == 0)
                state.list[index].product_price = detail.price;
        },
        delete(state: BookingProductState, index: number) {
            state.list.splice(index, 1);
            state.list.forEach((a, k) => {
                a.seq = k + 1;
            });
        },
        setFeatures(state: BookingProductState, list: any) {
            state.features = list;
        },
        addFeature(state: BookingProductState, item: any) {
            if (state.features.included == undefined) {
                state.features.included = []
            }
            if (state.features.excluded == undefined) {
                state.features.excluded = []
            }

            if (item.include) {                
                state.features.included.push(item);
            } else {                
                state.features.excluded.push(item);
            }
        },
        updateFeature(state: BookingProductState, item: any) {
            if (state.features.included == undefined) {
                state.features.included = []
            }
            if (state.features.excluded == undefined) {
                state.features.excluded = []
            }

            if (item.include) {
                state.features.included.push(item);
            } else {
                state.features.excluded.push(item);
            }
        },
        deleteFeature(state: BookingProductState, item: any) {
            if (item.include) {
                state.features.included.splice(item.index, 1);
            } else {
                state.features.excluded.splice(item.index, 1);
            }
        },
    });
}
const bookingproductModule=new BookingProductModule();
export default bookingproductModule;