import Entity from '../entity'

export default class ProductComment extends Entity<number> {
    product_id: number;
    user_id: number;
    comment: string;
    filename: string;
    fileData: string;
    filetype: string;
}

