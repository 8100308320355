import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import WorkOrderVehicle from '../../entities/production/workordervehicle'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Vehicle from '@/store/entities/logistics/vehicle';
import WorkOrder from '@/store/entities/production/workorder';

interface WorkOrderVehicleState extends ListState<WorkOrderVehicle>{
    editModel:WorkOrderVehicle;    
    order:number;    
}
class WorkOrderVehicleModule extends ListModule<WorkOrderVehicleState,any,WorkOrderVehicle>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<WorkOrderVehicle>(),
        loading:false,
        editModel: new WorkOrderVehicle(),
        activeList: new Array<WorkOrderVehicle>(),
        order: 0,
        path: 'work-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<WorkOrderVehicleState, any>, payload: any) {
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: WorkOrderVehicleState, model: WorkOrderVehicle) {
            state.editModel = model;
        },
        init(state: WorkOrderVehicleState, model: WorkOrder) {
            state.editModel = Object.assign({});
            state.order = 0;
            state.list = [];
            if (model != undefined) {
                state.order = model.id;
                state.list = model.vehicles;
            }
            state.totalCount = state.list.length;
        },
        add(state: WorkOrderVehicleState, vehicle: Vehicle) {
            let exist = state.list.filter((detail: any) => {
                return detail.vehicle_id === vehicle.id;
            });

            if (exist.length == 0) {
                let detail = new WorkOrderVehicle();
                let type = Util.abp.list.getItem(vehicle.type_id);

                detail.work_order_id = state.order;
                detail.vehicle_id = vehicle.id;
                detail.code = vehicle.plate;
                detail.vehicle_name = type.name + ' ' + vehicle['manufacturer'].name + ' ' + vehicle.description; 
                detail.description = '';
                detail.hours = 1;
                detail.cost_wt = parseFloat(String(vehicle.hour_rate));
                detail.total_wt = parseFloat((detail.hours * detail.cost_wt).toFixed(6));

                state.list.push(detail);
            }
            state.totalCount = state.list.length;
        },
        delete(state: WorkOrderVehicleState, index: number) {
            state.list.splice(index, 1);
            state.totalCount = state.list.length;
        },
    });
}
const workordervehicleModule=new WorkOrderVehicleModule();
export default workordervehicleModule;