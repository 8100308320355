import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PurchaseImport from '../../entities/purchases/purchaseimport'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PurchaseImportDetail from '@/store/entities/purchases/purchaseimportdetail';
import PurchaseImportHistory from '@/store/entities/purchases/history';
import PurchaseImportExpense from '@/store/entities/purchases/purchaseimportexpense';

interface PurchaseImportState extends ListState<PurchaseImport>{
    editModel:PurchaseImport;        
    hisLoading: boolean;      
    history: Array<PurchaseImportHistory>;
    expenses: Array<PurchaseImportExpense>;
    suppliers: Array<any>;    
}
class PurchaseImportModule extends ListModule<PurchaseImportState,any,PurchaseImport>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PurchaseImport>(),
        loading:false,
        hisLoading:false,
        editModel: new PurchaseImport(),
        activeList: new Array<PurchaseImport>(),
        history: new Array<PurchaseImportHistory>(),
        expenses: new Array<PurchaseImportExpense>(),
        suppliers: new Array<any>(),
        path: 'imports'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async confirm(context: ActionContext<PurchaseImportState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/imports/confirm', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.editModel.current_state_id = response.data.result.current_state_id;
            context.state.editModel.valid = response.data.result.valid;
            context.state.loading = false;
            return context.state.editModel;
        },
        async get(context: ActionContext<PurchaseImportState, any>, payload: any) {
            context.state.loading = true; 
            let reponse = await Ajax.get('/api/' + (context.state as any).path + '/get/' + payload.id);
            context.state.editModel = reponse.data.result as PurchaseImport;
            context.state.loading = false;
            context.state.expenses = context.state.editModel.expenses;
            context.state.suppliers = context.state.editModel.suppliers;
            return context.state.editModel;
        },
        async getLastNumber(context: ActionContext<PurchaseImportState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/imports/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<PurchaseImportState, any>, payload: any) {
            context.state.hisLoading = true; 
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/imports/' + id + '/history', { params: { import: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async importOrder(context: ActionContext<PurchaseImportState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/imports/import-order',
                payload.data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            .catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            
            context.state.editModel = Util.extend(context.state.editModel, {}, response.data.result);
            context.state.expenses = response.data.result.expenses;
            context.state.suppliers = response.data.result.suppliers;
            return true;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: PurchaseImportState, model: PurchaseImport) {
            var today = Util.abp.clock.today();
            let serie = Util.abp.setting.get('P_IMPORT_SERIE');

            state.editModel = Object.assign({});
            state.editModel.date_policy = today;
            state.editModel.issue_date = today;
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_PURCHASE_CURRENCY');
            state.editModel.currency_sign = '$';
            state.editModel.currency = null;
            state.editModel.exchange_rate = 0.00;
            state.editModel.expense_rate = 0.00;
            state.editModel.factor_total = 0.00;
            state.editModel.factor_item = 0.00;
            state.editModel.total = 0.00;
            state.editModel.total_pen = 0.00;
            state.editModel.serie = (serie == 'YYYY' ? today.substr(0, 4) : serie);
            state.editModel.number = 0;
            state.editModel.details = new Array<PurchaseImportDetail>();
            state.editModel.expenses = [];
            state.editModel.suppliers = [];
            state.expenses = [];
            state.suppliers = [];
        },
        edit(state: PurchaseImportState, model: PurchaseImport) {
            state.editModel = model;
            state.editModel.currency_sign = model.currency.sign;
            state.expenses = model.expenses;
            state.suppliers = model.suppliers;
        },
        setCurrency(state: PurchaseImportState, currency: any) {
            state.editModel.currency = currency;
            state.editModel.currency_sign = currency.sign;
        },        
    });
}
const purchaseimportModule=new PurchaseImportModule();
export default purchaseimportModule;