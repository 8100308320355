import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Stock from '../../entities/stocks/stock'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface StockConversionState extends ListState<Stock>{
    editModel: Stock;
    activeList: Array<Stock>;
    reportList: Array<any>;
    filename: string;
}
class StockModule extends ListModule<StockConversionState, any, Stock>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Stock>(),
        loading:false,
        editModel:new Stock(),
        activeList: new Array<Stock>(),
        reportList: new Array<any>(),
        path: 'stock-conversions'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<StockConversionState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/stock-conversions', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            }); 
            context.state.loading = false;
            return response.data.result;
        },
        async updateBatch(context: ActionContext<StockConversionState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/stocks/batch', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },        
        async getConversionDetails(context: ActionContext<StockConversionState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/stock-conversions/details', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.reportList = reponse.data.result;
            return context.state.reportList;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const stockModule=new StockModule();
export default stockModule;