import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import DebitNote from '../../entities/sales/debitnote'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'
import Customer from '@/store/entities/customers/customer';
import DebitNoteDetail from '@/store/entities/sales/debitnotedetail';
import DebitNoteHistory from '@/store/entities/sales/history';
import Sale from '@/store/entities/sales/sale';
import axios from 'axios'

interface DebitNoteState extends ListState<DebitNote>{
    editModel:DebitNote;    
    editCustomer: Customer;      
    hisLoading: boolean;      
    history: Array<DebitNoteHistory>;      
}
class DebitNoteModule extends ListModule<DebitNoteState,any,DebitNote>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<DebitNote>(),
        loading:false,
        hisLoading:false,
        editModel: new DebitNote(),
        editCustomer: new Customer(),
        activeList: new Array<DebitNote>(),
        history: new Array<DebitNoteHistory>(),
        path: 'debit-notes'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async update(context: ActionContext<DebitNoteState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.put('/api/' + (context.state as any).path + '/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async invoice(context: ActionContext<DebitNoteState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/debit-note-invoice', payload.data).catch(e => {
                context.state.loading = false;
                return false;
            });
            context.state.loading = false;
            if (!response)
                return response;
            else if ((response as any).data != undefined)
                return (response as any).data.result;
            return false;
        },
        async getHistory(context: ActionContext<DebitNoteState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/debit-notes/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async print(context: ActionContext<DebitNoteState, any>, payload: any) {
            context.state.loading = true;

            let format = Util.abp.setting.get('SALES_PRINT_FORMAT');
            let localUrl = Util.abp.setting.get('LOCAL_PRINTER_URL');
            let mode = Util.abp.setting.get('REMOTE_PRINTER_MODE');
            let method = Util.abp.setting.get('REMOTE_PRINTER_METHOD');
            let useRemote = Util.abp.setting.get('USE_REMOTE_PRINTER');
            let api = (format == 'A4' ? 'debit-note-invoice' : 'debit-note-invoice-receipt');

            let response = await Ajax.get('/api/' + api, { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            if (mode == 'LOCAL' && useRemote == 1 && format == 'TK') {
                if (response.data.result.localUrl != undefined) localUrl = response.data.result.localUrl;
                if (method == 'POST') {
                    await axios.post(localUrl, response.data.result);
                } else {
                    let href: any = localUrl + "?endpoint=" + (url + 'api/' + api) + "&token=" + window.abp.auth.getToken() + "&id=" + payload.id;
                    const link = document.createElement('a');
                    link.href = href;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    return '';
                    //await axios.get(localUrl, { params: response.data.result });
                }
                return '';
            } else
                return response.data.result; 
        },
        async downloadInvoiceFile(context: ActionContext<DebitNoteState, any>, payload: any) {
            let enableNube = Util.abp.setting.get('NUBEFACT_EINVOICE_ENABLE');
            let enableSunat = Util.abp.setting.get('SUNAT_EINVOICE_ENABLE');
            context.state.loading = true;
            payload.data.debit = payload.data.id;
            let data = { params: payload.data };
            if (enableSunat == 1) data['responseType'] = 'blob';
            let reponse = await Ajax.get('/api/sale-invoices-download', data).then(response => {
                const link = document.createElement('a');
                if (enableNube == 1) {
                    const url = response.data.result;
                    link.href = url;
                    if (payload.data.type == 'pdf')
                        link.target = '_blank';
                } else if (enableSunat == 1) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    link.href = url;
                }
                link.setAttribute('download', payload.data.filename); //or any other extension
                document.body.appendChild(link);
                link.click();
            }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: DebitNoteState, model: DebitNote) {
            state.editModel = Object.assign({});
            state.editModel.process_date = Util.abp.clock.today();
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.currency_sign = 'S/';
            state.editModel.exchange_rate = 1;
            state.editModel.invoice_type_name = 'Nota de Debito';
            state.editModel.subtotal = 0.00;
            state.editModel.subtotal_wt = 0.00;
            state.editModel.taxes = 0.00;
            state.editModel.aditional = 0.00;         
            state.editModel.total = 0.00;
            state.editModel.total_wt = 0.00;
            state.editModel.total_paid = 0.00;
            state.editModel.balance = 0.00;
            state.editModel.invoice_serie = null;
            state.editModel.invoice_number = '0';
            state.editModel.details = new Array<DebitNoteDetail>();
        },
        edit(state: DebitNoteState, model: DebitNote) {
            state.editModel = model;
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.invoice_type_name = 'Nota de Debito';
            state.editModel.sale_invoice_type = model.sale_invoice_type;

            state.editModel.customer_code = model.customer.code;
            state.editModel.customer_document = model.customer.document_full;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_address = model['address_full'];
        },
        setInvoiceNumber(state: DebitNoteState, number: any) {
            state.editModel.invoice_number = number;
        },
        updateAmount(state: DebitNoteState, details: Array<DebitNoteDetail>) {
            state.editModel.subtotal = 0;
            state.editModel.subtotal_wt = 0;
            state.editModel.taxes = 0;

            details.forEach((item, index) => {
                state.editModel.subtotal += item.total;
                state.editModel.subtotal_wt += item.total_wt;
            });

            state.editModel.total = state.editModel.subtotal;
            state.editModel.total_wt = state.editModel.subtotal_wt;
            state.editModel.taxes = state.editModel.total_wt - state.editModel.total;
            state.editModel.balance = state.editModel.total_wt + state.editModel.total_paid;
        },
        setTotals(state: DebitNoteState, data: any) {
            state.editModel.subtotal = data.subtotal;
            state.editModel.subtotal_wt = data.subtotal_wt;
            state.editModel.taxes = data.taxes;
            state.editModel.total = data.total;
            state.editModel.total_wt = data.total_wt;
            state.editModel.balance = data.balance;
            state.editModel.total_paid = data.total_paid;
        },
        loadFromSale(state: DebitNoteState, model: Sale) {
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);

            state.editModel = Object.assign({});
            state.editModel.customer_code = model.customer.code;
            state.editModel.customer_document = model.customer.document_full;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.customer_address = model.address_full;

            state.editModel.reference = model.reference;
            state.editModel.sale_id = model.id;
            state.editModel.process_date = Util.abp.clock.today();
            state.editModel.customer_id = model.customer_id;
            state.editModel.currency = model.currency;
            state.editModel.currency_id = model.currency_id;
            state.editModel.invoice_type_name = 'Nota de Debito'; 
            state.editModel.sale_invoice_type = model.invoice_type_id;
            state.editModel.sale_invoice = (model as any).invoice;
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.exchange_rate = model.exchange_rate;
            state.editModel.subtotal = model.subtotal;
            state.editModel.subtotal_wt = model.subtotal_wt;
            state.editModel.taxes = model.taxes;
            state.editModel.aditional = 0.00;        
            state.editModel.total = model.total;
            state.editModel.total_wt = model.total_wt;
            state.editModel.balance = model.total_wt;
            state.editModel.invoice_serie = null;
            state.editModel.invoice_number = '0';
            state.editModel.details = new Array<DebitNoteDetail>();

            model.details.forEach((item, index) => {
                let det = new DebitNoteDetail();
                det.debit_note_id = state.editModel.id;
                det.id = item.id;
                det.product_id = item.product_id;
                det.product_attribute_id = item.product_attribute_id;
                det.warehouse_id = item.warehouse_id;
                det.unity_id = item.unity_id;
                det.code = item.code;
                det.product_name = item.product_name;
                det.quantity = item.quantity;
                det.price = item.price;
                det.price_wt = item.price_wt;
                det.total = item.total;
                det.total_wt = item.total_wt;
                det.taxes = item.taxes;
                det.stock = item.quantity;
                state.editModel.details.push(det);
            });
        },
    });
}
const debitnoteModule=new DebitNoteModule();
export default debitnoteModule;