import Entity from '../entity'

export default class ProductAttribute extends Entity<number> {
    product_id: number;
    manufacturer_id: number;
    name: string;
    code: string;
    upc: string;
    profit: number;
    cost: number;
    price: number;
    quantity: number;
    default_on: boolean;
    minimal_quantity: number;
}

