import Entity from '../entity'
import MeasureUnit from '../catalogs/measureunit';
import Product from '../catalogs/product';

export default class PurchaseImportDetail extends Entity<number>{
    import_id: number;
    product_id: number;
    product_attribute_id: number;
    warehouse_id: number;
    supplier_id: number;
    unity_id: number;
    code: string;
    upc: string;
    product_name: string;
    manufacturer: string;
    version: string;
    quantity: number;
    cost: number;
    cost_local: number;
    total: number;

    unity: MeasureUnit;
    product: Product;
    unity_sym: string;
}


