import Entity from '../entity'
import DeliveryOrderDetail from './deliveryorderdetail';
import Customer from '../customers/customer';
import Currency from '../preferences/currency';

export default class DeliveryOrder extends Entity<number>{
    reference: string;
    serie: string;
    number: string;
    customer_id: number;
    branch_id: number;
    currency_id: number;
    warehouse_id: number;
    location_id: number;
    location_destiny_id: number;
    current_state_id: number;
    received_by_id: number;
    delivery_by_id: number;
    reason_id: number;
    cost_center_id: number;
    entity_id: number;
    entity: string;
    origin_document: string;
    delivery_date: string;
    exchange_rate: number;
    total_wt: number;
    total_paid: number;
    balance: number;
    notes: string;
    contact: string;
    delivered: boolean;
    invoiced: boolean;
    details: Array<DeliveryOrderDetail>;
    
    customer: Customer;
    currency: Currency;
    currency_sign: string;
    customer_name: string;
    nro: string;
}


