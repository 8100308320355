import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PurchaseCreditNote from '../../entities/purchases/purchasecreditnote'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PurchaseCreditNoteDetail from '@/store/entities/purchases/purchasecreditnotedetail';
import PurchaseCreditNoteHistory from '@/store/entities/purchases/history';
import Purchase from '@/store/entities/purchases/purchase';
import PageResult from '@/store/entities/page-result';
import axios from 'axios'

interface PurchaseCreditNoteState extends ListState<PurchaseCreditNote>{
    editModel: PurchaseCreditNote;
    hisLoading: boolean;      
    history: Array<PurchaseCreditNoteHistory>;
    reportList: Array<any>;
    supplier: number;
    filename: string;    
}
class PurchaseCreditNoteModule extends ListModule<PurchaseCreditNoteState,any,PurchaseCreditNote>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PurchaseCreditNote>(),
        loading:false,
        hisLoading:false,
        editModel: new PurchaseCreditNote(),
        activeList: new Array<PurchaseCreditNote>(),
        history: new Array<PurchaseCreditNoteHistory>(),
        supplier: 0,
        reportList: new Array<any>(),
        filename: '',
        path: 'purchase-credit-notes'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<PurchaseCreditNoteState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            if (context.state.supplier != null && context.state.supplier > 0)
                payload.data.for_supplier = context.state.supplier;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<PurchaseCreditNote>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }
            return context.state.list;
        },
        async getHistory(context: ActionContext<PurchaseCreditNoteState, any>, payload: any) {
            context.state.hisLoading = true; 
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/purchase-credit-notes/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: PurchaseCreditNoteState, model: PurchaseCreditNote) {
            state.editModel = Object.assign({});
            state.editModel.process_date = Util.abp.clock.today();
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.currency_sign = 'S/';
            state.editModel.exchange_rate = 1;
            state.editModel.invoice_type_name = 'Nota de Credito';
            state.editModel.subtotal = 0.00;
            state.editModel.subtotal_wt = 0.00;
            state.editModel.taxes = 0.00;
            state.editModel.total = 0.00;
            state.editModel.total_wt = 0.00;
            state.editModel.used_amount = 0.00;
            state.editModel.discounts = 0.00;            
            state.editModel.balance = 0.00;
            state.editModel.invoice_serie = null;
            state.editModel.invoice_number = null;
            state.editModel.details = new Array<PurchaseCreditNoteDetail>();
        },
        edit(state: PurchaseCreditNoteState, model: PurchaseCreditNote) {
            state.editModel = model;
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.invoice_type_name = 'Nota de Credito';
            state.editModel.purchase_invoice_type = model.purchase_invoice_type;

            state.editModel.supplier_document = model.supplier.document;
            state.editModel.supplier_name = model.supplier.name;
        },
        updateAmount(state: PurchaseCreditNoteState, details: Array<PurchaseCreditNoteDetail>) {
            state.editModel.subtotal = 0;
            state.editModel.subtotal_wt = 0;
            state.editModel.taxes = 0;
            
            details.forEach((item, index) => {
                state.editModel.subtotal += item.total;
                state.editModel.subtotal_wt += item.total_wt;
            });

            state.editModel.total = state.editModel.subtotal;
            state.editModel.total_wt = state.editModel.subtotal_wt;
            state.editModel.taxes = state.editModel.total_wt - state.editModel.total;
            state.editModel.balance = state.editModel.total_wt + state.editModel.used_amount;
        },
        setTotals(state: PurchaseCreditNoteState, data: any) {
            state.editModel.subtotal = data.subtotal;
            state.editModel.subtotal_wt = data.subtotal_wt;
            state.editModel.taxes = data.taxes;
            state.editModel.total = data.total;
            state.editModel.total_wt = data.total_wt;
            state.editModel.balance = data.balance;
            state.editModel.used_amount = data.used_amount;
        },
        loadFromPurchase(state: PurchaseCreditNoteState, model: Purchase) {
            var today = new Date();
            var tomorrow = new Date();
            tomorrow.setDate(today.getDate() + 7);

            let reason = state.editModel.reason_id;
            state.editModel = Object.assign({});
            state.editModel.supplier_document = model.supplier_document;
            state.editModel.supplier_name = model.supplier_name;

            state.editModel.reason_id = reason;
            state.editModel.reference = model.reference;
            state.editModel.purchase_id = model.id;
            state.editModel.process_date = Util.abp.clock.today();
            state.editModel.supplier_id = model.supplier_id;
            state.editModel.currency = model.currency;
            state.editModel.currency_id = model.currency_id;
            state.editModel.invoice_type_name = 'Nota de Credito';
            state.editModel.purchase_invoice_type = model.invoice_type_id;
            state.editModel.purchase_invoice = (model as any).invoice;
            state.editModel.currency_sign = model.currency.sign;
            state.editModel.exchange_rate = model.exchange_rate;
            state.editModel.subtotal = model.subtotal;
            state.editModel.subtotal_wt = model.subtotal_wt;
            state.editModel.taxes = model.taxes;
            state.editModel.discounts = model.discounts;            
            state.editModel.total = model.total;
            state.editModel.total_wt = model.total_wt;
            state.editModel.balance = model.total_wt;
            state.editModel.invoice_serie = null;
            state.editModel.invoice_number = null;
            state.editModel.details = new Array<PurchaseCreditNoteDetail>();

            model.details.forEach((item, index) => {
                if (item.quantity > 0) {
                    let det = new PurchaseCreditNoteDetail();
                    det.purchase_credit_note_id = state.editModel.id;
                    det.id = item.id;
                    det.purchase_detail_id = item.id;
                    det.product_id = item.product_id;
                    det.product_attribute_id = item.product_attribute_id;
                    det.warehouse_id = Util.abp.session.warehouseId;
                    det.unity_id = item.unity_id;
                    det.code = item.code;
                    det.product_name = item.product_name;
                    det.quantity = item.quantity;
                    det.cost = item.cost;
                    det.cost_wt = item.cost_wt;
                    det.total = item.total;
                    det.total_wt = item.total_wt;
                    det.taxes = item.taxes;
                    det.discounts = 0;
                    det.stock = item.quantity;
                    state.editModel.details.push(det);
                }
            });
            
        },
        setSupplier(state: PurchaseCreditNoteState, id: number) {
            state.supplier = id;
        },
    });
}
const purchasecreditnoteModule=new PurchaseCreditNoteModule();
export default purchasecreditnoteModule;