import Entity from '../entity'
import PurchaseOrderDetail from './purchaseorderdetail';
import Supplier from './supplier';
import Currency from '../preferences/currency';

export default class PurchaseOrder extends Entity<number>{
    reference: string;
    serie: string;
    number: string;
    user_id : number;
    cost_center_id : number;
    supplier_id : number;
    branch_id : number;
    currency_id : number;
    payment_type_id : number;
    work_order_id: number;
    purchase_planning_id: number;
    purchase_request_id: number;
    purchase_quotation_id : number;
    current_state_id : number;
    exchange_rate: number;
    taxes: number;
    total: number;
    total_wt: number;
    issue_date: string;
    order_date: string;
    confirm_date: string;
    reception_date: string;
    for_import: boolean;
    valid: boolean;
    invoiced: boolean;
    received: boolean;
    request_number: string;
    quotation_number: string;
    quotation_supplier: string;
    notes: string;
    credit_days: number;

    details: Array<PurchaseOrderDetail>;
    supplier_document: string;
    supplier_address: string;
    supplier_name: string;
    supplier: Supplier;
    currency: Currency;
    currency_sign: string;
    nro: string;
    pending_quantity: number;
}


