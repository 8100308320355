import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Product from '../../entities/catalogs/product'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface ProductSearchState extends ListState<Product>{
    editModel:Product;
    activeList: Array<Product>;
    customs: Array<any>;
    loadingCustom: boolean;
}
class ProductModule extends ListModule<ProductSearchState,any,Product>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Product>(),
        loading:false, 
        loadingCustom: false,
        loaded: false,
        editModel:new Product(),
        activeList: new Array<Product>(),
        customs: new Array<any>(),
        path: 'products',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<ProductSearchState, any>, payload: any) {
            context.state.loading = true;
            payload.data.find = true;
            let reponse = await Ajax.get('/api/products', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Product>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }

            let isComp = (Util.abp.setting.get('ENABLE_COMPATIBILITY') == 1);
            context.state.list.forEach(m => {
                if (m['special_price_rule_id'] != null && m['special_price_rule_id'] > 0) {
                    m['icon'] = 'local_offer';
                    m['color'] = 'red';
                }
                if (isComp && m['compatibilities'] != null && m['compatibilities'].length > 0) {
                    m['years'] = '';
                    let comp = m['compatibilities'][0];
                    if (comp.year_start != '-')
                        m['years'] += comp.year_start;
                    if (comp.year_end != '-')
                        m['years'] += ' / ' + comp.year_end;
                }

                if (m['features'] != null && m['features'].length > 0) {
                    m['features_text'] = m['features'].map(a => {
                        let parts = a.name.split(':');
                        return parts[1];
                    }).join(', ');
                }

                if (m.images != null && m.images.length > 0) {
                    let img = m.images[0];
                    if (m.product_attribute_id > 0) {
                        let fil = m.images.filter(a => {
                            return a.product_attribute_id == m.product_attribute_id;
                        });
                        if (fil.length > 0) {
                            img = fil[0];
                        }
                    }

                    m.url = img.url;
                    m.url_icon = (img.url_icon != null ? img.url_icon : img.url);
                    m.url_catalog = (img.url_catalog != null ? img.url_catalog : img.url);
                    m.url_box = (img.url_box != null ? img.url_box : img.url);
                    m.url_preview = (img.url_preview != null ? img.url_preview : img.url);
                }
            });
            return context.state.list;
        },
        async getForPos(context: ActionContext<ProductSearchState, any>, payload: any) {
            if (context.state.loaded) return;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/products', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.loaded = true;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<Product>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }
            return context.state.list;
        },
        async getCustomInfo(context: ActionContext<ProductSearchState, any>, payload: any) {
            if ((context.state.customs != undefined && context.state.customs[payload.data.id_product] != undefined)) return context.state.customs[payload.data.id_product];

            context.state.loadingCustom = true;
            let response = await Ajax.get('/api/product-customs', { params: payload.data }).catch(e => {
                context.state.loadingCustom = false;
                return Promise.reject(e);
            });
            
            context.state.loadingCustom = false;
            context.state.customs[payload.data.product_id] = response.data.result;
            context.state.customs[payload.data.product_id].id = payload.data.product_id;
            return context.state.customs[payload.data.product_id];
        },

    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        updateStocks(state: ProductSearchState, list: Array<any>) {
            let newList = [];
            list.forEach(item => {
                newList[item.key] = parseFloat(item.stock);
            });
            
            state.list.forEach(item => {
                if (newList[item['pkey']] != null) {
                    item.stock = newList[item['pkey']];
                }
            });
        },
        setLoaded(state: ProductSearchState, value: boolean) {
            state.loaded = value;
        },
    });
}
const productModule=new ProductModule();
export default productModule;