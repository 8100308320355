import Entity from '../entity'

export default class IncomeOutput extends Entity<number>{
    reference: string;
    serie: string;
    number: string;
    income_output_concept_id: number;
    work_order_id: number;
    sale_id: number;
    user_type_id: number;
    customer_id: number;
    supplier_id: number;
    employee_id: number;
    currency_id: number;
    bank_account_id: number;
    cashier_id: number;
    cashier_history_id: number;
    payment_method_id: number;
    cost_center_id: number;
    state_id: number;
    transaction_type_id: number;
    type: string;
    conversion_rate: number;
    amount: number;
    description: string;
    reason: string;
    nro_operation: string;
    operation_date: string;
    valid: boolean;
    nro_external_document: string;
    filename: string;
    bank_account_out_id: number;
    payment_method_out_id: number;
    nro_operation_out: string;

    user_name: string;
    nro: string;
}


