import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import ServiceOrder from '@/store/entities/production/serviceorder';
import ServiceOrderHistory from '@/store/entities/sales/history';
import ServiceOrderDetail from '@/store/entities/production/serviceorderdetail';
import Currency from '@/store/entities/preferences/currency';
import Quotation from '@/store/entities/sales/quotation';

interface ServiceOrderState extends ListState<ServiceOrder>{
    editModel: ServiceOrder;
    hisLoading: boolean;
    history: Array<ServiceOrderHistory>;
}
class ServiceOrderModule extends ListModule<ServiceOrderState, any, ServiceOrder>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ServiceOrder>(),
        loading: false,
        hisLoading: false,
        editModel:new ServiceOrder(),
        activeList: new Array<ServiceOrder>(),
        history: new Array<ServiceOrderHistory>(),
        path: 'service-orders'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastNumber(context: ActionContext<ServiceOrderState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/service-orders/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<ServiceOrderState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/service-orders/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async confirm(context: ActionContext<ServiceOrderState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/service-orders/confirm', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async print(context: ActionContext<ServiceOrderState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.get('/api/service-order-pdf', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ServiceOrderState, model: ServiceOrder) {
            let date = new Date();

            let serie = Util.abp.setting.get('SO_SERIE');

            let currency = new Currency();
            currency.id = Util.abp.setting.get('DEFAULT_CURRENCY');
            currency.sign = Util.abp.setting.get('DEFAULT_CURRENCY_SIGN');
            currency.conversion_rate = 1;

            state.editModel = Object.assign({});
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PRODUCTION_COSTCENTER');
            state.editModel.customer_id = Util.abp.setting.get('DEFAULT_CUSTOMER');
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.currency = currency;
            state.editModel.currency_sign = 'S/';
            state.editModel.exchange_rate = 1;
            state.editModel.request_by_id = Util.abp.session.employeeId;
            state.editModel.taxes = 0.00;
            state.editModel.total = 0.00;
            state.editModel.total_wt = 0.00;
            state.editModel.serie = (serie == 'YYYY' ? date.toISOString().substr(0, 4) : serie);
            state.editModel.number = '00000';
            state.editModel.details = new Array<ServiceOrderDetail>();
        },
        edit(state: ServiceOrderState, model: ServiceOrder) {
            state.editModel = model;
            state.editModel.customer_name = model.customer.fullname;
        },
        setCurrency(state: ServiceOrderState, currency: any) {
            state.editModel.currency = currency;
            state.editModel.currency_id = currency.id;
            state.editModel.currency_sign = currency.sign;
            state.editModel.exchange_rate = currency.conversion_rate;
        },
        loadFromQuotation(state: ServiceOrderState, model: Quotation) {
            let date = new Date();
                                             
            state.editModel = Object.assign({});
            state.editModel.reference = model.reference;
            state.editModel.cost_center_id = Util.abp.setting.get('DEFAULT_PRODUCTION_COSTCENTER');
            state.editModel.sale_quotation_id = model.id;
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.customer_id = model.customer_id;
            state.editModel.customer_name = model.customer.fullname;
            state.editModel.currency_id = model.currency_id;
            state.editModel.currency = model.currency;
            state.editModel.currency_sign = model.currency_sign;
            state.editModel.exchange_rate = model.exchange_rate;
            state.editModel.request_by_id = Util.abp.session.employeeId;
            state.editModel.payment_type_id = model.payment_type_id;
            state.editModel.including_taxes = model.including_taxes;
            state.editModel.taxes = model.taxes;
            state.editModel.total = model.total;
            state.editModel.total_wt = model.total_wt;
            state.editModel.serie = date.toISOString().substr(0, 4);
            state.editModel.number = '00000';
            state.editModel.quotation_nro = model.nro;
            state.editModel.details = new Array<ServiceOrderDetail>();
            
            let serv = Util.abp.list.getProdId('SE');
            model.details.forEach((item, index) => {
                if (item.product_type_id == serv && !item.is_finished) {
                    let det = new ServiceOrderDetail();
                    det = Util.extend(true, {}, item);
                    det.service_id = item.product_id;
                    det.service_name = item.product_name;
                    det.cost = item.price;
                    det.cost_wt = item.price_wt;
                    det.product_cost = item.product_price;
                    delete det.id;
                    delete det.tax;
                    state.editModel.details.push(det);
                }
            });
        },
    });
}
const serviceorderModule=new ServiceOrderModule();
export default serviceorderModule;