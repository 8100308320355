import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import SaleState from '../../entities/sales/salestate'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface SaleStateState extends ListState<SaleState>{
    editModel:SaleState;    
}
class SaleStateModule extends ListModule<SaleStateState,any,SaleState>{
    state={ 
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<SaleState>(),
        loading:false,
        editModel: new SaleState(),
        activeList: new Array<SaleState>(),
        path: 'sale-states'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const salestateModule=new SaleStateModule();
export default salestateModule;