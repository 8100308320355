import Entity from '../entity'

export default class SubCategory extends Entity<number>{
    line_id: number;
    category_id: number;
    name: string;
    active: boolean;
    public_name: string;
    public_visibility: boolean;

    image_url: string;
    imageData: string;
    
}


