import Entity from '../entity'

export default class Vehicle extends Entity<number>{
    carrier_id: number;
    manufacturer_id: number;
    manufacturer_model_id: number;
    type_id: number;
    engine_type_id: number;
    state_id: number;
    user_type_id: number;
    employee_id: number;
    supplier_id: number;
    customer_id: number;
    plate: string;
    serial: string;
    description: string;
    active: boolean;
    hour_rate: number;
    name: string;
    year: number;
    mileage: number;
    maintenance_cost: number;
    maintenance_frequency: number;
    maintenance_duration: number;
    maintenance_last: number;
    maintenance_next: number;
    mileage_for_next: number;
    asset: boolean;

    customer: any;
    supplier: any;
}