import Entity from '../entity'
import MeasureUnit from '../catalogs/measureunit';
import Product from '../catalogs/product';

export default class FoodOrderProduct extends Entity<number>{
    food_order_id: number;
    food_id: number;
    product_id: number;
    product_attribute_id: number;
    warehouse_id: number;
    unity_id: number;
    price_rule_id: number;
    day: number;
    code: string;
    category_name: string;
    product_name: string;
    stock: number;
    quantity: number;
    price: number;
    price_wt: number;
    taxes: number;
    total: number;
    total_wt: number;
    product_price: number;

    unity: MeasureUnit;
    product: Product;
    unity_sym: string;
}


