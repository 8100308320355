import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Project from '@/store/entities/production/project';
import ProjectHistory from '@/store/entities/sales/history';

interface ProjectState extends ListState<Project>{
    editModel: Project;
    hisLoading: boolean;
    history: Array<ProjectHistory>;
}
class ProjectModule extends ListModule<ProjectState, any, Project>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Project>(),
        loading: false,
        hisLoading: false,
        editModel:new Project(),
        activeList: new Array<Project>(),
        history: new Array<ProjectHistory>(),
        path: 'projects'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastNumber(context: ActionContext<ProjectState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/projects/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<ProjectState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/projects/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ProjectState, model: any) {
            let date = new Date();

            let serie = Util.abp.setting.get('PROJECT_SERIE');

            state.editModel = Object.assign({});
            state.editModel.issue_date = Util.abp.clock.today();
            state.editModel.branch_id = Util.abp.session.branchId;
            state.editModel.responsable_id = Util.abp.session.employeeId;
            state.editModel.serie = (serie == 'YYYY' ? date.toISOString().substr(0, 4) : serie);
            state.editModel.number = '00000';
        },
        edit(state: ProjectState, model: Project) {
            state.editModel = model;
            state.editModel.customer_name = model.customer.fullname;
        },
    });
}
const projectModule=new ProjectModule();
export default projectModule;