import Entity from '../entity'
import MeasureUnit from '../catalogs/measureunit';
import Product from '../catalogs/product';

export default class DeliveryOrderDetail extends Entity<number>{
    delivery_order_id: number;
    product_id: number;
    product_attribute_id: number;
    unity_id: number;
    warehouse_id: number;
    code: string;
    upc: string;
    product_name: string;
    version: string;
    quantity: number;
    stock: number;
    exchange_rate: number;
    price_wt: number;
    total_wt: number;
    product_price: number;
    entity: string;
    entity_id: number;
    
    unity: MeasureUnit;
    product: Product;
    unity_sym: string;
    batches: Array<any>;
}


