import Entity from '../entity'
import MeasureUnit from '../catalogs/measureunit';
import Product from '../catalogs/product';

export default class PurchaseRequestDetail extends Entity<number>{
    purchase_request_id: number;
    work_order_id: number;
    product_id : number;
    product_attribute_id : number;
    unity_id : number;
    product_name: string;
    version: string;
    code: string;
    upc: string;
    quantity: number;
    cost_wt: number;
    total_wt: number;
    product_cost: number;
    stock: number;
    service: boolean;
    order_number: string;

    unity: MeasureUnit;
    product: Product;
    unity_sym: string;
}


