import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PreparationRemark from '../../entities/catalogs/preparationremark'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface PreparationRemarkState extends ListState<PreparationRemark>{
    editModel: PreparationRemark;
}
class PreparationRemarkModule extends ListModule<PreparationRemarkState, any, PreparationRemark>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PreparationRemark>(),
        loading:false,
        editModel:new PreparationRemark(),
        activeList: new Array<PreparationRemark>(),
        path: 'preparation-remarks'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<PreparationRemarkState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            if (typeof reponse.data.result == 'object' && !Array.isArray(reponse.data.result)) {
                let page = reponse.data.result as PageResult<PreparationRemark>;
                context.state.totalCount = page.total;
                context.state.list = page.data;
            } else {
                context.state.totalCount = reponse.data.result.length;
                context.state.list = reponse.data.result;
            }

            context.state.list.forEach(a => {
                a.categories = a.remark_categories.map(b => { return b.line_id + '-' + b.category_id + (b.subcategory_id > 0 ? '-' + b.subcategory_id : '') });
            });

            return context.state.list;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: PreparationRemarkState, model: PreparationRemark) {
            state.editModel = Object.assign({});
        },
    });
}
const preparationremarkModule=new PreparationRemarkModule();
export default preparationremarkModule;