import Entity from '../entity'

export default class StockAvailable extends Entity<number>{
    product_id: number;
    product_attribute_id: number;
    physical_quantity: number;
    quantity: number;
}


