import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PreparationGroup from '../../entities/catalogs/preparationgroup'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface PreparationGroupState extends ListState<PreparationGroup>{
    editModel: PreparationGroup;
}
class PreparationGroupModule extends ListModule<PreparationGroupState, any, PreparationGroup>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PreparationGroup>(),
        loading:false,
        editModel:new PreparationGroup(),
        activeList: new Array<PreparationGroup>(),
        path: 'preparation-groups'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: PreparationGroupState, model: PreparationGroup) {
            state.editModel = Object.assign({});
            state.editModel.group_type = 'single';
        },
    });
}
const preparationgroupModule=new PreparationGroupModule();
export default preparationgroupModule;