import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import CorrespDocument from '../../entities/stocks/correspdocument'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import url from '../../../lib/url'
import axios from 'axios'

interface CorrespDocumentState extends ListState<CorrespDocument>{
    editModel: CorrespDocument;
}
class CorrespDocumentModule extends ListModule<CorrespDocumentState, any, CorrespDocument>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<CorrespDocument>(),
        loading:false,
        editModel:new CorrespDocument(),
        path: 'corresp-documents'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<CorrespDocumentState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async print(context: ActionContext<CorrespDocumentState, any>, payload: any) {
            context.state.loading = true;

            let localUrl = Util.abp.setting.get('LOCAL_STICKER_PRINTER_URL');
            let mode = Util.abp.setting.get('REMOTE_PRINTER_MODE');
            let useRemote = Util.abp.setting.get('USE_REMOTE_PRINTER');
            let method = Util.abp.setting.get('REMOTE_PRINTER_METHOD');

            let response = await Ajax.get('/api/corresp-document-sticker', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            if (mode == 'LOCAL' && useRemote == 1) {
                if (response.data.result.localUrl != undefined) localUrl = response.data.result.localUrl;
                if (method == 'POST') {
                    await axios.post(localUrl, response.data.result);
                } else {
                    let href: any = localUrl + "?endpoint=" + (url + 'api/corresp-document-sticker') + "&token=" + window.abp.auth.getToken() + "&id=" + payload.id;
                    const link = document.createElement('a');
                    link.href = href;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    return '';
                    //await axios.get(localUrl, { params: response.data.result });
                }
                return '';
            } else
                return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: CorrespDocumentState, model: CorrespDocument) {
            state.editModel = Object.assign({});
            state.editModel.copies = 1;
        }
    });
}
const correspdocumentModule=new CorrespDocumentModule();
export default correspdocumentModule;