import Entity from '../entity'
import FoodOrderProduct from './foodorderproduct';
import Customer from '../customers/customer';
import Currency from '../preferences/currency';

export default class FoodOrder extends Entity<number>{
    reference: string;
    number: string;
    company_id: number;
    branch_id: number;
    food_plan_id: number;
    currency_id: number;
    payment_gateway_id: number;
    current_state_id: number;
    customer_id: number;
    organization_id: number;
    exchange_rate: number;
    subtotal: number;
    subtotal_wt: number;
    taxes: number;
    total: number;
    total_wt: number;
    total_paid: number;
    balance: number;
    order_date: string;
    comment: string;
    valid: boolean;
    invoiced: boolean;
    products: Array<FoodOrderProduct>;

    customer: Customer;
    organization: Customer;
    currency: Currency;
    customer_document: string;
    customer_name: string;

    organization_document: string;
    organization_name: string;
}


