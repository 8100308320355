import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Location from '../../entities/stocks/location'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface LocationState extends ListState<Location>{
    editModel: Location;
    activeList: Array<Location>;
}
class LocationModule extends ListModule<LocationState, any, Location>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Location>(),
        loading:false,
        editModel:new Location(),
        activeList: new Array<Location>(),
        path: 'locations'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastCode(context: ActionContext<LocationState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/locations/last-code', { params: payload.data }).then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.code = result;

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: LocationState, model: Location) {
            state.editModel = Object.assign({});
            state.editModel.type_id = Util.abp.list.get('T_LOCATION', 'A').id;
            state.editModel.code = '';
            state.editModel.warehouse_id = null;
            state.editModel.area_id = null;
            state.editModel.process_id = null;
        },
        setActiveList(state: LocationState, locations: Array<Location>) {
            if (locations != null) {
                state.activeList = locations;
            } else
                state.activeList = new Array<Location>();
        },
    });
}
const locationModule=new LocationModule();
export default locationModule;