import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Custom from '../../entities/purchases/custom'
import Util from '../../../lib/util'

interface CustomState extends ListState<Custom>{
    editModel: Custom;
}
class CustomModule extends ListModule<CustomState, any, Custom>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Custom>(),
        loading:false,
        editModel:new Custom(),
        activeList: new Array<Custom>(),
        path: 'customs'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const customModule=new CustomModule();
export default customModule;