import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Sticker from '../../entities/preferences/sticker'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import axios from 'axios'
import url from '../../../lib/url'

interface StickerState extends ListState<Sticker>{
    editModel:Sticker;
}
class StickerModule extends ListModule<StickerState,any,Sticker>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Sticker>(),
        loading:false,
        editModel: new Sticker(),
        activeList: new Array<Sticker>(),
        path: 'stickers'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async printTest(context: ActionContext<StickerState, any>, payload: any) {
            context.state.loading = true;

            let localUrl = Util.abp.setting.get('LOCAL_STICKER_PRINTER_URL');
            let mode = Util.abp.setting.get('REMOTE_PRINTER_MODE');
            let method = Util.abp.setting.get('REMOTE_PRINTER_METHOD');

            let response = await Ajax.post('/api/stickers/print', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            if (mode == 'LOCAL') {
                if (response.data.result.localUrl != undefined) localUrl = response.data.result.localUrl;
                if (method == 'POST') {
                    await axios.post(localUrl, response.data.result);
                } else {
                    let href: any = localUrl + "?endpoint=" + (url + 'api/stickers/print') + "&token=" + window.abp.auth.getToken() + "&sticker_id=" + payload.data.id;
                    const link = document.createElement('a');
                    link.href = href;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    return '';
                }
                return '';
            }
        },
        async getAllActive(context: ActionContext<StickerState, any>, payload: any) {
            let force = false;
            if (payload.data != undefined && payload.data['force'] == true) {
                force = true;
                context.state.loaded = false;
                context.state.activeList = [];
            }
            if ((context.state.activeList != undefined && context.state.activeList.length > 0) || (context.state.loading && !force)) return true;
            let params = (payload.data == undefined ? {} : payload.data);
            delete params['force'];
            params.perPage = -1;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: params }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.activeList = reponse.data.result;
            context.state.activeList.forEach(a => {
                let text = (a.module == 'product' ? 'Producto' : (a.module == 'shipping' ? 'Corresp.' : 'Guia de Remision'));
                a.subtitle = ' ' + text + ': ' + Util.abp.list.getItem(a.page_type_id).name;
            });
            context.state.loaded = true;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: StickerState, model: Sticker) {
            state.editModel = Object.assign({});
            state.editModel.format = 'ZPL';
        },
    });
}
const stickerModule=new StickerModule();
export default stickerModule;