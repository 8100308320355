import { Store, Module, ActionContext, MutationTree } from 'vuex'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface DashboardState {
    activity: any;
    sales: any;
    widgets: any;
    widget_sales: Array<any>;
    widget_customers: any;
    chart_customers: Array<any>;
    loading: boolean;
    
}
class DashboardModule implements Module<DashboardState, any>{
    namespaced = true;
    state={
        activity: {
            nro_payments: 0,
            nro_sales: 0,
            payable: 0,
            receivable: 0,
            total_quotations: 0,
            total_credit_notes: 0,
            total_debit_notes: 0,
            total_delivery: 0,
            total_orders: 0,
            total_payments: 0,
            total_sales: 0,
            total_sales_b: 0,
            total_sales_f: 0,
            total_sales_t: 0
        },
        sales: [],
        widgets: {},
        widget_sales: [],
        widget_customers: {},
        chart_customers: [],
        loading:false,
    }
    actions = {
        async getDashActivity(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/dash-activities', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return false;
            });
            if (reponse) {
                context.state.loading = false;
                context.state.activity = (reponse as any).data.result;
                return true;
            } else 
                return false;
        },
        async getDashSales(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/dash-sales', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return false;
            });
            if (reponse) {
                context.state.loading = false;
                context.state.sales = (reponse as any).data.result;
                return true;
            } else
                return false;
        },
        async getWidgetValues(context: ActionContext<DashboardState, any>, payload: any) {
            let reponse = await Ajax.get('/api/dash-widgets', { params: payload.data }).catch(e => {
                return false;
            });
            if (reponse) {
                context.state.widgets = (reponse as any).data.result;
                return context.state.widgets;
            } else
                return false;
        },
        async getWidgetSales(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/widget-sales', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return false;
            });
            context.state.loading = false;
            if (reponse) {
                context.state.widget_sales = (reponse as any).data.result;
                return context.state.widget_sales;
            } else
                return false;
        },

        async getWidgetCustomers(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/widget-customers', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.loading = false;
            context.state.widget_customers = response.data.result;
        },
        async getDashCustomers(context: ActionContext<DashboardState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/dash-customers', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.loading = false;
            context.state.chart_customers = response.data.result;
        },
    };
    mutations = {
        
    };
}
const dashboardModule = new DashboardModule();
export default dashboardModule;