import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import AccountKey from '../../entities/accounting/accountkey'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface AccountKeyState extends ListState<AccountKey>{
    editModel: AccountKey;
}
class AccountKeyModule extends ListModule<AccountKeyState, any, AccountKey>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10, 
        list: new Array<AccountKey>(),
        loading:false,
        editModel:new AccountKey(),
        activeList: new Array<AccountKey>(),
        path: 'account-keys'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async update(context: ActionContext<AccountKeyState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async getAll(context: ActionContext<AccountKeyState, any>, payload: any) {
            context.state.loading = true;
            let params = { perPage: -1};
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: params }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.totalCount = reponse.data.result.length;
            context.state.list = reponse.data.result;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const accountkeyModule=new AccountKeyModule();
export default accountkeyModule;