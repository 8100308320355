import Entity from '../entity'
import EquipmentFault from './equipmentfault';

export default class Equipment extends Entity<number>{
    equipment_type_id: number;
    product_id: number;
    fixed_asset_id: number;
    state_id: number;
    name: string;
    hour_rate: number;
    maintenance_frequency: number;
    maintenance_duration: number;
    avg_estimated_failure: number;
    avg_failure: number;
    next_maintenance: string;
    avg_repair: number;
    active: boolean;

    fixed_asset_name: string;
    faults: Array<EquipmentFault>;
}