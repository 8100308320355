import Entity from '../entity'

export default class WorkOrderService extends Entity<number>{
    work_order_id: number;
    supplier_id: number;
    product_id: number;
    product_name: string;
    code: string;
    quantity: number;
    cost_wt: number;
    total_wt: number;
}


