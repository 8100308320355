import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import WorkOrderProduct from '../../entities/production/workorderproduct'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import WorkOrder from '@/store/entities/production/workorder';

interface WorkOrderProductState extends ListState<WorkOrderProduct>{
    editModel:WorkOrderProduct;    
    order:number;    
    quantity_ordered: number;    
}
class WorkOrderProductModule extends ListModule<WorkOrderProductState,any,WorkOrderProduct>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<WorkOrderProduct>(),
        loading:false,
        editModel: new WorkOrderProduct(),
        activeList: new Array<WorkOrderProduct>(),
        order: 0,
        quantity_ordered: 0,
        path: 'work-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<WorkOrderProductState, any>, payload: any) {
            
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: WorkOrderProductState, model: WorkOrderProduct) {
            state.editModel = model;
        },
        init(state: WorkOrderProductState, model: WorkOrder) {
            state.editModel = Object.assign({});
            state.order = 0;
            state.list = [];
            if (model != undefined) {
                state.order = model.id;
                state.list = model.products;
            }
            state.totalCount = state.list.length;
        },
        add(state: WorkOrderProductState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id && detail.product_attribute_id == product['product_attribute_id'];
            });
            
            if (exist.length > 0) {
                exist[0].quantity += product.quantity;
            } else {
                let detail = new WorkOrderProduct();
                detail.work_order_id = state.order;
                detail.product_id = product.id;
                detail.product_attribute_id = product['product_attribute_id'];
                detail.code = product.code;
                detail.product_name = Util.formatProdName(product);
                detail.quantity = product.quantity;
                detail.quantity_total = parseFloat((detail.quantity * state.quantity_ordered).toFixed(2));
                detail.quantity_request = 0;
                detail.quantity_received = 0;
                detail.cost_wt = parseFloat(String(product.cost_wt));
                detail.total_wt = parseFloat((detail.quantity_total * detail.cost_wt).toFixed(6));
                detail.stock = product.stock;
                detail.product_type_id = product.product_type_id;
                detail.unity_id = product.unity_id;

                if (Util.abp.setting.get('SHOW_WEIGHT'))
                    detail.weight = product['weight'];
             
                if (product.unity != null) detail.unity_sym = product.unity.symbol;
                if (product.unity_symbol != null) detail.unity_sym = product.unity_symbol;                
                
                state.list.push(detail);
            }
            state.totalCount = state.list.length;
        },
        delete(state: WorkOrderProductState, index: number) {
            state.list.splice(index, 1);
            state.totalCount = state.list.length;
        },
        setQuantity(state: WorkOrderProductState, quantity: number) {
            if (state.quantity_ordered != quantity) {
                state.quantity_ordered = quantity;

                state.list.forEach(item => {
                    item.quantity = parseFloat(String(item.quantity));
                    item.quantity_total = parseFloat((item.quantity * state.quantity_ordered).toFixed(2));
                    item.cost_wt = parseFloat(String(item.cost_wt));
                    item.total_wt = item.quantity_total * item.cost_wt;
                });
            }
        },
    });
}
const workorderproductModule=new WorkOrderProductModule();
export default workorderproductModule;