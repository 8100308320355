import Entity from '../entity'

export default class Shelf extends Entity<number>{
    location_id: number;
    code: string;
    nro_postitions: number;
    code_start: number;
    code_end: number;
}


