import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Vehicle from '../../entities/logistics/vehicle'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface VehicleState extends ListState<Vehicle>{
    editModel: Vehicle;
    activeList: Array<Vehicle>;
    selectedList: Array<any>;
}
class VehicleModule extends ListModule<VehicleState, any, Vehicle>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Vehicle>(),
        loading:false,
        editModel:new Vehicle(),
        activeList: new Array<Vehicle>(),
        selectedList: new Array<any>(),
        path: 'vehicles'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: VehicleState, model: Vehicle) {
            state.editModel = Object.assign({});
            state.editModel.manufacturer_id = null;
            state.editModel.manufacturer_model_id = null;
            state.editModel.mileage = 0;
            state.editModel.maintenance_frequency = 0;
            state.editModel.maintenance_last = 0;
            state.editModel.maintenance_next = 0;
            state.editModel.mileage_for_next = 0;

            if (model != null) {
                state.editModel.user_type_id = model.user_type_id;
                if (model.customer_id != null) {
                    state.editModel.customer = model.customer;
                    state.editModel.customer_id = model.customer_id;
                }
                if (model.supplier_id != null) {
                    state.editModel.supplier = model.supplier;
                    state.editModel.supplier_id = model.supplier_id;
                }
                if (model.employee_id != null) {
                    state.editModel.employee_id = model.employee_id;
                }
            }
        },
        setSelectedList(state: VehicleState, list: any) {
            state.selectedList = [];

            state.activeList.forEach((item, index) => {
                let obj = list.filter((child: any) => {
                    return child.vehicle_id === item.id;
                })[0];

                if (obj != null) {
                    let yearStart = (obj.year_start == '-' ? '' : obj.year_start);
                    let yearEnd = (obj.year_end == '-' ? '' : obj.year_end);
                    let years = (yearStart == '' ? '' : ' - (' + yearStart + (yearEnd == '' ? '' : ' - ' + yearEnd) + ')');
                    state.selectedList.push({
                        id: item.id,
                        name: item.name + years
                    });
                }
            });
        },
    });
}
const vehicleModule=new VehicleModule();
export default vehicleModule;