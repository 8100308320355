import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Attribute from '../../entities/catalogs/attribute'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import AttributeGroup from '@/store/entities/catalogs/attributegroup';

interface AttributeState extends ListState<Attribute> {
    editModel: Attribute;
    attribute_group: any;
}
class AttributeModule extends ListModule<AttributeState, any, Attribute>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Attribute>(),
        loading: false,
        editModel: new Attribute(),
        attribute_group: null,
        path: 'attributes',
    }
    actions = {
        async getAll(context: ActionContext<AttributeState, any>, payload: any) {
        },
        async create(context: ActionContext<AttributeState, any>, payload: any) {
            context.state.loading = true;
            var result = await Ajax.post('/api/attributes', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.list.push(result.data.result);
            context.state.loading = false;
            return result.data.result;
        },
        async update(context: ActionContext<AttributeState, any>, payload: any) {
            context.state.loading = true;
            var result = await Ajax.put('/api/attributes/' + payload.data.id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.list.forEach((item: any, k) => {
                if (item.id == payload.data.id)
                    item = Util.extend(item, payload.data);
            });
            context.state.loading = false;
            return result.data.result;
        },
        async delete(context: ActionContext<AttributeState, any>, payload: any) {
            context.state.loading = true;
            let id = payload.data.id;
            await Ajax.delete('/api/attributes/' + id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list = context.state.list.filter((item: any) => {
                return item.id !== id;
            });
        },
        async rebuild(context: ActionContext<AttributeState, any>, payload: any) {
            context.state.loading = true;
            await Ajax.post('/api/products-attributes-rebuild', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    };
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: AttributeState, model: Attribute) {
            state.editModel = Object.assign({});
            if (state.attribute_group != null) 
                state.editModel.attribute_group_id = state.attribute_group;
        },
        setList(state: AttributeState, item: AttributeGroup) {
            state.attribute_group = item;
            if (item != null && item.attributes != null) {
                state.list = item.attributes;
                state.totalCount = item.attributes.length;
            } else {
                state.list = [];
                state.totalCount = 0;
            }
        }
    });
}
const attributeModule = new AttributeModule();
export default attributeModule;