import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Goal from '../../entities/sales/goal'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface GoalState extends ListState<Goal>{
    editModel:Goal;    
}
class GoalModule extends ListModule<GoalState,any,Goal>{
    state={ 
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Goal>(),
        loading:false,
        editModel: new Goal(),
        activeList: new Array<Goal>(),
        path: 'goals'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAmount(context: ActionContext<GoalState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/goals-amount', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = reponse.data.result.length;
            context.state.list = reponse.data.result;
            return context.state.list;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const goalModule=new GoalModule();
export default goalModule;