import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ProductComment from '../../entities/catalogs/productcomment'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface ProductCommentState extends ListState<ProductComment>{
    editModel:ProductComment;
    product: number;
}
class ProductCommentModule extends ListModule<ProductCommentState,any,ProductComment>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10, 
        list: new Array<ProductComment>(),
        loading:false,
        editModel:new ProductComment(),
        activeList: new Array<ProductComment>(),
        product: 0,
        path: 'product-comments',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<ProductCommentState, any>, payload: any) {
            context.state.loading = true;
            if (context.state.product > 0) payload.data.product_id = context.state.product;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ProductCommentState, model: ProductComment) {
            state.editModel = Object.assign({});
            state.editModel.fileData = null;
            state.editModel.filename = null;
        },
        setProduct(state: ProductCommentState, product: number) {
            state.product = product;
        },
    });
}
const productcommentModule=new ProductCommentModule();
export default productcommentModule;