import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import BudgetConcept from '../../entities/cashiers/budgetconcept'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface BudgetConceptState extends ListState<BudgetConcept>{
    editModel: BudgetConcept;
}
class BudgetConceptModule extends ListModule<BudgetConceptState,any,BudgetConcept>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<BudgetConcept>(),
        loading: false,
        editModel: new BudgetConcept(),
        activeList: new Array<BudgetConcept>(),
        path: 'budget-concepts'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations 
        new(state: BudgetConceptState, model: any) {
            state.editModel = Object.assign({});
            if (model != null) {
                state.editModel['year'] = model.year;
                state.editModel['branch_id'] = model.branch;
            }
        },
    });
}
const budgetconceptModule=new BudgetConceptModule();
export default budgetconceptModule;