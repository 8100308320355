import Entity from '../entity'
import MeasureUnit from '../catalogs/measureunit';
import Product from '../catalogs/product';

export default class TransferOrderDetail extends Entity<number>{
    transfer_order_id: number;
    product_id: number;
    product_attribute_id: number;
    unity_id: number;
    warehouse_id: number;
    product_name: string;
    version: string;
    quantity: number;
    stock: number;
    code: string;
    approved: number;
    comment: string;

    unity: MeasureUnit;
    product: Product;
    unity_sym: string;
    batches: Array<any>;
}


