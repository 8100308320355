import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Feature from '../../entities/catalogs/feature'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface FeatureState extends ListState<Feature>{
    editModel: Feature;
}
class FeatureModule extends ListModule<FeatureState, any, Feature>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Feature>(),
        loading:false,
        editModel:new Feature(),
        activeList: new Array<Feature>(),
        path: 'features'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const featureModule=new FeatureModule();
export default featureModule;