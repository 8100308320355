import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Util from '../../../lib/util'
import PurchaseImportExpense from '@/store/entities/purchases/purchaseimportexpense';

interface PurchaseImportExpenseState extends ListState<PurchaseImportExpense>{
    editModel: PurchaseImportExpense;
}
class PurchaseImportExpenseModule extends ListModule<PurchaseImportExpenseState,any,PurchaseImportExpense>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PurchaseImportExpense>(),
        loading:false,
        editModel: new PurchaseImportExpense(),
        path: 'import_expenses'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const purchaseimportexpenseModule=new PurchaseImportExpenseModule();
export default purchaseimportexpenseModule;