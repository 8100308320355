import Entity from '../entity'

export default class FoodPlanCategory extends Entity<number>{
    food_plan_id: number;
    food_id: number;
    line_id: number;
    category_id: number;
    name: string;
}


