import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import CreditLine from '../../entities/cashiers/creditline'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface CreditLineState extends ListState<CreditLine>{
    editModel:CreditLine;
}
class CreditLineModule extends ListModule<CreditLineState,any,CreditLine>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<CreditLine>(),
        loading:false,
        editModel: new CreditLine(),
        path: 'credit-lines'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async approveMove(context: ActionContext<CreditLineState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/credit-line-move/approve', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
        async deleteMove(context: ActionContext<CreditLineState, any>, payload: any) {
            context.state.loading = true;
            let id = payload.data.id;
            await Ajax.delete('/api/credit-line-move/' + id).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
        },
        async extend(context: ActionContext<CreditLineState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/credit-lines/extend', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations  
        new(state: CreditLineState, model: CreditLine) {
            state.editModel = Object.assign({});
            state.editModel.issue_date = Util.abp.clock.today();
        },
    });
}
const creditlineModule=new CreditLineModule();
export default creditlineModule;