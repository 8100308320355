import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ProductFeature from '../../entities/catalogs/productfeature'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface ProductFeatureState extends ListState<ProductFeature>{
    editModel:ProductFeature;
    product: number;
}
class ProductFeatureModule extends ListModule<ProductFeatureState,any,ProductFeature>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ProductFeature>(),
        loading:false,
        editModel:new ProductFeature(),
        product: 0,
        path: 'products-features',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<ProductFeatureState, any>, payload: any) {
            context.state.loading = true;
            if (context.state.product > 0) payload.data.product_id = context.state.product;
            let response = await Ajax.post('/api/products-features', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.list = response.data.result;
            context.state.loading = false;
            return response.data.result;
        },
        async delete(context: ActionContext<ProductFeatureState, any>, payload: any) {
            context.state.loading = true;
            let id = context.state.product + '_' + payload.data.feature_value_id;
            let response = await Ajax.delete('/api/products-features/' + id, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            let index = null;
            context.state.list.forEach((item, k) => {
                if (item.feature_value_id == payload.data.feature_value_id) {
                    index = k;
                }
            });
            context.state.list.splice(index, 1);
        },
        async getFormatedFeatures(context: ActionContext<ProductFeatureState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/product-formated-features', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            return reponse.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: ProductFeatureState, model: ProductFeature) {
            state.editModel = Object.assign({});
            state.editModel.product_id = state.product;
            state.editModel.feature_id = 1;
            state.editModel.feature_value_id = null;
            state.editModel.include = true;
            state.editModel.position = 1;
        },
        setProduct(state: ProductFeatureState, product: number) {
            state.product = product;
        },
    });
}
const productfeatureModule=new ProductFeatureModule();
export default productfeatureModule;