import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Maintenance from '@/store/entities/production/maintenance';
import MaintenanceHistory from '@/store/entities/sales/history';

interface MaintenanceState extends ListState<Maintenance>{
    editModel: Maintenance;
    hisLoading: boolean;
    history: Array<MaintenanceHistory>;
}
class MaintenanceModule extends ListModule<MaintenanceState, any, Maintenance>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Maintenance>(),
        loading: false,
        hisLoading: false,
        editModel:new Maintenance(),
        activeList: new Array<Maintenance>(),
        history: new Array<MaintenanceHistory>(),
        path: 'maintenances'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastNumber(context: ActionContext<MaintenanceState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/maintenances/last-number').then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.number = result;

            return result;
        },
        async getHistory(context: ActionContext<MaintenanceState, any>, payload: any) {
            context.state.hisLoading = true;
            let id = context.state.editModel.id;
            let reponse = await Ajax.get('/api/maintenances/' + id + '/history', { params: { id: id } }).catch(e => {
                context.state.hisLoading = false;
                return Promise.reject(e);
            });
            context.state.history = reponse.data.result;
            context.state.hisLoading = false;
        },
        async confirm(context: ActionContext<MaintenanceState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/maintenances/confirm', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async start(context: ActionContext<MaintenanceState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/maintenances/start', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async finish(context: ActionContext<MaintenanceState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/maintenances/finish', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = response.data.result;
            return response.data.result;
        },
        async print(context: ActionContext<MaintenanceState, any>, payload: any) {
            context.state.loading = true;

            let response = await Ajax.get('/api/maintenance-pdf', { params: { id: payload.id } }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;

            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: MaintenanceState) {
            let date = new Date();

            state.editModel = Object.assign({});
            state.editModel.request_date = Util.abp.clock.today();
            state.editModel.estimated_start_date = Util.abp.clock.today();
            state.editModel.request_by_id = Util.abp.session.employeeId;
            state.editModel.type_id = Util.abp.list.get('T_MANT', 'PR').id;
            state.editModel.item_type_id = Util.abp.list.get('T_ITEM_MANT', 'MH').id;            
            state.editModel.duration = 0;
            state.editModel.priority_id = Util.abp.list.get('PRIORITY', 'P1').id;
            state.editModel.serie = date.toISOString().substr(0, 4);
            state.editModel.cost = 0;                
            state.editModel.number = '00000';
            state.editModel.vehicle = null;            
            state.editModel.equipment = null;            
        },
        edit(state: MaintenanceState, model: Maintenance) {
            state.editModel = model;
        },
    });
}
const maintenanceModule=new MaintenanceModule();
export default maintenanceModule;