import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import ProductCompatibility from '../../entities/catalogs/productcompatibility'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import PageResult from '@/store/entities/page-result';

interface ProductCompatibilityState extends ListState<ProductCompatibility>{
    editModel:ProductCompatibility;
    product: number;
    loaded: boolean;
}
class ProductCompatibilityModule extends ListModule<ProductCompatibilityState,any,ProductCompatibility>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<ProductCompatibility>(),
        loading:false,
        editModel:new ProductCompatibility(),
        activeList: new Array<ProductCompatibility>(),
        product: 0,
        loaded: false,
        path: 'products-compatibility',
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<ProductCompatibilityState, any>, payload: any) {
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = reponse.data.result.length;
            context.state.list = reponse.data.result;
        },
        async create(context: ActionContext<ProductCompatibilityState, any>, payload: any) {
            context.state.loading = true;
            if (context.state.product > 0) payload.data.product_id = context.state.product;
            let response = await Ajax.post('/api/' + (context.state as any).path, payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.loaded = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        setProduct(state: ProductCompatibilityState, product: number) {
            state.product = product;
            state.list = [];
            state.loaded = false;
        },
    });
}
const productcompatibilityModule=new ProductCompatibilityModule();
export default productcompatibilityModule;