import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import SaleItem from '../../entities/sales/saleitem'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Sale from '@/store/entities/sales/sale';

interface SaleItemState extends ListState<SaleItem>{
    editModel:SaleItem;    
    sale: number;
}
class SaleItemModule extends ListModule<SaleItemState,any,SaleItem>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<SaleItem>(),
        loading:false,
        editModel: new SaleItem(),
        activeList: new Array<SaleItem>(),
        sale: 0,
        path: 'sales'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<SaleItemState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: SaleItemState, model: SaleItem) {
            state.editModel = model;
        },
        init(state: SaleItemState, model: Sale) {
            state.editModel = Object.assign({});
            state.list = [];

            if (model != undefined) {
                state.sale = model.id;

                if (model.items != undefined)
                    state.list = model.items;
                
                state.list.forEach((item, index) => {
                    if (item.credit_node_detail_id != null && item.credit_node_detail_id > 0 && item.quantity_returned >= item.quantity)
                        item['class'] = 'removed_item';
                }); 
            }
        },
        add(state: SaleItemState, product: any) {
            let detail = new SaleItem();
            if (state.list == undefined) state.list = [];

            detail.sale_id = state.sale;
            detail.tax_id = Util.abp.setting.get('IGV_TAXES');
            detail.tax_rate = Util.abp.setting.get('TAX_RATE');
            detail.exchange_rate = 1;
            detail.number = state.list.length + 1;
            detail.product_name = '';
            detail.total_returned = 0;
            detail.quantity = 1;
            detail.price_wt = 0;
            detail.price = 0;
            detail.total = 0;
            detail.total_wt = 0;
            detail.taxes = parseFloat((detail.total_wt - detail.total).toFixed(6));

            state.list.push(detail);
        },
        delete(state: SaleItemState, index: number) {
            state.list.splice(index, 1);
        },
    });
}
const saleitemModule=new SaleItemModule();
export default saleitemModule;