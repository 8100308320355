import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Batch from '../../entities/stocks/batch'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface BatchState extends ListState<Batch>{
    editModel: Batch;
    activeList: Array<Batch>;
}
class BatchModule extends ListModule<BatchState, any, Batch>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Batch>(),
        loading:false,
        editModel:new Batch(),
        activeList: new Array<Batch>(),
        path: 'batchs'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastCode(context: ActionContext<BatchState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/batchs/last-code', { params: payload.data }).then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });
            context.state.editModel.code = result;

            return result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: BatchState, model: any) {
            state.editModel = Object.assign({});
            state.editModel.code = '0';
            if (model != undefined) {
                if (model.product_id != undefined)
                    state.editModel.product_id = model.product_id;

                if (model.product_name != undefined)
                    state.editModel.product_name = model.product_name;
            }
        },
        setActiveList(state: BatchState, list: []) {
            state.activeList = list;
        },
    });
}
const batchModule=new BatchModule();
export default batchModule;