import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import RepairOrderProduct from '../../entities/workshops/repairorderproduct'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import RepairOrder from '@/store/entities/workshops/repairorder';

interface RepairOrderProductState extends ListState<RepairOrderProduct>{
    editModel:RepairOrderProduct;    
    order: number;
    price_rule: number;
    customer: number;
    group: number;
    method: number;
    reduction: number;
    price_rules: Array<number>;  
}
class RepairOrderProductModule extends ListModule<RepairOrderProductState,any,RepairOrderProduct>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<RepairOrderProduct>(),
        loading:false,
        editModel: new RepairOrderProduct(),
        activeList: new Array<RepairOrderProduct>(),
        order: 0,
        customer: 0,
        group: 0,
        method: Util.abp.setting.get('DEFAULT_PAYMENT_METHOD'),
        reduction: 0,
        price_rule: Util.abp.setting.get('DEFAULT_PRICE_RULE_SALE'),
        price_rules: new Array<number>(),
        path: 'repair-orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<RepairOrderProductState, any>, payload: any) {
            
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        edit(state: RepairOrderProductState, model: RepairOrderProduct) {
            state.editModel = model;
        },
        init(state: RepairOrderProductState, model: RepairOrder) {
            state.editModel = Object.assign({});
            state.order = 0;
            state.list = [];
            if (model != undefined) {
                state.order = model.id;
                if (model.products != undefined)
                    state.list = model.products;
            }
            state.totalCount = state.list.length;
        },
        add(state: RepairOrderProductState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id === product.id && detail.product_attribute_id == product['product_attribute_id'];
            });

            if (exist.length > 0) {
                exist[0].quantity += product.quantity;
                exist[0].total_wt = exist[0].quantity * exist[0].price_wt;
                let total = Util.removeTaxes(exist[0].total_wt);
                exist[0].taxes = parseFloat((exist[0].total_wt - total).toFixed(6));
            } else {
                let detail = new RepairOrderProduct();
                detail.repair_order_id = state.order;
                detail.product_type_id = product.product_type_id;
                detail.product = product;
                detail.product_id = product.id;
                detail.product_attribute_id = product['product_attribute_id'];
                detail.warehouse_id = (product['warehouse_id'] != undefined ? product['warehouse_id'] : Util.abp.session.warehouseId);
                detail.unity_id = product.unity_id;
                detail.unity_sym = product.unity_symbol;
                detail.price_rule_id = product.price_rule_id;
                detail.special_price_rule_id = product['special_price_rule_id'];
                detail.code = product.code;
                detail.upc = product.upc;
                detail.product_name = Util.formatProdName(product);
                detail.version = product.version;
                detail.stock_physical = product.stock_physical;
                detail.stock = product.stock;
                detail.quantity = product.quantity;
                detail.price_wt = parseFloat(String(product.price_wt));
                detail.discount_type = (product['discount_type'] != null ? product['discount_type'] : 'percent');
                detail.discounts = (product['discount'] != null ? product['discount'] : 0);
                detail.total_wt = parseFloat((detail.quantity * detail.price_wt).toFixed(6));
                let total = Util.removeTaxes(detail.total_wt);
                detail.taxes = parseFloat((detail.total_wt - total).toFixed(6));
                detail.product_price = product['product_price'];
                detail.product_cost = product.product_cost;
                detail.service = product['service'];
                Util.updateTotalDetails(detail);      

                if (product['special_price_rule_id'] != null) {
                    detail.special_price_rule = {
                        id: product['special_price_rule_id'],
                        groups: product['groups'],
                        customers: product['customers'],
                        payment_methods: product['payment_methods'],
                        price_rules: product['price_rules'],
                        discount_type: product['discount_type'],
                        discount: product['discount'],
                    };
                }                

                state.list.push(detail);
            }
            state.totalCount = state.list.length;
        },
        delete(state: RepairOrderProductState, index: number) {
            state.list.splice(index, 1);
            state.totalCount = state.list.length;
        },
        setPriceRule(state: RepairOrderProductState, data: any) {
            state.list.forEach(item => {
                if (item.product_id == data.product_id && item.product_attribute_id == data.product_attribute_id) {
                    item.product_price = data.product_price;
                    item.price_wt = data.price_wt;
                    item.price_rule_id = data.price_rule_id;
                    Util.updateTotalDetails(item);
                }
            });
        },
        setGlobalParams(state: RepairOrderProductState, data: any) {
            let changePrice = false;
            let check = false;
            if (data.price_rule_id != undefined) {
                changePrice = (data.price_rule_id != state.price_rule);
                state.price_rule = Util.abp.setting.get('DEFAULT_PRICE_RULE');
                if (data.price_rule_id != undefined && data.price_rule_id > 0)
                    state.price_rule = data.price_rule_id;
                check = true;
            }
            if (data.price_rules != undefined)
                state.price_rules = data.price_rules;

            if (data.reduction != undefined) {
                state.reduction = data.reduction;
                check = true;
            }

            if (data.customer_id != undefined) {
                state.customer = data.customer_id;
                check = true;
            }
            if (data.group_id != undefined) {
                state.group = data.group_id;
                check = true;
            }
            if (data.payment_method_id != undefined) {
                state.method = data.payment_method_id;
                check = true;
            }

            if (check) {
                state.list.forEach(item => {
                    if ((item.id == null || item.id == undefined)) {
                        item.discounts = 0;
                    }

                    if (item.special_price_rule != null) {
                        item.special_price_rule_id = null;
                        item.discounts = 0;

                        let rule1 = ((state.group > 0 && item.special_price_rule.groups != null && item.special_price_rule.groups.length > 0 && item.special_price_rule.groups.indexOf(state.group) >= 0) || item.special_price_rule.groups == null);
                        let rule2 = ((state.customer > 0 && item.special_price_rule.customers != null && item.special_price_rule.customers.length > 0 && item.special_price_rule.customers.indexOf(state.customer) >= 0) || item.special_price_rule.customers == null);
                        let rule3 = ((state.method > 0 && item.special_price_rule.payment_methods != null && item.special_price_rule.payment_methods.length > 0 && item.special_price_rule.payment_methods.indexOf(state.method) >= 0) || item.special_price_rule.payment_methods == null);
                        let rule4 = ((state.price_rule > 0 && item.special_price_rule.price_rules != null && item.special_price_rule.price_rules.length > 0 && item.special_price_rule.price_rules.indexOf(item.price_rule_id) >= 0) || item.special_price_rule.price_rules == null);

                        if (rule1 && rule2 && rule3 && rule4) {
                            item.special_price_rule_id = item.special_price_rule.id;
                            item.discount_type = item.special_price_rule.discount_type;
                            item.discounts = item.special_price_rule.discount;
                            if (item.discount_type == 'amount') {
                                item.discounts = item.special_price_rule.discount * item.quantity;
                            }
                        }

                        Util.updateTotalDetails(item);
                    } else if (state.reduction > 0) {
                        item.discount_type = 'percent';
                        item.discounts = state.reduction;
                        Util.updateTotalDetails(item);
                    }
                });
            }

            //revisar si cambio de tipo de precio
            if (changePrice) {
                state.list.forEach(item => {
                    if (item.price_rule_id != state.price_rule && item.product != null && item.product.prices != null) {
                        let rule = item.product.prices.filter((item: any) => {
                            return item.price_rule_id == state.price_rule;
                        })[0];

                        item.price_rule_id = state.price_rule;
                        item.price_wt = parseFloat(String(rule.price_wt));
                        item.product_price = parseFloat(String(rule.price_wt));
                        Util.updateTotalDetails(item);
                    }
                });
            }
        },
    });
}
const repairorderproductModule=new RepairOrderProductModule();
export default repairorderproductModule;