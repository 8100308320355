import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Line from '../../entities/catalogs/line'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Category from '@/store/entities/catalogs/category';

interface LineState extends ListState<Line>{
    editModel: Line;
    activeList: Array<Line>;
    currents: Array<any>;
    current: any;
    original: Array<any>;
    items: Array<any>;
    categories: Array<Category>;
}
class LineModule extends ListModule<LineState, any, Line>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Line>(),
        loading:false,
        editModel:new Line(),
        activeList: new Array<Line>(),
        currents: new Array<any>(),
        current: null,
        original: new Array<any>(),
        items: new Array<any>(),
        categories: new Array<Category>(),
        path: 'lines'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getLastCode(context: ActionContext<LineState, any>, payload: any) {
            let result = null;
            await Ajax.get('/api/lines/last-code', { params: payload.data }).then(response => {
                result = response.data.result;
            }).catch(e => {
                result = '';
            });

            return result;
        },
        async getForPos(context: ActionContext<LineState, any>, payload: any) {
            if (context.state.original != undefined && context.state.original.length > 0 || (context.state.loading)) return true;
            let params = (payload.data == undefined ? {} : payload.data);
            params.perPage = -1;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/lines', { params: params }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.original = reponse.data.result;
            context.state.items = reponse.data.result;
            context.state.loading = false;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        new(state: LineState, model: Line) {
            state.editModel = Object.assign({});
            state.editModel.code = 0;
        },
        //setItems(state: LineState, list: Array<any>) {
        //    state.current = null;
        //    state.items = list;
        //},
        goBack(state: LineState, list: Array<any>) {
            let total = state.currents.length;
            if (total > 0) {
                total--;
                state.currents.pop();
            } else {
                total = 0;
                state.currents = [];
            }
            if (total > 0) {
                state.current = state.currents[total - 1];
                if (state.current.categories != null && state.current.categories.length > 0) {
                    state.items = state.current.categories;
                } else if (state.current.subcategories != null && state.current.subcategories.length > 0) {
                    state.items = state.current.subcategories;
                }
            } else {
                state.current = null;
                state.items = state.original;
            }
        },
        setCurrent(state: LineState, current: any) {
            if (state.currents == null) state.currents = [];
            state.current = current;
            if (current.categories != null && current.categories.length > 0) {
                state.items = current.categories;
                state.currents.push(current);
            } else if (current.subcategories != null && current.subcategories.length > 0) {
                state.items = current.subcategories;
                state.currents.push(current);
            }
        },
    });
}
const lineModule=new LineModule();
export default lineModule;