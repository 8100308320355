import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import BookingAttachment from '@/store/entities/bookings/bookingattachment'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Booking from '@/store/entities/bookings/booking';

interface BookingAttachmentState extends ListState<BookingAttachment>{
    editModel:BookingAttachment;    
    booking:number;    
}
class BookingAttachmentModule extends ListModule<BookingAttachmentState,any,BookingAttachment>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<BookingAttachment>(),
        loading:false,
        editModel: new BookingAttachment(),
        booking: 0,
        path: 'booking-attachments'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<BookingAttachmentState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.post('/api/booking-attachments', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.list = response.data.result;
            context.state.totalCount = context.state.list.length;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        init(state: BookingAttachmentState, model: Booking) {
            state.editModel = Object.assign({});
            state.booking = 0;
            state.list = [];
            if (model != undefined) {
                state.booking = model.id;
                if (model.attachments != undefined)
                    state.list = model.attachments;
            }
            state.totalCount = state.list.length;
        },
    });
}
const bookingattachmentModule=new BookingAttachmentModule();
export default bookingattachmentModule;