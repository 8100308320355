import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import app from './modules/app'
import session from './modules/session'
import user from './modules/admin/user'
import role from './modules/admin/role'
import tab from './modules/admin/tab'
import application from './modules/admin/application'
import report from './modules/admin/report'
import company from './modules/admin/company'
import branch from './modules/admin/branch' 
import costcenter from './modules/admin/costcenter' 
import organizationunit from './modules/admin/organizationunit'
import employee from './modules/admin/employee'
import area from './modules/admin/area'
import jobtitle from './modules/admin/jobtitle'
import dashboard from './modules/admin/dashboard'
import backup from './modules/admin/backup'
  
import customer from './modules/customers/customer'
import customerchild from './modules/customers/customerchild'
import voucher from './modules/customers/voucher'
import address from './modules/customers/address'
import documenttype from './modules/customers/documenttype'
import group from './modules/customers/group'
import gender from './modules/customers/gender'

import booking from './modules/bookings/booking'
import bookingproduct from './modules/bookings/bookingproduct'
import bookingguest from './modules/bookings/bookingguest'
import bookingattachment from './modules/bookings/bookingattachment'

import foodplan from './modules/foods/foodplan'
import foodplancategory from './modules/foods/foodplancategory'
import foodorder from './modules/foods/foodorder'
import foodorderproduct from './modules/foods/foodorderproduct'
import room from './modules/foods/room'
import table from './modules/foods/table'
 
import attribute from './modules/catalogs/attribute'
import attributegroup from './modules/catalogs/attributegroup'
import line from './modules/catalogs/line'
import category from './modules/catalogs/category'
import subcategory from './modules/catalogs/subcategory'
import categorybudget from './modules/catalogs/categorybudget'
import feature from './modules/catalogs/feature'
import featurevalue from './modules/catalogs/featurevalue'
import manufacturer from './modules/catalogs/manufacturer'
import manufacturermodel from './modules/catalogs/manufacturermodel'
import measureunit from './modules/catalogs/measureunit'
import pricerule from './modules/catalogs/pricerule'
import product from './modules/catalogs/product' 
import producttype from './modules/catalogs/producttype'
import productsearch from './modules/catalogs/productsearch'
import productcompatibility from './modules/catalogs/productcompatibility'
import productcomment from './modules/catalogs/productcomment'
import productattribute from './modules/catalogs/productattribute'
import productlocation from './modules/catalogs/productlocation'
import productprice from './modules/catalogs/productprice'
import productfeature from './modules/catalogs/productfeature'
import productsupplier from './modules/catalogs/productsupplier'
import productitinerary from './modules/catalogs/productitinerary'
import specialprice from './modules/catalogs/specialprice'
import ldm from './modules/production/ldm'
import kit from './modules/catalogs/kit'
import pack from './modules/catalogs/pack'
import preparationgroup from './modules/catalogs/preparationgroup'
import preparationremark from './modules/catalogs/preparationremark'

import vehicle from './modules/logistics/vehicle'
import equipment from './modules/logistics/equipment'
import fixedasset from './modules/logistics/fixedasset'
import fixedassettype from './modules/logistics/fixedassettype'
import assignment from './modules/logistics/assignment'
import refund from './modules/logistics/refund'
 
import supplier from './modules/purchases/supplier'
import purchaseplanning from './modules/purchases/purchaseplanning'
import purchaseplanningproduct from './modules/purchases/purchaseplanningproduct'
import purchase from './modules/purchases/purchase'
import purchasedetail from './modules/purchases/purchasedetail'
import purchaseorder from './modules/purchases/purchaseorder'
import purchaseorderdetail from './modules/purchases/purchaseorderdetail'
import purchaserequest from './modules/purchases/purchaserequest'
import purchaserequestdetail from './modules/purchases/purchaserequestdetail'
import purchasequotation from './modules/purchases/purchasequotation'
import purchasequotationdetail from './modules/purchases/purchasequotationdetail'
import purchaseimport from './modules/purchases/purchaseimport'
import purchaseimportdetail from './modules/purchases/purchaseimportdetail'
import purchaseimportexpense from './modules/purchases/purchaseimportexpense'
import purchasecreditnote from './modules/purchases/purchasecreditnote'
import purchasecreditnotedetail from './modules/purchases/purchasecreditnotedetail'
import purchasedebitnote from './modules/purchases/purchasedebitnote'
import purchasedebitnotedetail from './modules/purchases/purchasedebitnotedetail'
import importexpensetype from './modules/purchases/importexpensetype'
import custom from './modules/purchases/custom'

import country from './modules/preferences/country'
import department from './modules/preferences/department'
import province from './modules/preferences/province'
import district from './modules/preferences/district'
import currency from './modules/preferences/currency'
import exchangerate from './modules/preferences/exchangerate'
import tax from './modules/preferences/tax'
import generaltype from './modules/preferences/generaltype'
import generaltypevalue from './modules/preferences/generaltypevalue'
import configuration from './modules/preferences/configuration'
import notification from './modules/preferences/notification'
import conversation from './modules/preferences/conversation'
import printer from './modules/preferences/printer'
import sticker from './modules/preferences/sticker'
import quicknote from './modules/preferences/quicknote'
import imagetype from './modules/preferences/imagetype'
import datatable from './modules/preferences/datatable'


import warehouse from './modules/stocks/warehouse'
import productcodebar from './modules/stocks/productcodebar'
import correspdocument from './modules/stocks/correspdocument'
import location from './modules/stocks/location'
import shelf from './modules/stocks/shelf'
import batch from './modules/stocks/batch'
import productserie from './modules/stocks/productserie'
import stock from './modules/stocks/stock'
import stockavailable from './modules/stocks/stockavailable'
import stockmvt from './modules/stocks/stockmvt'
import stockconversion from './modules/stocks/stockconversion'
import stockmvtreason from './modules/stocks/stockmvtreason'
import carrier from './modules/stocks/carrier'
import deliveryorder from './modules/stocks/deliveryorder'
import deliveryorderdetail from './modules/stocks/deliveryorderdetail'
import entryorder from './modules/stocks/entryorder'
import entryorderdetail from './modules/stocks/entryorderdetail'
import transferorder from './modules/stocks/transferorder'
import transferorderdetail from './modules/stocks/transferorderdetail'
import inventory from './modules/stocks/inventory'
import inventorydetail from './modules/stocks/inventorydetail'

import process from './modules/production/process'
import project from './modules/production/project'
import serviceorder from './modules/production/serviceorder'
import serviceorderdetail from './modules/production/serviceorderdetail'
import workorder from './modules/production/workorder'
import workorderproduct from './modules/production/workorderproduct'
import workorderservice from './modules/production/workorderservice'
import workorderplan from './modules/production/workorderplan'
import workordertask from './modules/production/workordertask'
import workordervehicle from './modules/production/workordervehicle'
import workorderdocument from './modules/production/workorderdocument'
import maintenance from './modules/production/maintenance'
import activity from './modules/production/activity'

import workshop from './modules/workshops/workshop'
import repairorder from './modules/workshops/repairorder'
import repairorderproduct from './modules/workshops/repairorderproduct'
import repairorderplan from './modules/workshops/repairorderplan'
import repairorderdocument from './modules/workshops/repairorderdocument'


import ark from './modules/cashiers/ark'
import cashier from './modules/cashiers/cashier'
import cashierhistory from './modules/cashiers/cashierhistory'
import dailyclosure from './modules/cashiers/dailyclosure'
import payableaccount from './modules/cashiers/payableaccount'
import paymentmethod from './modules/cashiers/paymentmethod'
import paymentgateway from './modules/cashiers/paymentgateway'
import incomeoutputconcept from './modules/cashiers/incomeoutputconcept'
import accountcombined from './modules/cashiers/accountcombined'
import receivableaccount from './modules/cashiers/receivableaccount'
import currentaccount from './modules/cashiers/currentaccount'
import transaction from './modules/cashiers/transaction'
import bankaccount from './modules/cashiers/bankaccount'
import incomeoutput from './modules/cashiers/incomeoutput'
import creditline from './modules/cashiers/creditline'
import budget from './modules/cashiers/budget'
import budgetconcept from './modules/cashiers/budgetconcept'
import account from './modules/accounting/account'
import accountkey from './modules/accounting/accountkey'


import quotation from './modules/sales/quotation'
import quotationdetail from './modules/sales/quotationdetail'
import order from './modules/sales/order'
import orderdetail from './modules/sales/orderdetail'
import saleorder from './modules/sales/saleorder'
import saleorderdetail from './modules/sales/saleorderdetail'
import sale from './modules/sales/sale'
import saledetail from './modules/sales/saledetail'
import saleitem from './modules/sales/saleitem'
import salecharge from './modules/sales/saleitem'
import salestate from './modules/sales/salestate'
import creditnote from './modules/sales/creditnote'
import creditnotedetail from './modules/sales/creditnotedetail'
import debitnote from './modules/sales/debitnote'
import debitnotedetail from './modules/sales/debitnotedetail'
import referralguide from './modules/sales/referralguide'
import referralguidedetail from './modules/sales/referralguidedetail'
import sunatsummary from './modules/sales/sunatsummary'
import sunatvoided from './modules/sales/sunatvoided'
import invoicetype from './modules/sales/invoicetype'
import invoicetypeserie from './modules/sales/invoicetypeserie'
import saleinvoice from './modules/sales/saleinvoice'
import specialpricerule from './modules/sales/specialpricerule'
import goal from './modules/sales/goal'
import invoicecharge from './modules/sales/invoicecharge'

const store = new Vuex.Store({
    state: {
        //
        //loading: boolean
    },
    mutations: {
        //
    },
    actions: {

    },
    modules: {
        app,
        session,
        user,
        role,
        tab,
        application,
        report,
        company,
        branch,
        costcenter,
        organizationunit,
        employee,
        area,
        jobtitle,
        dashboard,
        backup,

        customer,
        customerchild,
        voucher,
        address,
        documenttype,
        group,
        gender,

        booking,
        bookingproduct,
        bookingguest,
        bookingattachment,

        foodplan,
        foodplancategory,
        foodorder,
        foodorderproduct,
        room,
        table,

        attribute,
        attributegroup,
        line,
        category,
        subcategory,
        categorybudget,
        feature,
        featurevalue,
        manufacturer,
        manufacturermodel,
        measureunit,
        pricerule,
        product,
        producttype,
        productsearch,
        productcompatibility,
        productcomment,
        productattribute,
        productlocation,
        productprice,
        productfeature,
        productsupplier,
        productitinerary,
        specialprice,
        ldm,
        kit,
        pack,
        carrier,
        preparationgroup,
        preparationremark,
        
        supplier,
        purchase,
        purchaseplanning,
        purchaseplanningproduct,
        purchasedetail,
        purchaseorder,
        purchaseorderdetail,
        purchaserequest,
        purchaserequestdetail,
        purchasequotation,
        purchasequotationdetail,
        purchaseimport,
        purchaseimportdetail,
        purchaseimportexpense,
        purchasecreditnote,
        purchasecreditnotedetail,
        purchasedebitnote,
        purchasedebitnotedetail,
        importexpensetype,
        custom,

        country,
        department,
        province,
        district,
        currency,
        exchangerate,
        tax,
        configuration,
        generaltype,
        generaltypevalue,
        notification,
        conversation,
        printer,
        sticker,
        quicknote,
        imagetype,
        datatable,

        warehouse,
        location,
	    shelf,
        batch,
        productserie,
        stock,
        stockavailable,
        stockmvt,
        stockconversion,
        stockmvtreason,
        deliveryorder,
        deliveryorderdetail,
        entryorder,
        entryorderdetail,
        transferorder,
        transferorderdetail,
        inventory,
        inventorydetail,        
	    correspdocument,
        productcodebar,

        process,
        project,
        serviceorder,
        serviceorderdetail,
        workorder,
        workorderproduct,
        workorderservice,
        workorderplan,
        workordertask,
        workordervehicle,
        workorderdocument,
        maintenance,
        activity,

        workshop,
        repairorder,
        repairorderproduct,
        repairorderplan,
        repairorderdocument,

        fixedasset,
        fixedassettype,
        assignment,
        refund,
        vehicle,
        equipment,

        ark,
        cashier,
        cashierhistory,
        paymentmethod,
        paymentgateway,
        incomeoutputconcept,
        transaction,
        invoicetype,
        invoicetypeserie,
        dailyclosure,
        accountcombined,
        payableaccount,
        receivableaccount,
        currentaccount,
        bankaccount,
        incomeoutput,
        creditline,
        budget,
        budgetconcept,
        account,
        accountkey,

        quotation,
        quotationdetail,
        order,
        orderdetail,
        saleorder,
        saleorderdetail,
        sale,
        saledetail,
        saleitem,
        salecharge,
        salestate,
        creditnote,
        creditnotedetail,
        debitnote,
        debitnotedetail,
        referralguide,
        referralguidedetail,
        sunatsummary,
        sunatvoided,
        saleinvoice,
        specialpricerule,
        goal,
        invoicecharge,
    }
});

export default store;